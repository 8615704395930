import { IonSpinner, IonSplitPane } from '@ionic/react';
import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { useApp } from '../../../context/app';
import { Router } from '../../../router';
import { currentAccountState } from '../../../states/account';
import { Splash } from '../../splash';
import { SideMenu } from '../../SideMenu';
import { showLoadingSpinnerState, showSplashState } from '../../../states/app';

const CustomIonSplitPane = styled(IonSplitPane)`
  --border: 1px solid #a3a3a3;
  --side-max-width: 300px !important;
`;

const LoadingSpinner = styled.div`
  align-items: center;
  background: #e0dcdc;
  border-radius: 8px;
  bottom: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  color: black;
  display: flex;
  height: 50px;
  justify-content: center;
  left: 20px;
  position: absolute;
  width: 50px;
`;

export const Dashboard: FunctionComponent = () => {
  const { isLoading } = useApp();
  const currentAccount = useRecoilValue(currentAccountState);
  const showLoadingSpinner = useRecoilValue(showLoadingSpinnerState);
  const showSplash = useRecoilValue(showSplashState);

  if (isLoading || showSplash) {
    return <Splash />;
  }

  return (
    <>
      <Helmet>
        <title>LBP{currentAccount?.name ? `: ${currentAccount.name}` : ''}</title>
      </Helmet>
      <CustomIonSplitPane contentId="main">
        <SideMenu />
        <Router />
      </CustomIonSplitPane>
      {showLoadingSpinner && (
        <LoadingSpinner>
          <IonSpinner name="dots"></IonSpinner>
        </LoadingSpinner>
      )}
    </>
  );
};
