export const CancelIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.50486 11.9951L7.99995 8.5M11.495 5.00491L7.99995 8.5M7.99995 8.5L4.50486 5.00491M7.99995 8.5L11.495 11.9951"
      stroke="#1D2C3A"
      strokeOpacity="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
