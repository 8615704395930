import { useEffect, useState } from 'react';

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
}

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const breakpoints: Record<Breakpoint, number> = {
  lg: 992,
  md: 768,
  sm: 576,
  xl: 1200,
  xs: 0,
};

export function useBreakpoint(): Breakpoint {
  const windowWidth = useWindowWidth();
  const [breakpoint, setBreakpoint] = useState(getCurrentBreakpoint());

  useEffect(() => {
    const newBreakpoint = getCurrentBreakpoint();
    if (newBreakpoint !== breakpoint) {
      setBreakpoint(newBreakpoint);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  function getCurrentBreakpoint(): Breakpoint {
    const windowWidth = window.innerWidth;
    if (windowWidth < breakpoints.sm) {
      return 'xs';
    } else if (windowWidth < breakpoints.md) {
      return 'sm';
    } else if (windowWidth < breakpoints.lg) {
      return 'md';
    } else if (windowWidth < breakpoints.xl) {
      return 'lg';
    }
    return 'xl';
  }

  return breakpoint;
}
