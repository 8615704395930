import { Authenticator } from '@aws-amplify/ui-react';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RecoilRoot } from 'recoil';
import App from './App';
import { config } from './config';
import { initializeGoogleAnalytics } from './utils/google-analytics';

initializeGoogleAnalytics();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={config.posthog?.apiKey}
      options={{ api_host: 'https://app.posthog.com' }}
    >
      <RecoilRoot>
        <Authenticator.Provider>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </Authenticator.Provider>
      </RecoilRoot>
    </PostHogProvider>
  </React.StrictMode>,
);
