import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useNotification } from '../hooks/notification';
import { useIsCurrentUserAllowed } from '../hooks/users';
import { currentAccountState } from '../states/account';
import { ApiErrors, httpDelete, httpGet, httpPost } from '../utils/api-client';
import {
  AccountUserUpdateDtoRoleEnum,
  InviteSelectDto,
  UserSelectDto,
  UserWithRolesSelectDto,
} from '../types/api.types';
import { queryClient } from './client';
import { queryKeys } from './keys';

type GetInvitesForUserResponse = {
  invites: InviteSelectDto[];
};

type GetUsersForAccountResponse = {
  users: UserWithRolesSelectDto[];
};

export const useGetCurrentUser = (disabled?: boolean) =>
  useQuery<UserSelectDto, ApiErrors>({
    enabled: !disabled,
    queryFn: () =>
      httpGet({
        ignoreAccountHeader: true,
        path: '/users/me',
      }),
    queryKey: disabled ? queryKeys.placeholders.default : queryKeys.users.getCurrentUser,
  });

export const useGetInvitesForUser = () =>
  useQuery<InviteSelectDto[], ApiErrors>({
    queryFn: async () => {
      const { invites } = await httpGet<GetInvitesForUserResponse>({
        path: '/users/invites',
      });
      return invites;
    },
    queryKey: queryKeys.users.getInvitesForUser,
  });

export const useGetCurrentUserForAccount = () => {
  return useMutation({
    mutationFn: async (accountId: number) =>
      httpGet<UserWithRolesSelectDto>({
        accountId,
        path: '/accounts/users/me',
      }),
  });
};

export const useGetUsersForAccount = () => {
  const activeAccount = useRecoilValue(currentAccountState);
  const isAllowed = useIsCurrentUserAllowed(AccountUserUpdateDtoRoleEnum.ADMIN);

  return useQuery<UserWithRolesSelectDto[], ApiErrors>({
    enabled: Boolean(activeAccount?.id) && isAllowed,
    queryFn: async () => {
      const { users } = await httpGet<GetUsersForAccountResponse>({
        path: '/accounts/users',
      });
      return users;
    },
    queryKey: queryKeys.users.getUsersForAccount,
    select: (data) => data.sort(({ id: idA }, { id: idB }) => idA - idB),
  });
};

export const useRemoveUserFromAccount = () => {
  const notify = useNotification();

  const onError = (error: Error) => {
    if (error instanceof ApiErrors) notify(error.message);
  };
  const onSuccess = () => {
    notify('User successfully removed from account');
    queryClient.invalidateQueries(queryKeys.users.getUsersForAccount);
  };

  return useMutation({
    mutationFn: async (params: { userId: number }) =>
      httpDelete<UserWithRolesSelectDto>({ path: `/accounts/users/${params.userId}` }),
    onError,
    onSuccess,
  });
};

export const useUpdateAccountUserRole = () => {
  const notify = useNotification();

  const onError = (error: Error) => {
    if (error instanceof ApiErrors) notify(error.message);
  };

  const onSuccess = (user: UserWithRolesSelectDto) => {
    notify('User role successfully changed');
    queryClient.setQueryData(queryKeys.users.getUsersForAccount, (oldUsers: UserWithRolesSelectDto[]) => {
      return oldUsers.map((oldUser) => {
        if (oldUser.id === user.id) return user;
        return oldUser;
      });
    });
  };

  return useMutation({
    mutationFn: async (params: { role: AccountUserUpdateDtoRoleEnum; userId: number }) =>
      httpPost<UserWithRolesSelectDto>({
        body: { role: params.role },
        path: `/accounts/users/${params.userId}/update-role`,
      }),
    onError,
    onSuccess,
  });
};
