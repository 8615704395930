import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { currentAccountState } from '../states/account';
import {
  CompetitorSelectDto,
  KeywordStatsSelectDto,
  LicenseStatsDto,
  LocationChangelogSelectDto,
} from '../types/api.types';
import { httpGet } from '../utils/api-client';
import { queryKeys } from './keys';

type GetCompetitorsForLicenseResponse = {
  competitors: CompetitorSelectDto[];
};

type GetKeywordsStatsForLicenseResponse = {
  keywords: KeywordStatsSelectDto[];
};

export function useGetCompetitorsForLicense(licenseId: number) {
  const activeAccount = useRecoilValue(currentAccountState);
  return useQuery({
    enabled: Boolean(activeAccount?.id),
    queryFn: async () => {
      const { competitors } = await httpGet<GetCompetitorsForLicenseResponse>({
        path: `/licenses/${licenseId}/competitors`,
      });
      return competitors;
    },
    queryKey: queryKeys.licenses.getCompetitorsForLicense(licenseId),
  });
}

export function useGetKeywordsStatsForLicense(licenseId: number) {
  const activeAccount = useRecoilValue(currentAccountState);
  return useQuery({
    enabled: Boolean(activeAccount?.id),
    queryFn: async () => {
      const { keywords } = await httpGet<GetKeywordsStatsForLicenseResponse>({
        path: `/licenses/${licenseId}/keywords`,
      });
      return keywords;
    },
    queryKey: queryKeys.licenses.getKeywordsStatsForLicense(licenseId),
  });
}

export function useGetStatsForLicense(licenseId: number) {
  const activeAccount = useRecoilValue(currentAccountState);
  return useQuery({
    enabled: Boolean(activeAccount?.id),
    queryFn: async () => httpGet<LicenseStatsDto>({ path: `/licenses/${licenseId}/stats` }),
    queryKey: queryKeys.licenses.getStatsForLicense(licenseId),
  });
}
export const useGetChangesForLicense = (params: {
  competitorIds?: number[];
  licenseId?: number;
  limit?: number;
  maxTimestamp?: string;
  minTimestamp?: string;
  page?: number;
}) => {
  const { competitorIds = [], licenseId, limit = 10, maxTimestamp = '', minTimestamp = '', page = 1 } = params;

  const queryString = useMemo(() => {
    const minTimestampValue = minTimestamp ? `&minTimestamp=${minTimestamp}` : '';
    const maxTimestampValue = maxTimestamp ? `&maxTimestamp=${maxTimestamp}` : '';
    const competitorIdsValue = competitorIds.length > 0 ? `&competitorIds=[${competitorIds.join(',')}]` : '';
    return `/licenses/${licenseId}/changelog?limit=${limit}&page=${page}${minTimestampValue}${maxTimestampValue}${competitorIdsValue}`;
  }, [competitorIds, licenseId, limit, maxTimestamp, minTimestamp, page]);

  return useQuery(
    ['getChangesForLicense', licenseId, page, limit, minTimestamp, maxTimestamp, competitorIds],
    () => httpGet<LocationChangelogSelectDto>({ path: queryString }),
    {
      enabled: Boolean(licenseId), // Only run the query if licenseId is truthy
    },
  );
};
