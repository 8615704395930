import { DependencyList, useEffect, useState } from 'react';

export function useAsyncState<T>(initValue: (prevValue?: T) => T, dependencies: DependencyList) {
  const [value, setValue] = useState<T>(initValue);

  useEffect(() => {
    setValue(initValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return [value, setValue] as const;
}
