import {
  appsSharp,
  constructSharp,
  layersOutline,
  mailUnreadSharp,
  paperPlaneSharp,
  peopleCircleSharp,
  settingsSharp,
} from 'ionicons/icons';
import { AccountUserUpdateDtoRoleEnum } from '../types/api.types';

export type RouteUrl<T = any> = (params: T) => string;

type RouteKeys =
  | 'accountSettings'
  | 'auth'
  | 'changelog'
  | 'dashboard'
  | 'inviteUsers'
  | 'location'
  | 'locations'
  | 'manageUsers'
  | 'pendingInvites'
  | 'root'
  | 'settings'
  | 'subscriptions';

export type Route = {
  href: string;
  icon?: string;
  name: string;
  requiredRoles?: AccountUserUpdateDtoRoleEnum[];
  url?: RouteUrl;
};

export const inviteQueryPart = 'invite=1';
export const legacyRedirectPart = 'from_legacy=1';
export const supportEmail = 'support@localbusinessprotection.com';

export const routes: Record<RouteKeys, Route> = {
  accountSettings: {
    href: '/account/subscription',
    icon: constructSharp,
    name: 'Manage Subscription',
    requiredRoles: [AccountUserUpdateDtoRoleEnum.ADMIN, AccountUserUpdateDtoRoleEnum.OWNER],
  },
  auth: {
    href: '/auth',
    name: 'Auth',
  },
  changelog: {
    href: '/locations/:id/changelog',
    name: 'My Location changelog',
    url: (id: number) => routes.changelog.href.replace(':id', id.toString()),
  },
  dashboard: {
    href: '/dashboard',
    icon: appsSharp,
    name: 'Dashboard',
  },
  inviteUsers: {
    href: `/users?${inviteQueryPart}`,
    icon: paperPlaneSharp,
    name: 'Invite User',
    requiredRoles: [AccountUserUpdateDtoRoleEnum.ADMIN, AccountUserUpdateDtoRoleEnum.OWNER],
  },
  location: {
    href: '/locations/:id',
    name: 'My Location',
    url: (id: number) => routes.location.href.replace(':id', id.toString()),
  },
  locations: {
    href: '/locations',
    icon: layersOutline,
    name: 'My Locations',
  },
  manageUsers: {
    href: '/users',
    icon: peopleCircleSharp,
    name: 'Manage Users',
    requiredRoles: [AccountUserUpdateDtoRoleEnum.ADMIN, AccountUserUpdateDtoRoleEnum.OWNER],
  },
  pendingInvites: {
    href: '/pending-invites',
    icon: mailUnreadSharp,
    name: 'Pending Invites',
  },
  root: {
    href: '/',
    name: 'Root',
  },
  settings: { href: '/settings', icon: settingsSharp, name: 'User settings' },
  subscriptions: {
    href: '/subscriptions/:id',
    name: 'Subscriptions',
    url: (id: number) => routes.subscriptions.href.replace(':id', id.toString()),
  },
};

export const sideMenuRoutes = [routes.locations, routes.accountSettings, routes.manageUsers, routes.inviteUsers];
