import { IonButton, IonItem, IonLabel, IonList, IonRow } from '@ionic/react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useSwitchToInvitedAccount } from '../../hooks/accounts';
import { useAcceptInvite, useRejectInvite } from '../../queries/invites.query';
import { useGetInvitesForUser } from '../../queries/users.query';
import { capitalize } from '../../utils/text';
import { RolesDropdown } from '../account-users/roles-dropdown';
import { InviteSelectDto } from '../../types/api.types';

const Container = styled.div`
  width: 100%;
`;

const CustomIonList = styled(IonList)`
  padding: 0;
  width: 100%;
`;

const CustomIonItem = styled(IonItem)`
  --inner-padding-end: 0;
  --padding-start: 0;
`;

export const PendingInvites: FunctionComponent = () => {
  const { data: pendingInvites = [] } = useGetInvitesForUser();
  const { mutate: acceptInvite } = useAcceptInvite();
  const { mutate: rejectInvite } = useRejectInvite();
  const [, switchAccount] = useSwitchToInvitedAccount();

  function onAcceptedInvite(invite: InviteSelectDto) {
    const pending = pendingInvites.filter(({ id }) => id !== invite.id);
    if (pending.length === 0) {
      switchAccount(invite.account);
    }
  }

  return (
    <Container>
      <IonRow>
        <CustomIonList>
          <CustomIonItem>
            <IonLabel>Account</IonLabel>
            <IonLabel>Roles</IonLabel>
            <IonLabel>Actions</IonLabel>
          </CustomIonItem>
          {pendingInvites.map((invite) => {
            const {
              account: { name },
              id,
              roles,
            } = invite;
            return (
              <CustomIonItem key={id}>
                <IonLabel>{name}</IonLabel>
                <IonLabel>
                  <RolesDropdown
                    disabled
                    value={roles[0]}
                    userRoles={roles}
                    placeholder={capitalize(roles[0])}
                  />
                </IonLabel>
                <IonLabel>
                  <IonButton
                    color="danger"
                    onClick={() => rejectInvite({ id })}
                  >
                    Reject
                  </IonButton>
                  <IonButton
                    color="primary"
                    onClick={() => acceptInvite({ id }, { onSuccess: () => onAcceptedInvite(invite) })}
                  >
                    Accept
                  </IonButton>
                </IonLabel>
              </CustomIonItem>
            );
          })}
        </CustomIonList>
      </IonRow>
    </Container>
  );
};
