import { Locations } from '../components/licenses/Locations';
import { PageLayout } from '../components/ui/layouts/page';

export const MyLocations: React.FC = () => {
  return (
    <PageLayout
      breadcrumbRoutes={[{ name: 'My Locations' }]}
      title="My Locations"
    >
      <Locations />
    </PageLayout>
  );
};
