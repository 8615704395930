import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { LocationCard } from '../../../LocationCard';
import { Input } from '../../../ui/Input';
import { Button } from '../../../ui/button/Button';
import { Badge } from '../../../Badge';
import { CancelIcon } from '../../../icons/CancelIcon';
import { LocalFalconLocationSelectDto } from '../../../../types/api.types';
import { CoordinatesLocation } from '../../../../types/location.types';

type AddKeywordsStepProps = {
  initialKeywords?: string[];
  location: LocalFalconLocationSelectDto;
  newCordinates: CoordinatesLocation;
  numKeywords: number;
  setKeywords: (keywords: string[]) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const KeywordCountContainer = styled.div`
  align-items: center;
  color: var(--global-font-color-data);
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: space-between;
  margin: 12px 0 8px;
`;

const Searchbar = styled.div`
  align-items: center;
  column-gap: 8px;
  display: flex;
`;

const KeywordsContainer = styled.div`
  flex: 1;
  margin: 12px 0 0;
  overflow-y: auto;
`;

const KeywordContainer = styled.div`
  display: inline-block;
  margin: 0 10px 10px 0;
`;

const Keyword = styled.div`
  column-gap: 6px;
  display: flex;
  font-size: 16px;
  font-weight: 500;
`;

const CancelIconContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

export const AddKeywordsStep: FunctionComponent<AddKeywordsStepProps> = ({
  initialKeywords = [],
  location,
  newCordinates,
  numKeywords,
  setKeywords: setKeywordsForParent,
}) => {
  const [keywordInput, setKeywordInput] = useState('');
  const [keywords, setKeywords] = useState<string[]>(initialKeywords);

  const formDisabled = keywords.length >= numKeywords;
  const addKeywordDisabled =
    formDisabled || keywordInput.trim().length === 0 || keywords.includes(keywordInput.trim().toLowerCase());

  const addKeyword = () => {
    if (!addKeywordDisabled) {
      const keyword = keywordInput.trim().toLowerCase();
      setKeywords([...keywords, keyword]);
      setKeywordsForParent([...keywords, keyword]);
      setKeywordInput('');
    }
  };

  const removeKeyword = (keyword: string) => {
    setKeywords(keywords.filter((previousKeyword) => previousKeyword !== keyword));
    setKeywordsForParent(keywords.filter((previousKeyword) => previousKeyword !== keyword));
  };

  return (
    <Container>
      <div>
        <LocationCard
          location={location}
          newCordinates={newCordinates}
        />
        <KeywordCountContainer>
          <div>Add keywords to your business</div>
          <div>
            {keywords.length}/{numKeywords}
          </div>
        </KeywordCountContainer>
        <Searchbar>
          <Input
            disabled={formDisabled}
            onChange={(input) => setKeywordInput(input)}
            onEnterKey={addKeyword}
            placeholder={'Keyword...'}
            value={keywordInput}
          />
          <Button
            disabled={addKeywordDisabled}
            onClick={addKeyword}
          >
            Add Keyword
          </Button>
        </Searchbar>
      </div>
      <KeywordsContainer>
        {keywords.map((keyword) => (
          <KeywordContainer key={keyword}>
            <Badge type="normal">
              <Keyword>
                <div>{keyword}</div>
                <CancelIconContainer onClick={() => removeKeyword(keyword)}>{CancelIcon}</CancelIconContainer>
              </Keyword>
            </Badge>
          </KeywordContainer>
        ))}
      </KeywordsContainer>
    </Container>
  );
};
