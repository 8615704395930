import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CheckmarkIcon } from '../icons/CheckmarkIcon';
import { MagnifyingGlassIcon } from '../icons/MagnifyingGlassIcon';

const SearchBarWrapper = styled.div`
  background-color: var(--filter-input-bg-color);
  border: solid 1px var(--filter-input-border-color);
  border-radius: var(--filter-input-border-radius);
  color: var(--global-font-color-data);
  font-size: 14px;
  padding: 12px;
  width: 100%;
`;

const SelectedItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4px;
`;

const Item = styled.div`
  align-items: center;
  background: var(--license-competitor-selected-bg-color);
  border-radius: 2px;
  color: var(--license-competitor-selected-font-color);
  display: flex;
  font-size: 0.9rem;
  margin: 2px;
  padding: 4px 8px;
`;

const RemoveButton = styled.span`
  cursor: pointer;
  margin-left: 8px;
`;

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
`;

const IconWrap = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  width: 24px;
  svg {
    height: 20px;
    width: 20px;
    path {
      stroke: var(--global-font-color-data);
    }
  }
`;

const Input = styled.input`
  background: transparent;
  border: none;
  flex: 1;
  outline: none;
  padding: 4px;
`;

const SuggestionBox = styled.div`
  background-color: var(--filter-input-bg-color);
  border: solid 1px var(--table-separator-content-color);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  width: calc(100% - 8px);
  z-index: 10;
`;

const SuggestionItem = styled.div`
  align-items: center;
  border-bottom: solid 1px var(--table-separator-content-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  &:hover {
    background-color: var(--license-competitor-suggested-hover-color);
  }
`;

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  width: 24px;
  svg {
    path {
      stroke: var(--license-competitor-suggested-selected-icon-color);
    }
  }
`;

interface ICatalogItem {
  id: number;
  name: string;
}

interface ISelectedItem {
  id: number;
  name: string;
}

interface IFilters {
  competitorIds: number[];
  endDate: string;
  startDate: string;
}

interface ISearchBarProps {
  catalog: ICatalogItem[];
  filters: IFilters;
  setFilters: (filters: object) => void;
}

const CompetitorSearchBar: React.FC<ISearchBarProps> = ({ catalog, filters, setFilters }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedItems, setSelectedItems] = useState<ISelectedItem[]>([]);
  const [suggestions, setSuggestions] = useState<ICatalogItem[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);

    if (value.trim() === '') {
      setSuggestions([]);
    } else {
      const filteredSuggestions = catalog.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
      setSuggestions(filteredSuggestions);
    }
  };

  const addItem = (item: ICatalogItem) => {
    if (!selectedItems.some((selected) => selected.id === item.id)) {
      setSelectedItems([...selectedItems, item]);
      setFilters((prev) => ({ ...prev, competitorIds: [...prev.competitorIds, item.id] }));
      setInputValue('');
      setSuggestions([]);
    }
  };

  const removeItem = (itemId: number) => {
    setSelectedItems(selectedItems.filter((item) => item.id !== itemId));
    setFilters((prev) => ({ ...prev, competitorIds: prev.competitorIds.filter((id) => id !== itemId) }));
  };

  useEffect(() => {
    if (filters) {
      const selectedItems = filters.competitorIds
        .map((id) => catalog.find((item) => item.id === id))
        .filter((item) => item !== undefined) as ISelectedItem[];
      setSelectedItems(selectedItems);
    }
  }, [catalog, filters]);

  return (
    <SearchBarWrapper>
      {selectedItems.length > 0 && (
        <SelectedItems>
          {selectedItems.map((item) => (
            <Item key={item.id}>
              {item.name}
              <RemoveButton onClick={() => removeItem(item.id)}>×</RemoveButton>
            </Item>
          ))}
        </SelectedItems>
      )}
      <div style={{ position: 'relative' }}>
        <InputContainer>
          <IconWrap>{MagnifyingGlassIcon}</IconWrap>
          <Input
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Search competitors"
          />
        </InputContainer>
        {suggestions.length > 0 && (
          <SuggestionBox>
            {suggestions.map((suggestion) => (
              <SuggestionItem
                key={suggestion.id}
                onClick={() => addItem(suggestion)}
              >
                {suggestion.name}
                {selectedItems.some((selected) => selected.id === suggestion.id) && (
                  <IconWrapper>{CheckmarkIcon}</IconWrapper>
                )}
              </SuggestionItem>
            ))}
          </SuggestionBox>
        )}
      </div>
    </SearchBarWrapper>
  );
};

export default CompetitorSearchBar;
