export const queryKeys = {
  accounts: {
    getAccounts: ['accounts', 'getAccounts'],
  },
  dashboard: {
    getDashboard: ['dashboard', 'getDashboard'],
  },
  invites: {
    getInvitesForAccount: ['invites', 'getInvitesForAccount'],
  },
  licenses: {
    getChangesForLicense: (licenseId: number) => ['licenses', 'getChangesForLicense', licenseId],
    getCompetitorsForLicense: (licenseId: number) => ['licenses', 'getCompetitorsForLicense', licenseId],
    getKeywordsStatsForLicense: (licenseId: number) => ['licenses', 'getKeywordsStatsForLicense', licenseId],
    getStatsForLicense: (licenseId: number) => ['licenses', 'getStatsForLicense', licenseId],
  },
  locations: {
    getLocation: ({ accountId, locationId }: { accountId: number; locationId: number }) => [
      'locations',
      'getLocation',
      accountId,
      locationId,
    ],
    getMyLocations: (accountId: number) => ['locations', 'getMyLocations', accountId],
  },
  packages: {
    getPackage: (packageId: number) => ['packages', 'getPackage', packageId],
    getPackages: ['packages', 'getPackages'],
  },
  placeholders: {
    default: ['placeholders', 'default'],
  },
  places: {
    getPlaces: (query?: string) => ['places', 'getPlaces', query],
    getPlacesLocation: (query: string) => ['places', 'getPlacesLocation', query],
  },
  subscriptions: {
    finalizeSubscriptionForAccount: (subscriptionId: number) => [
      'subscriptions',
      'finalizeSubscriptionForAccount',
      subscriptionId,
    ],
    getSubscriptionForAccount: (subscriptionId: number) => [
      'subscriptions',
      'getSubscriptionForAccount',
      subscriptionId,
    ],
    getSubscriptionsForAccount: (accountId?: number) => ['subscriptions', 'getSubscriptionsForAccount', accountId],
  },
  users: {
    getCurrentUser: ['users', 'getCurrentUser'],
    getInvitesForUser: ['users', 'getInvitesForUser'],
    getUsersForAccount: ['users', 'getUsersForAccount'],
  },
};

export enum QueryStaleness {
  ONE_HOUR = 1000 * 60 * 60,
}
