import { FunctionComponent } from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  align-items: center;
  background-color: var(--checkbox-bg-color);
  border: 2px solid var(--checkbox-border-color);
  color: var(--checkbox-check-color);
  display: flex;
  flex-shrink: 0;
  font-size: 20px;
  height: 20px;
  justify-content: center;
  margin-left: 10px;
  position: absolute;
  width: 20px;
`;

export type CheckboxProps = {
  isChecked?: boolean;
};

export const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
  const { isChecked } = props;
  return <CheckboxContainer>{isChecked ? '✓' : ''}</CheckboxContainer>;
};
