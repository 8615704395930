import { useEffect } from 'react';
import { Theme } from '../types/common';

export function useToggleTheme() {
  useEffect(() => {
    // Use matchMedia to check the user preference
    const prefersDark = matchDarkColorSchema();
    enableTheme(getActiveTheme());
    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addEventListener('change', () => enableTheme(Theme.SYSTEM));

    return prefersDark.removeEventListener('change', () => enableTheme(Theme.SYSTEM));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function matchDarkColorSchema() {
    return window.matchMedia('(prefers-color-scheme: dark)');
  }

  // Add or remove the "dark" class based on if the media query matches
  function toggleDarkTheme(shouldAdd: boolean) {
    document.body.classList.toggle('dark', shouldAdd);
  }

  function enableTheme(theme: Theme) {
    localStorage.setItem('theme', theme);
    toggleDarkTheme(theme === Theme.DARK || (theme === Theme.SYSTEM && matchDarkColorSchema().matches));
  }

  function getActiveTheme(): Theme {
    return (localStorage.getItem('theme') || Theme.SYSTEM) as Theme;
  }

  return {
    enableTheme,
    getActiveTheme,
  };
}
