export const MagnifyingGlassIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3333 10.3333L12.6666 12.6666"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33325 7.33331C3.33325 9.54245 5.12411 11.3333 7.33325 11.3333C8.43974 11.3333 9.44129 10.884 10.1654 10.158C10.8871 9.43442 11.3333 8.43597 11.3333 7.33331C11.3333 5.12417 9.54239 3.33331 7.33325 3.33331C5.12411 3.33331 3.33325 5.12417 3.33325 7.33331Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
