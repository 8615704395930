import { IonAvatar } from '@ionic/react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const CustomAvatar = styled(IonAvatar)`
  align-items: center;
  background-color: var(--nav-avatar-bg-color);
  color: var(--nav-avatar-font-color);
  display: flex;
  font-size: 18px;
  font-weight: 500px;
  height: 40px;
  justify-content: center;
  width: 40px;
`;

export type AvatarProps = {
  avatarText?: string;
  avatarUrl?: string;
};

export const Avatar: FunctionComponent<AvatarProps> = (props) => {
  const { avatarText, avatarUrl } = props;
  return (
    <CustomAvatar>
      {avatarUrl && <img src={avatarUrl} />}
      {avatarText && !avatarUrl && <span>{avatarText}</span>}
    </CustomAvatar>
  );
};
