import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useGetDashboard } from '../../queries/dashboard.query';
import { routes } from '../../routes';
import { LocationTable } from '../licenses/table';
import { TitleText } from '../ui/Text';
import { Button } from '../ui/button/Button';
import { Card, CardContent, CardSecondaryContent } from '../ui/card';
import { CenteredCol, CenteredRow, JustifyEndRow } from '../ui/containers';
import { LicenseSelectDtoStatusEnum } from '../../types/api.types';
import { useGetMyLocations } from '../../queries/locations.query';

const maxProtectedLocationsToShow = 5;

const Grid = styled(IonGrid)`
  --padding-end: 0 !important;
  --padding-start: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-inline-end: 0;
  padding-inline-start: 0;
`;
const Row = styled(IonRow)`
  --padding-end: 0 !important;
  --padding-start: 0 !important;
  gap: 20px;
  padding-inline-end: 0;
  padding-inline-start: 0;
`;

const CardLink = styled(Link)`
  text-decoration: none;
`;

const ManageLocationsButton = styled(Button)`
  font-weight: 600;
  margin-top: 16px;
  min-width: 160px;
  padding: 0 23px;
`;

const Counter = styled(CardContent)`
  margin-bottom: 7px;
  a,
  span {
    font-size: 30px;
    font-weight: 600;
  }

  a {
    color: var(--global-title-color);
  }
`;

const CardCounter: FunctionComponent<{
  count: number;
  href?: string;
  text: string;
}> = (props) => {
  const { count, href, text } = props;
  return (
    <CenteredCol>
      <Counter>
        {count > 0 && href ? (
          <CardLink to={href}>
            <CountUp end={count} />
          </CardLink>
        ) : (
          <CountUp end={count} />
        )}
      </Counter>
      <CardSecondaryContent>{text}</CardSecondaryContent>
    </CenteredCol>
  );
};

const CountersContainer = styled(CenteredRow)`
  gap: 20px;
  justify-content: space-around;
`;

const columnsHeaders = [
  {
    cellAlign: 'flex-start',
    dataKey: 'location',
    flex: 2,
    hasFilter: false,
    hasSort: true,
    key: 'location-header-location',
    name: 'Location',
  },
  {
    cellAlign: 'center',
    dataKey: 'keywordsCount',
    flex: 1,
    hasFilter: false,
    hasSort: false,
    key: 'location-header-keywords',
    name: 'Keywords',
  },
  {
    cellAlign: 'center',
    dataKey: 'competitorsCount',
    flex: 1,
    hasFilter: false,
    hasSort: false,
    key: 'location-header-competitors',
    name: 'Competitors',
  },
  {
    cellAlign: 'center',
    dataKey: 'empty',
    flex: 1,
    key: 'location-header-empty',
    name: '',
  },
  {
    cellAlign: 'center',
    dataKey: 'empty-2',
    flex: 1,
    key: 'location-header-empty-2',
    name: '',
  },
];

export const Dashboard = () => {
  const {
    data: { competitorsKeywordsChanged, competitorsMonitored, competitorsRemoved },
  } = useGetDashboard();
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null as 'asc' | 'desc' | null);

  const { data: locations = [] } = useGetMyLocations();
  const router = useIonRouter();

  const protectedLocations = locations.filter(
    (location) => location.license?.status === LicenseSelectDtoStatusEnum.ACTIVE,
  );
  const locationsClaimed = locations.length - protectedLocations.length;

  const handleSort = (column) => {
    // eslint-disable-next-line no-negated-condition
    if (sortColumn !== column) {
      setSortDirection('asc');
      setSortColumn(column);
    } else {
      switch (sortDirection) {
        case 'asc':
          setSortDirection('desc');
          break;
        case 'desc':
          setSortDirection(null);
          break;
        default:
          setSortDirection('asc');
      }
    }
  };

  const sortedAndFilteredItems = useMemo(() => {
    const data = protectedLocations.slice(0, maxProtectedLocationsToShow);

    if (!sortColumn || sortDirection === null) return data;

    return data.sort((a, b) => {
      const isReversed = sortDirection === 'desc' ? -1 : 1;
      switch (sortColumn) {
        case 'location':
          return isReversed * a.name.localeCompare(b.name);
        default:
          return 0;
      }
    });
  }, [protectedLocations, sortColumn, sortDirection]);

  useEffect(() => {
    setSortColumn(null);
    setSortDirection(null);
  }, [router.routeInfo.pathname]);

  return (
    <Grid>
      {(protectedLocations.length > 0 ||
        locationsClaimed > 0 ||
        competitorsKeywordsChanged > 0 ||
        competitorsMonitored > 0 ||
        competitorsRemoved > 0) && (
        <Row>
          <IonCol>
            <Card title="Locations">
              <CountersContainer>
                <CardCounter
                  count={protectedLocations.length}
                  text="Protected"
                  href={routes.locations.href}
                />
                <CardCounter
                  count={locationsClaimed}
                  text="Claimed"
                />
              </CountersContainer>
            </Card>
          </IonCol>
          <IonCol>
            <Card title="Competitors">
              <CountersContainer>
                <CardCounter
                  count={competitorsMonitored}
                  text="Monitored"
                />
                <CardCounter
                  count={competitorsRemoved}
                  text="Removed"
                />
                <CardCounter
                  count={competitorsKeywordsChanged}
                  text="Fixed"
                />
              </CountersContainer>
            </Card>
          </IonCol>
          <IonCol></IonCol>
        </Row>
      )}
      <Row>
        <IonCol>
          <TitleText>My Locations</TitleText>
          <LocationTable
            data={sortedAndFilteredItems}
            columns={columnsHeaders}
            handleSort={handleSort}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
          />
          <JustifyEndRow>
            {protectedLocations.length > 0 && (
              <ManageLocationsButton onClick={() => router.push(routes.locations.href)}>
                Manage My Locations
              </ManageLocationsButton>
            )}
          </JustifyEndRow>
        </IonCol>
      </Row>
    </Grid>
  );
};
