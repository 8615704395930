import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { openUrlInNewTab } from '../utils/url';
import { LocalFalconLocationSelectDto } from '../types/api.types';
import { CoordinatesLocation } from '../types/location.types';
import { OpenLinkIcon } from './icons/OpenLinkIcon';
import { Badge } from './Badge';

type LocationCardProps = { location: LocalFalconLocationSelectDto; newCordinates: CoordinatesLocation };

const Container = styled.div`
  background: var(--global-components-bg-color);
  border: 1px solid var(--global-components-border-color);
  border-radius: 8px;
  padding: 20px;
`;

const TitleRow = styled.div`
  align-items: center;
  column-gap: 6px;
  display: flex;
`;

const Name = styled.div`
  color: var(--global-font-color-data);
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const IconContainer = styled.div`
  align-items: center;
  background: var(--global-icon-bg-color);
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 20px;
  justify-content: center;
  width: 20px;

  &:hover {
    background: var(--global-icon-bg-color-hover);
  }
`;

const Address = styled.div`
  color: var(--global-font-color-subtitle);
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const BadgeContainer = styled.div`
  display: inline-block;
  margin: 8px 0 0;
`;

export const LocationCard: FunctionComponent<LocationCardProps> = ({
  location: { address, googleMapsUrl, isServiceAreaBusiness, name },
  newCordinates: { address: newAddress, lat, lng },
}) => {
  const addressHasChanged = address !== newAddress;
  const formattedLatLong = `${lat}, ${lng}`;
  const newAddressOrLatLong = newAddress ? newAddress : formattedLatLong;
  const displayAddress = addressHasChanged ? newAddressOrLatLong : address;
  return (
    <Container>
      <TitleRow>
        <Name>{name}</Name>
        <IconContainer onClick={() => openUrlInNewTab(googleMapsUrl)}>{OpenLinkIcon}</IconContainer>
      </TitleRow>
      {(address || newAddress) && <Address>{displayAddress}</Address>}
      {isServiceAreaBusiness && (
        <BadgeContainer>
          <Badge type="warning">SERVICE AREA BUSINESS</Badge>
        </BadgeContainer>
      )}
    </Container>
  );
};
