import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { ApiErrors, httpGet } from '../utils/api-client';
import { LocalFalconLocationSelectDto, PlaceSelectDto } from '../types/api.types';
import { queryKeys } from './keys';

type GetPlacesResponse = {
  places: PlaceSelectDto[];
};

type GetPlacesLocationsResponse = {
  locations: LocalFalconLocationSelectDto[];
};

export const usePlaces = (initialValue?: string) => {
  const [query, setQuery] = useState<string | undefined>(initialValue);
  return [
    setQuery,
    useQuery<PlaceSelectDto[], ApiErrors>({
      enabled: Boolean(query),
      queryFn: async () => {
        const { places } = await httpGet<GetPlacesResponse>({
          path: '/places',
          queryStringParameters: { query },
        });
        return places;
      },
      queryKey: queryKeys.places.getPlaces(query),
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }),
  ] as const;
};

export const usePlacesLocation = (query?: string) => {
  const enabled = query !== undefined;

  return useQuery<LocalFalconLocationSelectDto[], ApiErrors>({
    enabled,
    queryFn: async () => {
      const { locations } = await httpGet<GetPlacesLocationsResponse>({
        path: '/places/locations',
        queryStringParameters: { query },
      });
      return locations;
    },
    queryKey: enabled ? queryKeys.places.getPlacesLocation(query) : queryKeys.placeholders.default,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};
