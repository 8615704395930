import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../hooks/screen';
import { useAsyncState } from '../../hooks/state';
import { useCreateSubscription, useUpdateSubscription } from '../../hooks/subscriptions';
import { isEqualObjectsByKeys } from '../../utils/objects';
import { Button } from '../ui/button/Button';
import { PackageSelectDto, SubscriptionSelectDto, SubscriptionSelectDtoStatusEnum } from '../../types/api.types';
import { SubscriptionCard } from './SubscriptionCard';
import { SubscriptionOptions, SubscriptionOptionsTable } from './SubscriptionOptions';

const PageContentContainer = styled.div`
  column-gap: 30px;
  display: flex;
  margin: 15px 0 0;
`;

const PageContentLeft = styled.div`
  max-width: 630px;
  width: 100%;
`;

const PageContentRight = styled.div`
  flex-shrink: 0;
  width: 220px;
`;

const CardContainer = styled.div`
  background-color: var(--subscriptions-bg-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const HelpContainer = styled(CardContainer)`
  padding: 18px;
  row-gap: 10px;
`;

const Title = styled.div<{ margin?: string }>`
  color: var(--subscriptions-title-font-color);
  font-size: 18px;
  font-weight: 500;
  margin: ${({ margin }) => (margin ? margin : '')};
`;

const Text = styled.div`
  color: var(--subscriptions-data-font-color);
  font-size: 12px;
  line-height: normal;
`;

const HelpText = styled(Text)`
  font-size: 12px;
`;

const SubscriptionTypesContainer = styled.div`
  column-gap: 30px;
  display: flex;
  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 14px 0 0;
  width: 100%;
`;

const UpdateSubscriptionButton = styled(Button)`
  font-weight: 600;
  min-width: 160px;
  padding: 0 23px;
`;

const QuantityHelpText = styled.div`
  font-size: 12px;
  font-style: italic;
  margin: 15px 0 0;
`;

const licenseAddOnId = 'licenseAddOnId';
const licenseInitialQuantity = 1;

export const ManageSubscription: React.FC<{
  isPackagesLoading?: boolean;
  isSubscriptionLoading: boolean;
  packages?: PackageSelectDto[];
  subscription?: SubscriptionSelectDto;
}> = (props) => {
  const { isPackagesLoading, isSubscriptionLoading, packages = [], subscription } = props;
  const {
    create: createSubscription,
    createSubscriptionQuery: { isLoading: isCreating },
  } = useCreateSubscription();
  const {
    update: updateSubscription,
    updateSubscriptionQuery: { isLoading: isUpdating },
  } = useUpdateSubscription(subscription?.id);
  const [selectedPackageIndex, setSelectedPackageIndex] = useAsyncState<number>(() => {
    let index = 0;

    if (subscription?.isActive) {
      index = packages.findIndex(({ id }) => id === subscription?.package.id);
    }

    return index === -1 ? 0 : index;
  }, [packages, subscription]);

  const activePackage = packages[selectedPackageIndex];
  const useCheckout = !subscription || subscription.useCheckout;
  const isCreate = useCheckout || !subscription.isActive;

  const [quantitiesByAddOnId, setQuantitiesByAddOnId] = useState<Record<string | number, number>>({
    [licenseAddOnId]: licenseInitialQuantity,
  });

  useEffect(() => {
    if (subscription) {
      const quantities = getSubscriptionQuantities();
      setQuantitiesByAddOnId((prevValue) => ({ ...prevValue, ...quantities }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  const onCreateSubscription = () => {
    const lineItems = Object.entries(quantitiesByAddOnId)
      .filter(([id, value]) => id !== licenseAddOnId && value > 0)
      .map(([addOnId, quantity]) => ({
        addOnId: Number(addOnId),
        quantity,
      }));

    createSubscription({
      lineItems,
      numLicenses: quantitiesByAddOnId[licenseAddOnId],
      packageId: packages[selectedPackageIndex].id,
    });
  };

  const onUpdateSubscription = () => {
    const lineItems = Object.entries(quantitiesByAddOnId)
      .filter(([id, value]) => id !== licenseAddOnId && value > 0)
      .map(([addOnId, quantity]) => ({
        addOnId: Number(addOnId),
        quantity,
      }));

    updateSubscription({
      lineItems,
      numLicenses: quantitiesByAddOnId[licenseAddOnId],
      packageId: packages[selectedPackageIndex].id,
    });
  };

  const onClickSubscribe = () => (useCheckout ? onCreateSubscription() : onUpdateSubscription());

  const onChangeQuantity = (addOnId: number) => (value: number) => {
    setQuantitiesByAddOnId((prevQuantities) => ({
      ...prevQuantities,
      [addOnId]: value,
    }));
  };

  function getSubscriptionQuantities() {
    let quantities = {};
    if (subscription?.isActive) {
      quantities[licenseAddOnId] = subscription?.numLicenses;

      const addOns = subscription?.subscriptionAddOns.reduce(
        (result, { addOnId, quantity }) => ({ ...result, [addOnId]: quantity }),
        {},
      );

      quantities = {
        ...quantities,
        ...addOns,
      };
    }

    return quantities;
  }

  const subscriptionOptions: SubscriptionOptions[] = [
    {
      id: licenseAddOnId as unknown as number,
      minValue: 1,
      name: 'Location Licenses',
      pricePerMonth: activePackage?.pricePerMonth,
    },
    ...(activePackage ? activePackage.addOns : []),
  ];

  const total = subscriptionOptions
    .map(({ id, pricePerMonth }) => (quantitiesByAddOnId[id] ?? 0) * pricePerMonth)
    .reduce((total, value) => total + value, 0);

  const isUpdateSubscriptionEnabled =
    !subscription ||
    subscription.status === SubscriptionSelectDtoStatusEnum.INACTIVE ||
    activePackage?.id !== subscription?.package.id ||
    !isEqualObjectsByKeys(quantitiesByAddOnId, getSubscriptionQuantities());

  const isValidLicenseCount = !subscription || subscription.usedLicenses <= quantitiesByAddOnId[licenseAddOnId];

  return (
    <PageContentContainer>
      <PageContentLeft>
        <SubscriptionTypesContainer>
          {packages.map((pkg, index) => (
            <SubscriptionCard
              key={pkg.id}
              pkg={pkg}
              onClick={() => setSelectedPackageIndex(index)}
              isChecked={selectedPackageIndex === index}
            />
          ))}
        </SubscriptionTypesContainer>
        <Title>Subscription options:</Title>
        <SubscriptionOptionsTable
          isLoading={isPackagesLoading || isSubscriptionLoading}
          onChangeQuantity={onChangeQuantity}
          quantities={quantitiesByAddOnId}
          total={total}
          subscriptionOptions={subscriptionOptions}
        />
        <ButtonContainer>
          <div>
            {subscription ? `${subscription?.usedLicenses} of ${subscription?.numLicenses} Location Licenses used` : ''}
          </div>
          <UpdateSubscriptionButton
            disabled={isCreating || isUpdating || !isUpdateSubscriptionEnabled || !isValidLicenseCount}
            onClick={onClickSubscribe}
            isLoading={isCreating || isUpdating}
          >
            {isCreate ? 'Create Subscription' : 'Update Subscription'}
          </UpdateSubscriptionButton>
        </ButtonContainer>
        {!isValidLicenseCount && (
          <QuantityHelpText>You must remove protection from your locations before removing licenses</QuantityHelpText>
        )}
      </PageContentLeft>
      <PageContentRight className="ion-hide-xl-down">
        <HelpContainer>
          <Title>What's a Location?</Title>
          <HelpText>Locations are physical locations that you sign up for protection.</HelpText>
          <HelpText>Each physical location requires its own license.</HelpText>
          <Title margin={'20px 0 0'}>What's a Keyword?</Title>
          <HelpText>
            When protecting a Location, specific keywords/phrases are monitored for that specific location.
          </HelpText>
          <HelpText>Each keyword/location combination requires a separate keyword license.</HelpText>
          <HelpText>Note License Locations come with bundled keywords as well.</HelpText>
        </HelpContainer>
      </PageContentRight>
    </PageContentContainer>
  );
};
