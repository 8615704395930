import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useNotification } from '../hooks/notification';
import { useIsCurrentUserAllowed } from '../hooks/users';
import { currentAccountState } from '../states/account';
import { ApiErrors, httpDelete, httpGet, httpPatch, httpPost } from '../utils/api-client';
import { AccountUserUpdateDtoRoleEnum, InviteCreateDto, InviteSelectDto, InviteUpdateDto } from '../types/api.types';
import { queryClient } from './client';
import { queryKeys } from './keys';

type GetInvitesForAccountResponse = {
  invites: InviteSelectDto[];
};

export const useGetInvitesForAccount = () => {
  const activeAccount = useRecoilValue(currentAccountState);
  const isAllowed = useIsCurrentUserAllowed(AccountUserUpdateDtoRoleEnum.ADMIN);
  return useQuery<InviteSelectDto[], ApiErrors>({
    enabled: Boolean(activeAccount?.id) && isAllowed,
    queryFn: async () => {
      const { invites } = await httpGet<GetInvitesForAccountResponse>({
        path: '/invites',
      });
      return invites;
    },
    queryKey: queryKeys.invites.getInvitesForAccount,
    select: (data) => data.sort(({ id: idA }, { id: idB }) => idA - idB),
  });
};

export const useAcceptInvite = () => {
  const notify = useNotification();

  const onError = (error: Error) => {
    if (error instanceof ApiErrors) notify(error.message);
  };

  const onSuccess = () => {
    notify('Account invite accepted');
    queryClient.invalidateQueries(queryKeys.accounts.getAccounts);
    queryClient.invalidateQueries(queryKeys.users.getInvitesForUser);
  };

  return useMutation({
    mutationFn: async (params: { id: number }) => httpPost({ path: `/invites/${params.id}/accept` }),
    onError,
    onSuccess,
  });
};

export const useInviteEmail = () => {
  const notify = useNotification();

  const onError = (error: Error) => {
    if (error instanceof ApiErrors) notify(error.message);
    queryClient.invalidateQueries(queryKeys.invites.getInvitesForAccount);
  };

  const onSuccess = () => {
    notify('Account invite sent');
    queryClient.invalidateQueries(queryKeys.invites.getInvitesForAccount);
  };

  return useMutation({
    mutationFn: async (body: InviteCreateDto) => httpPost({ body, path: '/invites' }),
    onError,
    onSuccess,
  });
};

export const useRejectInvite = () => {
  const notify = useNotification();

  const onError = (error: Error) => {
    if (error instanceof ApiErrors) notify(error.message);
  };

  const onSuccess = () => {
    notify('Account invite rejected');
    queryClient.invalidateQueries(queryKeys.users.getInvitesForUser);
  };

  return useMutation({
    mutationFn: async (params: { id: number }) => httpPost({ path: `/invites/${params.id}/reject` }),
    onError,
    onSuccess,
  });
};

export const useRevokeInvite = () => {
  const notify = useNotification();

  const onError = (error: Error) => {
    if (error instanceof ApiErrors) notify(error.message);
  };

  const onSuccess = () => {
    notify('Account invite revoked');
    queryClient.invalidateQueries(queryKeys.invites.getInvitesForAccount);
  };

  return useMutation({
    mutationFn: async (params: { id: number }) => httpDelete({ path: `/invites/${params.id}` }),
    onError,
    onSuccess,
  });
};

export const useResendInvite = () => {
  const notify = useNotification();

  const onError = (error: Error) => {
    if (error instanceof ApiErrors) notify(error.message);
  };

  const onSuccess = () => {
    notify('Account invite sent');
    queryClient.invalidateQueries(queryKeys.invites.getInvitesForAccount);
  };

  return useMutation({
    mutationFn: async (params: { id: number }) => httpPost({ path: `/invites/${params.id}/resend` }),
    onError,
    onSuccess,
  });
};

export const useUpdateInviteRole = () => {
  const notify = useNotification();

  const onError = (error: Error) => {
    if (error instanceof ApiErrors) notify(error.message);
  };

  const onSuccess = () => {
    notify('Invite role successfully changed');
    queryClient.invalidateQueries(queryKeys.invites.getInvitesForAccount);
  };

  return useMutation({
    mutationFn: async (params: InviteUpdateDto & { inviteId: number }) => {
      const { inviteId, ...body } = params;
      return httpPatch({ body, path: `/invites/${inviteId}` });
    },
    onError,
    onSuccess,
  });
};
