import { FunctionComponent } from 'react';
import { useGetActiveUserForAccount } from '../../hooks/users';
import {
  useGetInvitesForAccount,
  useResendInvite,
  useRevokeInvite,
  useUpdateInviteRole,
} from '../../queries/invites.query';
import { useGetUsersForAccount, useRemoveUserFromAccount, useUpdateAccountUserRole } from '../../queries/users.query';
import { formatDate } from '../../utils/dates.util';
import { isAllowed } from '../../utils/role';
import { capitalize } from '../../utils/text';
import { Button } from '../ui/button/Button';
import { Row } from '../ui/containers';
import { Table, TableCell, TableElement } from '../ui/table';
import {
  AccountUserUpdateDtoRoleEnum,
  InviteSelectDtoRolesEnum,
  InviteUpdateDtoRolesEnum,
  UserWithRolesSelectDtoRolesEnum,
} from '../../types/api.types';
import { RolesDropdown } from './roles-dropdown';

const headers = [
  {
    cellAlign: 'flex-start',
    dataKey: 'email',
    flex: 2,
    key: 'account-user-header-email',
    name: 'Email',
  },
  {
    cellAlign: 'center',
    dataKey: 'roles',
    key: 'account-user-header-roles',
    name: 'Roles',
  },
  {
    cellAlign: 'flex-end',
    dataKey: 'space',
    flex: 1.5,
    key: 'account-user-header-space',
    name: '',
  },
  {
    dataKey: 'space-2',
    flex: 2,
    key: 'account-user-header-space-2',
    name: '',
  },
];

export const AccountUsers: FunctionComponent = () => {
  const currentUser = useGetActiveUserForAccount();
  const { data: usersForAccount = [] } = useGetUsersForAccount();
  const { data: invitesForAccount = [] } = useGetInvitesForAccount();
  const { mutate: updateAccountUserRole } = useUpdateAccountUserRole();
  const { mutate: updateInviteRole } = useUpdateInviteRole();
  const { mutate: removeUserFromAccount } = useRemoveUserFromAccount();
  const { mutate: revokeInvite } = useRevokeInvite();
  const { mutate: resendInvite } = useResendInvite();

  const onUpdateUserRole = (params: { index: number; role: AccountUserUpdateDtoRoleEnum }) => {
    const { index, role } = params;
    const user = usersForAccount[index];

    if (user.roles.includes(role as unknown as UserWithRolesSelectDtoRolesEnum)) return;

    updateAccountUserRole({ role, userId: user.id });
  };

  const onUpdateInviteRole = (params: { index: number; role: InviteUpdateDtoRolesEnum }) => {
    const { index, role } = params;
    const invite = invitesForAccount[index];

    if (invite.roles.includes(role as unknown as InviteSelectDtoRolesEnum)) return;

    updateInviteRole({
      inviteId: invite.id,
      roles: [role],
    });
  };

  const isCurrentUser = (userId: number) => {
    return currentUser?.id === userId;
  };

  return (
    <Table
      header={headers}
      items={[
        ...usersForAccount.map<TableElement>(({ email, id: userId, roles }, index) => {
          const disabled =
            isCurrentUser(userId) ||
            !(
              currentUser &&
              isAllowed({
                role1: currentUser.roles[0] as any,
                role2: roles[0] as any,
              })
            );
          return {
            content: (
              <>
                <TableCell
                  flex={2}
                  cellAlign="flex-start"
                >
                  {email}
                </TableCell>
                <TableCell cellAlign="center">
                  <RolesDropdown
                    key={roles[0]}
                    disabled={disabled}
                    value={roles[0]}
                    userRoles={roles}
                    onChange={(role) =>
                      onUpdateUserRole({
                        index,
                        role: role as AccountUserUpdateDtoRoleEnum,
                      })
                    }
                    placeholder={capitalize(roles[0])}
                  />
                </TableCell>
                <TableCell
                  flex={1.5}
                  cellAlign="flex-end"
                />
                <TableCell
                  flex={2}
                  cellAlign="flex-end"
                >
                  <Button
                    disabled={disabled}
                    onClick={() => removeUserFromAccount({ userId })}
                    style="danger"
                  >
                    Remove User
                  </Button>
                </TableCell>
              </>
            ),
            id: `user-${userId}`,
          };
        }),
        ...invitesForAccount.map<TableElement>(({ createdAt, id, roles, user: { email } }, index) => {
          return {
            content: (
              <>
                <TableCell
                  flex={2}
                  cellAlign="flex-start"
                >
                  {email}
                </TableCell>
                <TableCell cellAlign="center">
                  <RolesDropdown
                    value={roles[0]}
                    userRoles={roles}
                    placeholder={capitalize(roles[0])}
                    onChange={(role) =>
                      onUpdateInviteRole({
                        index,
                        role: role as InviteUpdateDtoRolesEnum,
                      })
                    }
                  />
                </TableCell>
                <TableCell
                  flex={1.5}
                  cellAlign="flex-end"
                >
                  Invited {formatDate(createdAt)}
                </TableCell>
                <TableCell
                  flex={2}
                  cellAlign="flex-end"
                >
                  <Row gap={5}>
                    <Button onClick={() => resendInvite({ id })}>Resend</Button>
                    <Button
                      onClick={() => revokeInvite({ id })}
                      style="danger"
                    >
                      Revoke
                    </Button>
                  </Row>
                </TableCell>
              </>
            ),
            id: `invite-${id}`,
          };
        }),
      ]}
    />
  );
};
