export const RightArrowIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.99998 8L12.3333 8M12.3333 8L8.33331 12M12.3333 8L8.33331 4"
      stroke="#BABABA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
