import { IonSelect, IonSelectOption } from '@ionic/react';
import { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import type { JSX } from '@ionic/core/components';

export type DropdownProps = Partial<Omit<JSX.IonSelect, 'onIonChange' | 'onChange' | 'value'>> & {
  items: PropsWithChildren<Partial<JSX.IonSelectOption>>[];
  onChange?: (value: string) => void;
  value?: string;
};

export const Dropdown: FunctionComponent<DropdownProps> = (props) => {
  const { items, onChange, value, ...selectProps } = props;
  const [selectedOption, setSelectedOption] = useState(value);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  function onChangeOption(newValue: string) {
    if (newValue === value || newValue === selectedOption) {
      return;
    }
    setSelectedOption(newValue);
    if (onChange) onChange(newValue);
  }

  return (
    <IonSelect
      interface="popover"
      value={selectedOption}
      onIonChange={(e) => onChangeOption(e.detail.value)}
      {...selectProps}
    >
      {items.map(({ children, ...options }, index) => (
        <IonSelectOption
          key={index}
          {...options}
        >
          {children}
        </IonSelectOption>
      ))}
    </IonSelect>
  );
};
