import { useIonRouter } from '@ionic/react';
import styled from 'styled-components';

export type BreadcrumbRoute = { href?: string; name: string };

type BreadcrumbsProps = {
  mobileOnly?: boolean;
  routes: BreadcrumbRoute[];
};

const BreadcrumbsContainer = styled.div<{ mobileOnly?: boolean }>`
  color: var(--breadcrumbs-font-color);
  display: flex;
  flex: 1;
  height: 35px;
  margin: ${({ mobileOnly }) => (mobileOnly ? '-10px 0 15px 0' : '')};
`;

const RouteContainer = styled.div`
  align-items: center;
  display: flex;
  overflow: hidden;
`;

const Route = styled.div<{
  hasHref?: boolean;
  mobileOnly?: boolean;
  numRoutes: number;
}>`
  font-size: ${({ mobileOnly, numRoutes }) => (mobileOnly || numRoutes > 1 ? '15px' : '16px')};
  &:hover {
    color: ${({ hasHref }) => (hasHref ? 'var(--breadcrumbs-font-color-hover)' : 'default')};
    cursor: ${({ hasHref }) => (hasHref ? 'pointer' : 'default')};
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Arrow = styled.div<{ mobileOnly?: boolean; numRoutes: number }>`
  font-size: ${({ mobileOnly, numRoutes }) => (mobileOnly || numRoutes > 1 ? '15px' : '16px')};
  margin: 0 7px;
`;

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ mobileOnly, routes }) => {
  const router = useIonRouter();

  return (
    <BreadcrumbsContainer
      className={mobileOnly ? 'ion-hide-lg-up' : ''}
      mobileOnly={mobileOnly}
    >
      {routes.map((route, index) => (
        <RouteContainer
          key={index}
          onClick={() => route.href && router.push(route.href)}
        >
          <Route
            hasHref={Boolean(route.href)}
            mobileOnly={mobileOnly}
            numRoutes={routes.length}
          >
            {route.name}
          </Route>
          {index !== routes.length - 1 && (
            <Arrow
              mobileOnly={mobileOnly}
              numRoutes={routes.length}
            >
              {'>'}
            </Arrow>
          )}
        </RouteContainer>
      ))}
    </BreadcrumbsContainer>
  );
};
