export const OpenLinkIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 11.9913L17.9912 6M17.9912 6H14.6914M17.9912 6V9.29985"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 6.4C18 6.17909 17.8209 6 17.6 6M11 6H6.4C6.17909 6 6 6.17909 6 6.4V17.6C6 17.8209 6.17909 18 6.4 18H17.6C17.8209 18 18 17.8209 18 17.6V13"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
