import { Amplify, Auth } from 'aws-amplify';
import { useRecoilValue } from 'recoil';
import { config } from '../config';
import { currentAccountState } from '../states/account';

export function useConfigureAmplify() {
  const currentAccount = useRecoilValue(currentAccountState);
  Amplify.configure({
    API: {
      endpoints: [
        {
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
              ...(currentAccount && {
                [config.app.accountIdHeader]: currentAccount.id,
              }),
            };
          },
          endpoint: config.api.baseUrl,
          name: config.api.name,
        },
      ],
    },
    Auth: {
      oauth: {
        domain: config.auth.oauthDomain,
        redirectSignIn: config.app.baseUrl + '/',
        redirectSignOut: config.app.baseUrl + '/',
        responseType: 'code',
        scope: ['email', 'openid', 'profile'],
      },
      region: 'us-east-1',
      userPoolId: config.auth.userPoolId,
      userPoolWebClientId: config.auth.appClientId,
    },
  });
}
