import { useIonRouter } from '@ionic/react';
import { Location } from 'history';
import { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useGetActiveAccount } from '../../hooks/accounts';
import { useToggleTheme } from '../../hooks/theme';
import { useGetAccounts } from '../../queries/accounts.query';
import { queryClient } from '../../queries/client';
import { queryKeys } from '../../queries/keys';
import { useGetCurrentUser, useGetInvitesForUser } from '../../queries/users.query';
import { routes } from '../../routes';
import { Theme } from '../../types/common';

export type AppContext = {
  enableTheme: (theme: Theme) => void;
  getActiveTheme: () => Theme;
  isLoading?: boolean;
  location?: Location<unknown>;
};

export const AppContext = createContext<null | AppContext>(null);

export const AppProvider: FunctionComponent<PropsWithChildren> = (props) => {
  const { children } = props;
  const currentUserQuery = useGetCurrentUser();
  const { enableTheme, getActiveTheme } = useToggleTheme();
  const getInvitesForUserQuery = useGetInvitesForUser();
  const getAccountsQuery = useGetAccounts();
  const currentAccount = useGetActiveAccount();
  const router = useIonRouter();
  const location = useLocation();

  const pendingInvites = getInvitesForUserQuery.data ?? [];
  const accounts = getAccountsQuery.data ?? [];
  const isLoading = [currentUserQuery.isLoading, getInvitesForUserQuery.isLoading, getAccountsQuery.isLoading].some(
    (isLoading) => isLoading,
  );

  useEffect(() => {
    queryClient.invalidateQueries(queryKeys.subscriptions.getSubscriptionsForAccount(currentAccount?.id));
    queryClient.invalidateQueries(queryKeys.invites.getInvitesForAccount);
    queryClient.invalidateQueries(queryKeys.users.getUsersForAccount);
  }, [currentAccount]);

  useEffect(() => {
    if (pendingInvites && pendingInvites.length > 0) {
      router.push(routes.pendingInvites.href, 'root');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, pendingInvites]);

  return (
    <AppContext.Provider value={{ enableTheme, getActiveTheme, isLoading, location }}>{children}</AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext) as AppContext;
