import { FunctionComponent } from 'react';
import { capitalize } from '../../utils/text';
import { Dropdown, DropdownProps } from '../ui/dropdown';
import { accountUserRoles } from '../../types/user.types';

export type RolesDropdownProps = Omit<DropdownProps, 'items'> & {
  userRoles?: string[];
};

export const RolesDropdown: FunctionComponent<RolesDropdownProps> = (props) => {
  const { userRoles, ...dropdownProps } = props;
  const catalog = ['SELECT', ...accountUserRoles];
  return (
    <Dropdown
      {...dropdownProps}
      interface="popover"
      value={userRoles?.[0] ?? 'SELECT'}
      items={catalog.map((role) => ({
        children: capitalize(role),
        disabled: userRoles?.includes(role) || role === 'SELECT',
        key: role,
        value: role,
      }))}
    />
  );
};
