import { useState } from 'react';
import { AddLocationModal } from '../components/modals/add-location';
import { InviteUsersModal } from '../components/modals/invite-users';
import { CreateAccountModal } from '../components/modals/create-account';
import { LegacyRedirectModal } from '../components/modals/legacy-redirect';
import { LocationSelectDto, SubscriptionSelectDto } from '../types/api.types';

export function useAddLocationModal(subscription?: SubscriptionSelectDto, seedLocation?: LocationSelectDto) {
  const [isOpen, setIsOpen] = useState(false);

  const modal = isOpen ? (
    <AddLocationModal
      isOpen={isOpen}
      minKeywords={subscription?.package.numKeywords}
      seedLocation={seedLocation}
      setIsOpen={setIsOpen}
    />
  ) : (
    <></>
  );

  const dismissModal = () => setIsOpen(false);
  const presentModal = () => setIsOpen(true);

  return { dismissModal, modal, presentModal };
}

export function useCreateAccountModal() {
  const [isOpen, setIsOpen] = useState(false);

  const modal = isOpen ? (
    <CreateAccountModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  ) : (
    <></>
  );

  const dismissModal = () => setIsOpen(false);
  const presentModal = () => setIsOpen(true);

  return { dismissModal, modal, presentModal };
}

export function useInviteUsersModal() {
  const [isOpen, setIsOpen] = useState(false);

  const modal = isOpen ? (
    <InviteUsersModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  ) : (
    <></>
  );

  const dismissModal = () => setIsOpen(false);
  const presentModal = () => setIsOpen(true);

  return { dismissModal, modal, presentModal };
}

export function useLegacyRedirectModal() {
  const [isOpen, setIsOpen] = useState(false);

  const modal = isOpen ? (
    <LegacyRedirectModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  ) : (
    <></>
  );

  const dismissModal = () => setIsOpen(false);
  const presentModal = () => setIsOpen(true);

  return { dismissModal, modal, presentModal };
}
