import { IonBadge, IonContent, IonList, IonPopover } from '@ionic/react';
import { Auth } from 'aws-amplify';
import { addOutline, checkmarkOutline, chevronDownOutline, logOutSharp } from 'ionicons/icons';
import { FunctionComponent, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSwitchAccount } from '../../hooks/accounts';
import { useGetAccounts } from '../../queries/accounts.query';
import { useGetCurrentUser, useGetInvitesForUser } from '../../queries/users.query';
import { routes } from '../../routes';
import { CustomMouseEvent } from '../../types/dom.types';
import { StyledElement } from '../../types/styles.types';
import { getElementCoordinates } from '../../utils/dom';
import { capitalize, initials, usernameFromEmail } from '../../utils/text';
import { Item, ItemContainer, ItemIcon, ListItem, PresentationItem } from '../ui/items';
import { useCreateAccountModal } from '../../hooks/modal';
import { AccountSelectDto, InviteSelectDto, UserSelectDto } from '../../types/api.types';

export type AccountListProps = Pick<
  UserAccountPopoverProps,
  'accounts' | 'currentAccount' | 'maxAccountItems' | 'onChangeAccount' | 'onCreateAccount' | 'toggleDrawer'
> & {
  onClosePopover: () => void;
};

const MenuList = styled(IonList)`
  background-color: var(--popover-bg-color);
  ${Item} {
    --background: var(--popover-bg-color);
    padding: 0 10px;
  }
`;

const AccountListWrapper = styled.div`
  ${ItemContainer} {
    justify-content: space-between;
  }
`;

export const AccountList: FunctionComponent<AccountListProps> = (props) => {
  const { accounts, currentAccount, maxAccountItems, onChangeAccount, onClosePopover, onCreateAccount, toggleDrawer } =
    props;

  const onClickItem = (action: () => void) => () => {
    action();
    onClosePopover();
  };

  const AccountItems = () => (
    <AccountListWrapper>
      {accounts.map((account) => {
        const isCurrentAccount = currentAccount?.id === account.id;
        return (
          <ListItem
            key={account.id}
            label={account.name}
            itemProps={{
              button: !isCurrentAccount,
              disabled: isCurrentAccount,
              lines: 'none',
              onClick: onClickItem(() => onChangeAccount(account)),
              routerDirection: 'none',
            }}
            toggleDrawer={toggleDrawer}
            tail={
              isCurrentAccount && (
                <ItemIcon
                  ios={checkmarkOutline}
                  md={checkmarkOutline}
                  size="small"
                />
              )
            }
          />
        );
      })}
    </AccountListWrapper>
  );

  const AccountMenu = () => (
    <>
      {accounts.length > 1 && <AccountItems />}
      <ListItem
        graphic={
          <ItemIcon
            ios={addOutline}
            md={addOutline}
          />
        }
        label="Create new Account"
        itemProps={{
          button: true,
          lines: 'none',
          onClick: onClickItem(onCreateAccount),
          routerDirection: 'none',
        }}
        toggleDrawer={false}
      />
    </>
  );

  if (accounts.length <= maxAccountItems) {
    return <AccountMenu />;
  }

  const accountMenuId = 'accounts-menu';

  return (
    <>
      <ListItem
        label="Switch Account"
        itemProps={{
          button: true,
          detail: true,
          id: accountMenuId,
          lines: 'none',
        }}
        toggleDrawer={toggleDrawer}
      />
      <IonPopover
        trigger={accountMenuId}
        triggerAction="hover"
        dismissOnSelect
        side="end"
      >
        <IonContent>
          <MenuList>
            <AccountItems />
            <ListItem
              graphic={
                <ItemIcon
                  ios={addOutline}
                  md={addOutline}
                />
              }
              label="Create new Account"
              itemProps={{
                button: true,
                lines: 'none',
                onClick: onClickItem(onCreateAccount),
                routerDirection: 'none',
              }}
              toggleDrawer={false}
            />
          </MenuList>
        </IonContent>
      </IonPopover>
    </>
  );
};

export type UserAccountPopoverProps = {
  accounts: AccountSelectDto[];
  currentAccount?: AccountSelectDto;
  currentUser?: UserSelectDto;
  maxAccountItems: number;
  onChangeAccount: (account: AccountSelectDto) => void;
  onCreateAccount: () => void;
  pendingInvites: InviteSelectDto[];
} & StyledElement &
  Pick<UserAccountMenuProps, 'toggleDrawer'>;

export const UserAccountPopover: FunctionComponent<UserAccountPopoverProps> = (props) => {
  const {
    accounts,
    className,
    currentAccount,
    currentUser,
    maxAccountItems,
    onChangeAccount,
    onCreateAccount,
    pendingInvites,
    toggleDrawer,
  } = props;
  const popover = useRef<HTMLIonPopoverElement>(null);
  const popoverTriggerRef = useRef<HTMLDivElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: CustomMouseEvent) => {
    if (popover.current && popoverTriggerRef.current) {
      const { bottom, left } = getElementCoordinates(popoverTriggerRef.current);
      e.clientX = left;
      e.clientY = bottom;
      popover.current.event = e;
      setPopoverOpen(true);
    }
  };
  const closePopover = () => setPopoverOpen(false);
  const username = capitalize(usernameFromEmail(currentUser?.email ?? ''));

  return (
    <>
      <div ref={popoverTriggerRef}>
        <PresentationItem
          className={className}
          avatarText={initials(currentAccount?.name || currentUser?.email || '')}
          onClick={openPopover}
          subtitle={currentUser?.email}
          title={currentAccount?.name}
          itemProps={{
            button: true,
          }}
          tail={
            <ItemIcon
              ios={chevronDownOutline}
              md={chevronDownOutline}
              size="small"
            />
          }
          toggleDrawer={toggleDrawer}
        />
      </div>
      <IonPopover
        ref={popover}
        isOpen={popoverOpen}
        onDidDismiss={closePopover}
        reference="event"
        showBackdrop={false}
      >
        <IonContent>
          <MenuList>
            <PresentationItem
              avatarText={initials(currentAccount?.name || currentUser?.email || '')}
              title={currentAccount?.name}
              toggleDrawer={toggleDrawer}
            />
            <AccountList
              accounts={accounts}
              currentAccount={currentAccount}
              maxAccountItems={maxAccountItems}
              onClosePopover={closePopover}
              onChangeAccount={onChangeAccount}
              onCreateAccount={onCreateAccount}
              toggleDrawer={toggleDrawer}
            />
            <PresentationItem
              avatarText={initials(username)}
              title={username}
              subtitle={currentUser?.email}
              toggleDrawer={toggleDrawer}
            />
            {pendingInvites.length > 0 && (
              <ListItem
                graphic={
                  <ItemIcon
                    ios={routes.pendingInvites.icon}
                    md={routes.pendingInvites.icon}
                  />
                }
                label={routes.pendingInvites.name}
                itemProps={{
                  button: true,
                  lines: 'none',
                  onClick: closePopover,
                  routerDirection: 'none',
                  routerLink: routes.pendingInvites.href,
                }}
                tail={<IonBadge>{pendingInvites.length}</IonBadge>}
                toggleDrawer={toggleDrawer}
              />
            )}
            <ListItem
              graphic={
                <ItemIcon
                  ios={routes.settings.icon}
                  md={routes.settings.icon}
                />
              }
              label={routes.settings.name}
              itemProps={{
                button: true,
                lines: 'none',
                onClick: closePopover,
                routerDirection: 'none',
                routerLink: routes.settings.href,
              }}
              toggleDrawer={toggleDrawer}
            />
            <ListItem
              graphic={
                <ItemIcon
                  ios={logOutSharp}
                  md={logOutSharp}
                />
              }
              label="Sign out"
              itemProps={{
                button: true,
                lines: 'none',
                onClick: () => Auth.signOut(),
                routerDirection: 'none',
                routerLink: routes.pendingInvites.href,
              }}
              toggleDrawer={toggleDrawer}
            />
          </MenuList>
        </IonContent>
      </IonPopover>
    </>
  );
};

export type UserAccountMenuProps = StyledElement & {
  toggleDrawer?: boolean;
};

export const UserAccountMenu: FunctionComponent<UserAccountMenuProps> = (props) => {
  const { className, toggleDrawer } = props;
  const { data: accounts = [] } = useGetAccounts();
  const { data: currentUser } = useGetCurrentUser();
  const [currentAccount, switchAccount] = useSwitchAccount();
  const { modal, presentModal } = useCreateAccountModal();
  const { data: pendingInvites = [] } = useGetInvitesForUser();

  function onChangeAccount(account: AccountSelectDto) {
    if (account && account.id !== currentAccount?.id) {
      switchAccount(account);
    }
  }

  return (
    <>
      {modal}
      <UserAccountPopover
        accounts={accounts}
        className={className}
        currentAccount={currentAccount}
        currentUser={currentUser}
        maxAccountItems={3}
        onChangeAccount={onChangeAccount}
        onCreateAccount={presentModal}
        pendingInvites={pendingInvites}
        toggleDrawer={toggleDrawer}
      />
    </>
  );
};
