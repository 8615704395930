import styled from 'styled-components';

import { Chip } from '../ui/Chip';
import { ArrowTrSquareIcon } from '../icons/ArrowTrSquareIcon';
import { ShieldMinusIcon } from '../icons/ShieldMinusIcon';
import { Paginator } from '../ui/paginator';
import {
  LocationChangelogItemDtoGoogleStatusEnum,
  LocationChangelogItemDtoTypeEnum,
  LocationChangelogSelectDto,
} from '../../types/api.types';

type ChangelogListProps = {
  changelogData: LocationChangelogSelectDto;
  handleItemsToShow: (items: number) => void;
  itemsToShow: number;
  page: number;
  setPage: (page: number) => void;
};

const ChangelogListContainer = styled.div`
  background: var(--global-components-bg-color);
  width: 100%;
`;

const ChangeLogItem = styled.div`
  border-bottom: 1px solid var(--table-separator-content-color);
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

const ChangeLogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ChangeLogTimestamp = styled.div`
  color: var(--global-text-alternative-color);
  font-size: 12px;
  font-weight: 300;
`;

const ChangeLogTitle = styled.div`
  color: var(--global-title-color);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4px;
`;

const ChangeLogName = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  gap: 4px;
`;

const WrapIcon = styled.a`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  svg {
    path {
      stroke: var(--global-text-alternative-color);
    }
  }
`;

const ChangeLogTypeContent = styled.div`
  align-items: end;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
`;

const IconChipWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const OriginalName = styled.span`
  color: var(--global-text-alternative-color);
  font-size: 12px;
  font-weight: 300;
`;

export const ChangelogList: React.FC<ChangelogListProps> = ({
  changelogData,
  handleItemsToShow,
  itemsToShow,
  page,
  setPage,
}) => {
  const formatDate = (input: string) => {
    const date = new Date(input);

    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ?? 12;
    const formattedTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' ' + ampm;

    return `${formattedDate} @ ${formattedTime}`;
  };

  const formatType = (type: string) => {
    switch (type) {
      case LocationChangelogItemDtoTypeEnum.KEYWORDS_CHANGED:
        return 'Competitor keyword removed';
      case LocationChangelogItemDtoTypeEnum.FOUND:
        return 'New competitor found';
      case LocationChangelogItemDtoTypeEnum.REMOVED:
        return 'Competitor keyword resolved';
      case LocationChangelogItemDtoTypeEnum.REVIEWED:
        return 'Competitor report submitted';
      default:
        return '';
    }
  };

  const getChipStatusColor = (type: string) => {
    switch (type) {
      case LocationChangelogItemDtoTypeEnum.KEYWORDS_CHANGED:
        return 'success';
      case LocationChangelogItemDtoTypeEnum.FOUND:
        return 'disabled';
      case LocationChangelogItemDtoTypeEnum.REMOVED:
        return 'success';
      case LocationChangelogItemDtoTypeEnum.REVIEWED:
        return 'warning';
      default:
        return 'success';
    }
  };

  const getChipText = (type: string) => {
    switch (type) {
      case LocationChangelogItemDtoTypeEnum.KEYWORDS_CHANGED:
        return 'Keywords Removed';
      case LocationChangelogItemDtoTypeEnum.FOUND:
        return 'Pending Review';
      case LocationChangelogItemDtoTypeEnum.REMOVED:
        return 'Hard Suspended';
      case LocationChangelogItemDtoTypeEnum.REVIEWED:
        return 'Pending Change';
      case LocationChangelogItemDtoGoogleStatusEnum.PERMANENTLY_CLOSED:
        return 'Permanently Closed';
      case LocationChangelogItemDtoGoogleStatusEnum.HARD_SUSPENDED:
        return 'Hard Suspended';
      default:
        return 'success';
    }
  };

  return (
    <>
      <ChangelogListContainer>
        {changelogData && (
          <>
            {changelogData.changelogItems.map(
              ({ googleMapsUrl, googleStatus, name, originalName, timestamp, type }, index) => (
                <ChangeLogItem key={index}>
                  <ChangeLogContent>
                    <ChangeLogTimestamp>{formatDate(timestamp)}</ChangeLogTimestamp>
                    <ChangeLogTitle>{formatType(type)}</ChangeLogTitle>
                    <ChangeLogName>
                      <WrapIcon
                        href={googleMapsUrl}
                        target="_blank"
                      >
                        {ArrowTrSquareIcon}
                      </WrapIcon>
                      {name}
                    </ChangeLogName>
                  </ChangeLogContent>
                  <ChangeLogTypeContent>
                    <Chip color={getChipStatusColor(type)}>
                      {type === LocationChangelogItemDtoTypeEnum.REMOVED ||
                        (type === LocationChangelogItemDtoTypeEnum.KEYWORDS_CHANGED && (
                          <IconChipWrapper>{ShieldMinusIcon}</IconChipWrapper>
                        ))}
                      {getChipText(
                        type === LocationChangelogItemDtoTypeEnum.REMOVED ? (googleStatus ? googleStatus : type) : type,
                      )}
                    </Chip>
                    {originalName && type === LocationChangelogItemDtoTypeEnum.KEYWORDS_CHANGED && (
                      <OriginalName>{originalName}</OriginalName>
                    )}
                  </ChangeLogTypeContent>
                </ChangeLogItem>
              ),
            )}
          </>
        )}
      </ChangelogListContainer>
      <Paginator
        handleItemsToShow={handleItemsToShow}
        handleNextPage={() => setPage(page + 1)}
        handlePrevPage={() => setPage(page - 1)}
        itemsQuantity={changelogData?.count ?? 0}
        itemsToShow={itemsToShow}
        lastPageNumber={Math.ceil((changelogData?.count ?? 0) / itemsToShow)}
        page={page}
        setPage={setPage}
      />
    </>
  );
};
