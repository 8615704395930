import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { DrawerState } from '../types/app';
import { drawerState } from '../states/app';
import { config } from '../config';

export function useDrawer() {
  const isMobile = useIsMobile();
  const [state, setDrawerState] = useRecoilState(drawerState);

  useEffect(() => {
    setDrawerState((prevValue) =>
      isMobile ? DrawerState.CLOSE : prevValue === DrawerState.CLOSE ? DrawerState.OPEN : prevValue,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const closeDrawer = () => setDrawerState(DrawerState.CLOSE);
  const collapseDrawer = () => setDrawerState(DrawerState.COLLAPSED);
  const openDrawer = () => setDrawerState(DrawerState.OPEN);

  return {
    closeDrawer,
    collapseDrawer,
    isClose: state === DrawerState.CLOSE,
    isCollapsed: state === DrawerState.COLLAPSED,
    isOpen: state === DrawerState.OPEN,
    openDrawer,
    state,
  };
}

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= config.app.mobileBreakpoint);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= config.app.mobileBreakpoint);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
}
