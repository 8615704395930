import { useEffect } from 'react';
import { Dashboard } from '../components/dashboard/Dashboard';
import { PageLayout } from '../components/ui/layouts/page';
import { useGetAccounts } from '../queries/accounts.query';
import { useGetInvitesForUser } from '../queries/users.query';
import { useCreateAccountModal } from '../hooks/modal';

export const DashboardPage: React.FC = () => {
  const { data: accounts = [] } = useGetAccounts();
  const { data: pendingInvites } = useGetInvitesForUser();

  const { modal, presentModal } = useCreateAccountModal();

  useEffect(() => {
    if (accounts.length === 0 && pendingInvites && pendingInvites.length === 0) {
      presentModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  return (
    <PageLayout
      breadcrumbRoutes={[{ name: 'Dashboard' }]}
      title="Dashboard"
    >
      {modal}
      <Dashboard />
    </PageLayout>
  );
};
