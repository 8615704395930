import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { config } from '../../config';
import { StyledElement } from '../../types/styles.types';

export const SmallLogo = styled.div`
  background-image: url('/assets/logo.collapsed.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 62px;
  width: 45px;
`;

export const Container = styled.div<{ isCollapsed?: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  @media (min-width: ${config.app.mobileBreakpoint}px) {
    justify-content: flex-start;
  }
`;

export const Logo = styled.div`
  background-image: url('/assets/logo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 62px;
  width: 192px;
`;

export type BrandPros = {
  isCollapsed?: boolean;
} & StyledElement;

export const Brand: FunctionComponent<BrandPros> = (props) => {
  const { className, isCollapsed } = props;
  return (
    <Container
      className={className}
      isCollapsed={isCollapsed}
    >
      {isCollapsed ? <SmallLogo /> : <Logo />}
    </Container>
  );
};
