import styled from 'styled-components';

export type ChipColor = 'primary' | 'danger' | 'warning' | 'success' | 'light' | 'disabled';

const colorToVar = {
  danger: {
    bg: '--chip-danger-bg-color',
    border: '--chip-danger-border-color',
    font: '--chip-danger-font-color',
  },
  disabled: {
    bg: '--chip-disabled-bg-color',
    border: '--chip-disabled-border-color',
    font: '--chip-disabled-font-color',
  },
  light: {
    bg: '--chip-light-bg-color',
    border: '--chip-light-border-color',
    font: '--chip-font-color',
  },
  primary: {
    bg: '--button-bg-color',
    border: '--chip-border-color',
    font: '--chip-font-color',
  },
  success: {
    bg: '--chip-success-bg-color',
    border: '--chip-success-border-color',
    font: '--chip-success-font-color',
  },
  warning: {
    bg: '--chip-warning-bg-color',
    border: '--chip-warning-border-color',
    font: '--chip-warning-font-color',
  },
};

export const Chip = styled.div<{ color?: ChipColor }>`
  align-items: center;
  background-color: var(${({ color }) => colorToVar[color ?? 'primary'].bg});
  border: 1px solid var(${({ color }) => colorToVar[color ?? 'primary'].border});
  border-radius: 20px;
  color: var(${({ color }) => colorToVar[color ?? 'primary'].font});
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  display: inline-flex;
  font-size: 12px;
  gap: 4px;
  margin: 1px;
  padding: 6px 12px;
  text-align: center;
  text-transform: capitalize;
  width: auto;
`;
