import { API } from 'aws-amplify';
import { config } from '../config';

type ApiError = {
  message: string;
};
export class ApiErrors extends Error {
  errors: ApiError[];
  originalError?: Error;

  constructor(params: { errors: ApiError[]; originalError?: Error }) {
    super(params.errors[0].message);
    this.errors = params.errors;
    this.originalError = params.originalError;
  }
}

const fallbackErrorMessage = 'Something went wrong, please try again!';

async function processApiRequest<T>(request: Promise<any>): Promise<T> {
  try {
    return await request;
  } catch (error) {
    const apiErrors: ApiError[] = error.response?.data?.errors ?? [{ message: fallbackErrorMessage }];
    throw new ApiErrors({ errors: apiErrors, originalError: error });
  }
}

export async function httpGet<T>(params: {
  accountId?: number;
  ignoreAccountHeader?: boolean;
  path: string;
  queryStringParameters?: Record<string, any>;
}): Promise<T> {
  const { accountId, ignoreAccountHeader, path, queryStringParameters } = params;

  const request = API.get(config.api.name, path, {
    ...(accountId && {
      headers: { [config.app.accountIdHeader]: accountId },
    }),
    ...(ignoreAccountHeader && {
      headers: { [config.app.accountIdHeader]: undefined },
    }),
    queryStringParameters,
  });
  return processApiRequest<T>(request);
}

export async function httpDelete<T>(params: {
  accountId?: number;
  ignoreAccountHeader?: boolean;
  path: string;
}): Promise<T> {
  const { accountId, ignoreAccountHeader, path } = params;

  const request = API.del(config.api.name, path, {
    ...(accountId && {
      headers: { [config.app.accountIdHeader]: accountId },
    }),
    ...(ignoreAccountHeader && {
      headers: { [config.app.accountIdHeader]: undefined },
    }),
  });
  return processApiRequest<T>(request);
}

export async function httpPatch<T>(params: {
  accountId?: number;
  body?: Record<string, any>;
  ignoreAccountHeader?: boolean;
  path: string;
}): Promise<T> {
  const { accountId, body, ignoreAccountHeader, path } = params;

  const request = API.patch(config.api.name, path, {
    body,
    ...(accountId && {
      headers: { [config.app.accountIdHeader]: accountId },
    }),
    ...(ignoreAccountHeader && {
      headers: { [config.app.accountIdHeader]: undefined },
    }),
  });
  return processApiRequest<T>(request);
}

export async function httpPost<T>(params: {
  accountId?: number;
  body?: Record<string, any>;
  ignoreAccountHeader?: boolean;
  path: string;
}): Promise<T> {
  const { accountId, body, ignoreAccountHeader, path } = params;

  const request = API.post(config.api.name, path, {
    body,
    ...(accountId && {
      headers: { [config.app.accountIdHeader]: accountId },
    }),
    ...(ignoreAccountHeader && {
      headers: { [config.app.accountIdHeader]: undefined },
    }),
  });
  return processApiRequest<T>(request);
}
