export const PageFlipIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0125 8.25H11.325H13.2"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9727 5.25H11.3254H13.2004"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.44995 12.375V10.6875M13.2 16.875V16.875C14.8568 16.875 16.2 15.5319 16.2 13.875V2.85C16.2 2.51863 15.9313 2.25 15.6 2.25H11.325H10.575"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.49959 12.375H6.44959H9.59959C9.93096 12.375 10.2028 12.6424 10.2353 12.9722C10.3561 14.1961 10.8849 16.875 13.1996 16.875H6.44959H5.39959C3.90842 16.875 2.69959 15.6662 2.69959 14.175C2.69959 13.1809 3.50547 12.375 4.49959 12.375Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.51245 3.375L5.51245 5.0625L7.19995 5.0625"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.51282 8.8125C7.58388 8.8125 9.26282 7.13357 9.26282 5.0625C9.26282 2.99143 7.58388 1.3125 5.51282 1.3125C3.44175 1.3125 1.76282 2.99143 1.76282 5.0625C1.76282 7.13357 3.44175 8.8125 5.51282 8.8125Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
