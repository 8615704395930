import { atom } from 'recoil';
import { DrawerState } from '../types/app';

export const drawerState = atom<DrawerState>({
  default: DrawerState.OPEN,
  key: 'drawerState',
});

export const showLoadingSpinnerState = atom<boolean>({
  default: false,
  key: 'showLoadingSpinnerState',
});

export const showSplashState = atom<boolean>({
  default: false,
  key: 'showSplashState',
});

export const toastMessageState = atom<string | undefined>({
  default: undefined,
  key: 'toastMessageState',
});
