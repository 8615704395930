import { IonIcon, IonItem, IonLabel, IonMenuToggle } from '@ionic/react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IonItemProps } from '../../../types/ionic.types';
import { StyledElement } from '../../../types/styles.types';
import { Avatar } from '../Avatar';

export const Divider = styled.div`
  background-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
  height: 1px;
  margin: 5px 0;
  width: 100%;
`;

export const Item = styled(IonItem)`
  --color: var(--side-menu-popover-font-color);
  --color-hover: var(--side-menu-popover-font-color-hover);
  --inner-padding-end: 0;
  --padding-start: 0;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
  width: 100%;
`;

export const ItemIcon = styled(IonIcon)`
  font-size: 24px;
`;

export const ItemLabel = styled(IonLabel)`
  color: var(--nav-item-font-color) !important;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 18px;
  max-width: 180px;
  padding-left: 12px;
  text-overflow: ellipsis;

  h3 {
    font-size: 18px;
    text-overflow: ellipsis;
  }

  p {
    font-size: 13px;
    text-overflow: ellipsis;
  }
`;

export const ItemContainer = styled.div<{ active?: boolean }>`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: start;

  &::before {
    background-color: ${({ active }) => (active ? 'var(--ion-color-primary)' : 'transparent')};
    border-radius: 0px 3px 3px 0px;
    bottom: 10px;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 10px;
    width: 5px;
  }
`;

export type ListItemProps = {
  active?: boolean;
  className?: string;
  graphic?: React.ReactNode;
  itemProps?: IonItemProps;
  label?: React.ReactNode;
  tail?: React.ReactNode;
  toggleDrawer?: boolean;
};

export const GraphicContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 40px;
`;

export const TailContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-right: 5px;
  width: auto;
`;

export type PresentationCardProps = {
  avatarText?: string;
  avatarUrl?: string;
  id?: string;
  itemProps?: IonItemProps;
  onClick?: (event: any) => void;
  subtitle?: React.ReactNode;
  title?: React.ReactNode;
} & StyledElement &
  Pick<ListItemProps, 'tail' | 'toggleDrawer'>;

export const PresentationItem: FunctionComponent<PresentationCardProps> = (props) => {
  const { avatarText, avatarUrl, className, id, itemProps, onClick, subtitle, tail, title, toggleDrawer } = props;

  return (
    <ListItem
      className={className}
      itemProps={{ id, lines: 'none', onClick, ...itemProps }}
      graphic={
        (avatarUrl || avatarText) && (
          <Avatar
            avatarUrl={avatarUrl}
            avatarText={avatarText}
          />
        )
      }
      label={
        <>
          <h3 style={{ color: 'var(--nav-item-font-color)' }}>{title}</h3>
          <p>{subtitle}</p>
        </>
      }
      tail={tail}
      toggleDrawer={toggleDrawer}
    />
  );
};

export const ListItem: FunctionComponent<ListItemProps> = (props) => {
  const { active, className, graphic, label, tail, toggleDrawer = true } = props;
  // eslint-disable-next-line prefer-destructuring
  const itemProps = props.itemProps as any;

  const ItemElement = () => (
    <Item
      className={className}
      {...itemProps}
    >
      <ItemContainer active={active}>
        {graphic && <GraphicContainer>{graphic}</GraphicContainer>}
        {label && <ItemLabel>{label}</ItemLabel>}
        {tail && <TailContainer>{tail}</TailContainer>}
      </ItemContainer>
    </Item>
  );

  return toggleDrawer ? (
    <IonMenuToggle autoHide={false}>
      <ItemElement />
    </IonMenuToggle>
  ) : (
    <ItemElement />
  );
};
