import { useEffect } from 'react';
import { config } from '../config';

export const useHubspotIntegration = () => {
  useEffect(() => {
    if (config.hubspot) {
      const script = document.createElement('script');
      script.async = true;
      script.defer = true;
      script.id = 'hs-script-loader';
      script.src = `//js.hs-scripts.com/${config.hubspot.hubId}.js`;

      document.body.prepend(script);
    }
  }, []);
};
