import { FunctionComponent, PropsWithChildren } from 'react';
import styled from 'styled-components';

type BadgeProps = { type: 'normal' | 'warning' };

const BaseContainer = styled.div`
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
`;

const NormalContainer = styled(BaseContainer)`
  background: var(--badge-bg-color-normal);
  color: var(--global-font-color-data);
`;

const WarningContainer = styled(BaseContainer)`
  background: var(--badge-bg-color-warning);
  border: 1px solid var(--badge-border-color-warning);
  color: var(--badge-font-color-warning);
`;

export const Badge: FunctionComponent<PropsWithChildren<BadgeProps>> = ({ children, type }) => {
  const Container = type === 'normal' ? NormalContainer : type === 'warning' ? WarningContainer : BaseContainer;

  return <Container>{children}</Container>;
};
