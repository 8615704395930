import * as yup from 'yup';
import { validate } from '../utils/validation';

export function useValidate() {
  function validateSchema<T extends Record<string, any>>(
    schema: yup.ObjectSchema<T>,
    payload: T,
  ): Map<keyof T, string> | undefined {
    const errors = validate(schema, payload);
    if (errors) {
      const translatedErrors = Array.from(errors.entries()).map(([key, error]) => [key, error.message] as const);

      return new Map(translatedErrors);
    }
  }

  return validateSchema;
}
