import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { DashboardPage } from '../pages/dashboard';
import { InvitesPage } from '../pages/invites';
import { LocationPage } from '../pages/location';
import { MyLocations } from '../pages/locations';
import { ManageSubscriptionPage } from '../pages/manage-subscription';
import { ManageUsersPage } from '../pages/manage-users';
import { SettingsPage } from '../pages/settings';
import { ChangelogPage } from '../pages/changelog';
import { routes } from '../routes';

export const Router = () => {
  return (
    <IonRouterOutlet id="main">
      <Route
        path={routes.accountSettings.href}
        exact
      >
        <ManageSubscriptionPage />
      </Route>
      <Route
        path={routes.dashboard.href}
        exact
      >
        <DashboardPage />
      </Route>
      <Route
        path={routes.manageUsers.href}
        exact
      >
        <ManageUsersPage />
      </Route>
      <Route
        path={routes.pendingInvites.href}
        exact
      >
        <InvitesPage />
      </Route>
      <Route
        path={routes.location.href}
        exact
      >
        <LocationPage />
      </Route>
      <Route
        path={routes.changelog.href}
        exact
      >
        <ChangelogPage />
      </Route>
      <Route
        path={routes.locations.href}
        exact
      >
        <MyLocations />
      </Route>
      <Route
        path={routes.settings.href}
        exact
      >
        <SettingsPage />
      </Route>
      <Route
        path={routes.root.href}
        exact
      >
        <Redirect to={routes.dashboard.href} />
      </Route>
    </IonRouterOutlet>
  );
};
