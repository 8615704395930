import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import { FunctionComponent, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { StyledElement } from '../../../types/styles.types';

export type CardProps = {
  subtitle?: React.ReactNode;
  title?: React.ReactNode;
} & StyledElement;

export const CardContainer = styled(IonCard)`
  --ion-item-background: transparent;
  background-color: var(--card-content-bg-color);
  border-bottom: 1px solid var(--table-separator-content-color);
  border-radius: 8px;
  box-shadow: none;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const CardHeader = styled(IonCardHeader)`
  align-items: center;
  background-color: var(--card-header-bg-color);
  border-bottom: 1px solid var(--table-separator-content-color);
  display: flex;
  height: 40px;
  padding: 0 10px;
`;

export const CardTitle = styled(IonCardTitle)`
  color: var(--card-header-font-color);
  font-size: 18px;
  font-weight: 600;
`;

export const CardContent = styled(IonCardTitle)`
  color: var(--card-content-font-color);
`;

export const CardContentContainer = styled(IonCardContent)`
  color: var(--card-content-font-color);
  line-height: normal;
  padding: 24px !important;
`;

export const CardSecondaryContent = styled(IonCardTitle)`
  color: var(--card-content-font-secondary-color);
  font-size: 16px;
  font-weight: 600;
`;

export const Card: FunctionComponent<PropsWithChildren<CardProps>> = (props) => {
  const { children, className, subtitle, title } = props;
  return (
    <CardContainer className={className}>
      {title && (
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          {subtitle && <IonCardSubtitle>{subtitle}</IonCardSubtitle>}
        </CardHeader>
      )}
      <CardContentContainer>{children}</CardContentContainer>
    </CardContainer>
  );
};
