import styled from 'styled-components';

const IconContainer = styled.div<{ disabled?: boolean }>`
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  justify-content: center;
  user-select: none;

  line {
    stroke: var(--subscriptions-title-font-color);
  }

  rect {
    stroke: var(--subscriptions-title-font-color);
  }
`;

export const PlusIcon = (params: { disabled?: boolean; onClick?: () => void }) => {
  const { disabled, onClick } = params;

  return (
    <IconContainer
      disabled={disabled}
      onClick={() => onClick && onClick()}
    >
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="10"
          height="10"
          stroke="#133F68"
        />
        <line
          x1="2.2002"
          y1="5.36664"
          x2="8.8002"
          y2="5.36664"
          stroke="#133F68"
        />
        <line
          x1="5.3667"
          y1="8.79999"
          x2="5.3667"
          y2="2.19999"
          stroke="#133F68"
        />
      </svg>
    </IconContainer>
  );
};
