import * as yup from 'yup';
import { accountUserRoles } from '../../types/user.types';

export const invitationSchema = yup.object().shape({
  email: yup.string().email().required(),
  role: yup.mixed().oneOf(accountUserRoles).required(),
});

export const invitationPartialSchema = yup.object().shape({
  email: yup.string().email(),
  role: yup.mixed().oneOf(accountUserRoles),
});

export const invitationsSchema = yup.array().of(invitationSchema);
