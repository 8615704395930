import { useRecoilValue } from 'recoil';
import { sideMenuRoutes } from '../routes';
import { currentAccountState } from '../states/account';
import { AccountUserUpdateDtoRoleEnum } from '../types/api.types';

export function useAvailableSideMenuRoutes() {
  const currentAccount = useRecoilValue(currentAccountState);

  return sideMenuRoutes.filter((route) => {
    if (!currentAccount) return false;
    if (!route.requiredRoles) return true;

    return currentAccount.roles.some((role) =>
      route.requiredRoles!.includes(role as unknown as AccountUserUpdateDtoRoleEnum),
    );
  });
}
