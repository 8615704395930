import styled from 'styled-components';

import { useGetCompetitorsForLicense } from '../../queries/licenses.query';
import { LeftArrowIcon } from '../../components/icons/LeftArrowIcon';
// import { Dropdown } from '../../components/ui/dropdown';
import { Button } from '../ui/button/Button';
import CompetitorSearchBar from './competitor-searcher';

type FiltersValues = {
  competitorIds: number[];
  endDate: string;
  startDate: string;
};

type FiltersProps = {
  filters: FiltersValues;
  isLoading?: boolean;
  licenseId: number;
  onApplyfilters: () => void;
  setFilters: (filters: object) => void;
};

const FiltersContainer = styled.div`
  background-color: var(--global-components-bg-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  width: 100%;
`;

const FiltersHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FilterName = styled.p`
  color: var(--global-text-alternative-color);
  font-size: 12px;
  font-weight: 300;
  margin: 0;
`;

const InputFilter = styled.input`
  background-color: var(--filter-input-bg-color);
  border: 1px solid var(--filter-input-border-color);
  border-radius: var(--filter-input-border-radius);
  color: var(--global-font-color-data);
  font-size: 14px;
  padding: 12px;
  width: 100%;
`;

const DateInputContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ArrowIconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  transform: rotate(180deg);
  svg {
    height: 20px;
    width: 20px;
    path {
      stroke: var(--global-font-color-data);
    }
  }
`;

// const DropdownFilter = styled(Dropdown)`
//   background-color: var(--filter-input-bg-color);
//   border: 1px solid var(--filter-input-border-color);
//   border-radius: var(--filter-input-border-radius);
//   color: var(--global-font-color-data);
// `;

// const EventsValueOptions = [
//   { children: 'All Events', value: 'all' },
//   { children: 'Found', value: 'found' },
//   { children: 'Removed', value: 'removed' },
//   { children: 'Review', value: 'review' },
// ];

export const Filters: React.FC<FiltersProps> = ({ filters, isLoading, licenseId, onApplyfilters, setFilters }) => {
  const { data: competitors = [] } = useGetCompetitorsForLicense(licenseId);

  const competitorsCatalog = competitors.map((item) => ({ id: item.id, name: item.name }));
  return (
    <FiltersContainer>
      <FiltersHeader>
        Filters
        <Button
          disabled={isLoading}
          onClick={onApplyfilters}
        >
          Apply
        </Button>
      </FiltersHeader>
      <FilterWrapper>
        <FilterName>Filter by date</FilterName>
        <DateInputContainer>
          <InputFilter
            placeholder="From"
            type="date"
            name="startDate"
            onChange={(e) => setFilters((prev) => ({ ...prev, startDate: e.target.value }))}
            value={filters.startDate}
          ></InputFilter>
          <ArrowIconWrapper>{LeftArrowIcon}</ArrowIconWrapper>
          <InputFilter
            type="date"
            placeholder="To"
            name="endDate"
            value={filters.endDate}
            onChange={(e) => setFilters((prev) => ({ ...prev, endDate: e.target.value }))}
          ></InputFilter>
        </DateInputContainer>
      </FilterWrapper>
      <FilterWrapper>
        <FilterName>Filter by competitor</FilterName>
        <CompetitorSearchBar
          catalog={competitorsCatalog}
          setFilters={setFilters}
          filters={filters}
        />
      </FilterWrapper>
      {/* <FilterWrapper>
        <FilterName>Event type</FilterName>
        <DropdownFilter
          placeholder="All Events"
          items={EventsValueOptions}
        ></DropdownFilter>
      </FilterWrapper> */}
      {/* <FilterWrapper>
        <FilterName>Filter by keyword</FilterName>
        <InputFilter
          placeholder="Search keywords..."
          onChange={() => {}}
        ></InputFilter>
      </FilterWrapper> */}
    </FiltersContainer>
  );
};
