import { FunctionComponent, useEffect } from 'react';
import { useLegacyRedirectModal } from '../../hooks/modal';
import { legacyRedirectPart } from '../../routes';

export const LegacyRedirectModal: FunctionComponent = () => {
  const { modal, presentModal } = useLegacyRedirectModal();

  useEffect(() => {
    if (location?.search.includes(legacyRedirectPart)) {
      presentModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{modal}</>;
};
