import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { QueryClientProvider } from '@tanstack/react-query';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/* Amplify styling */
import '@aws-amplify/ui-react/styles.css';
import './theme/amplify.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { LegacyRedirectModal } from './components/sign-in';
import { Dashboard } from './components/ui/layouts/dashboard';
import { AppProvider } from './context/app';
import { useConfigureAmplify } from './hooks/amplify';
import { queryClient } from './queries/client';

/* Theme variables */
import './theme/custom.css';
import './theme/variables.css';
import { config } from './config';
import { useHubspotIntegration } from './hooks/hubspot';
import { useEvents } from './hooks/events';
import { useGetCurrentUser } from './queries/users.query';

setupIonicReact();

const AppContent: React.FC = () => {
  const location = useLocation();
  const posthog = usePostHog();
  const { trackPageview } = useEvents();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const isAuthenticated = authStatus === 'authenticated';
  const { data: user } = useGetCurrentUser(!isAuthenticated);

  useEffect(() => {
    trackPageview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (user) {
      posthog.identify(user.id.toString(), { email: user.email });
    }
  }, [isAuthenticated, posthog, user]);

  return isAuthenticated ? (
    <AppProvider>
      <Dashboard />
    </AppProvider>
  ) : (
    <>
      <Authenticator
        loginMechanisms={['email']}
        socialProviders={config.auth.userPoolId === 'us-east-1_SNxfJz5cq' ? undefined : ['google']}
      />
      <LegacyRedirectModal />
    </>
  );
};

const App: React.FC = () => {
  useConfigureAmplify();
  useHubspotIntegration();

  return (
    <QueryClientProvider client={queryClient}>
      <IonApp>
        <IonReactRouter>
          <AppContent />
        </IonReactRouter>
      </IonApp>
    </QueryClientProvider>
  );
};

export default App;
