import { rolesWeight } from '../types/account-users.types';
import { AccountUserUpdateDtoRoleEnum } from '../types/api.types';

export function compareRoles(params: { role1: AccountUserUpdateDtoRoleEnum; role2: AccountUserUpdateDtoRoleEnum }) {
  const { role1, role2 } = params;

  return rolesWeight[role1] - rolesWeight[role2];
}

export function isAllowed(params: { role1: AccountUserUpdateDtoRoleEnum; role2: AccountUserUpdateDtoRoleEnum }) {
  return compareRoles(params) >= 0;
}
