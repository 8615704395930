import { IonSpinner } from '@ionic/react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const SplashContainer = styled.div`
  align-items: center;
  background-color: var(--ion-color-dark-shade);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  z-index: 999999;
`;

const LogoIcon = styled.div`
  background-image: url('/assets/icon.png');
  background-position: center;
  background-size: cover;
  flex: none;
  height: 140px;
  margin-bottom: 10px;
  width: 140px;
`;

export const Splash: FunctionComponent = () => {
  return (
    <SplashContainer>
      <LogoIcon />
      <IonSpinner name="dots"></IonSpinner>
    </SplashContainer>
  );
};
