import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../hooks/screen';
import { formatUsdStripZeroPennies } from '../../utils/money';
import { Checkbox } from '../ui/Checkbox';
import { PackageSelectDto } from '../../types/api.types';

const Title = styled.div<{ margin?: string }>`
  color: var(--subscriptions-title-font-color);
  font-size: 18px;
  margin: ${({ margin }) => (margin ? margin : '')};
`;

const Text = styled.div`
  color: var(--subscriptions-data-font-color);
  font-size: 14px;
  line-height: normal;
`;

const CardContainer = styled.div`
  background-color: var(--subscriptions-bg-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const SubscriptionTypeContainer = styled(CardContainer)`
  margin: 0 0 28px;
  padding-bottom: 20px;
  row-gap: 15px;
  width: 300px;

  &:hover {
    border: 2px solid var(--subscriptions-border-color-hover);
    cursor: pointer;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    text-align: center;
  }
`;

const SubscriptionTypeHeader = styled.div`
  align-items: center;
  background-color: var(--table-header-bg-color);
  border-bottom: 1px solid var(--table-separator-content-color);
  display: flex;
  gap: 20px;
  height: 40px;
  padding: 10px 0;
`;

const SubscriptionTypeTitle = styled(Title)`
  color: var(--table-header-font-color);
  flex-grow: 1;
  font-weight: 600;
  padding: 0 47px;
`;

const SubscriptionTypePrice = styled(Title)`
  font-size: 14px;
  font-weight: 600;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 47px;
  row-gap: 16px;
`;

export type SubscriptionCardProps = {
  isChecked?: boolean;
  onClick: (pkg: PackageSelectDto) => void;
  pkg: PackageSelectDto;
};

export const SubscriptionCard: FunctionComponent<SubscriptionCardProps> = (props) => {
  const { isChecked, onClick, pkg } = props;
  return (
    <SubscriptionTypeContainer onClick={() => onClick(pkg)}>
      <SubscriptionTypeHeader>
        <Checkbox isChecked={isChecked} />
        <SubscriptionTypeTitle>{pkg.name}</SubscriptionTypeTitle>
      </SubscriptionTypeHeader>
      <TextContainer>
        <Text dangerouslySetInnerHTML={{ __html: pkg.description ?? '' }} />
        <SubscriptionTypePrice>
          {formatUsdStripZeroPennies(pkg.pricePerMonth)} per location per month
        </SubscriptionTypePrice>
      </TextContainer>
    </SubscriptionTypeContainer>
  );
};
