import * as yup from 'yup';

export function validate<T extends Record<string, any>>(
  schema: yup.ObjectSchema<T>,
  payload: T,
): Map<string, yup.ValidationError> | undefined {
  try {
    schema.validateSync(payload, { abortEarly: false });
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      const entries = error.inner
        .filter((error) => error.path)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((error) => [error.path!, error] as const);
      return new Map(entries);
    }
  }
}
