import { ManageSubscription } from '../components/manage-subscriptions/ManageSubscriptions';
import { PageLayout } from '../components/ui/layouts/page';
import { useGetPackages } from '../queries/packages.query';
import { useGetSubscriptionForAccount } from '../queries/subscriptions.query';

export const ManageSubscriptionPage: React.FC = () => {
  const { data: packages = [], isLoading: isPackagesLoading } = useGetPackages();
  const { data: subscription, isLoading: isSubscriptionLoading } = useGetSubscriptionForAccount();

  return (
    <PageLayout
      breadcrumbRoutes={[{ name: 'Account' }, { name: 'Manage Subscription' }]}
      title="Manage Subscription"
    >
      <ManageSubscription
        isSubscriptionLoading={isSubscriptionLoading}
        isPackagesLoading={isPackagesLoading}
        packages={packages}
        subscription={subscription}
      />
    </PageLayout>
  );
};
