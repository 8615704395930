import { useIonRouter } from '@ionic/react';
import { FunctionComponent, useCallback, useState } from 'react';
import { routes } from '../../routes';
import { headersType, Table, TableCell, TableColumn, TitleCell } from '../ui/table';
import { Button } from '../ui/button/Button';
import { LocationWithStatsSelectDto } from '../../types/api.types';

export interface EnhancedLocationWithStatsSelectDto extends LocationWithStatsSelectDto {
  statusLocation: string;
}

type LocationType = LocationWithStatsSelectDto & {
  statusLocation: string;
};

type LocationTableProps = {
  columns: headersType[];
  data: any;
  emptyContent?: React.ReactNode;
  handleSort?: (column: string) => void;
  sortColumn?: string | null;
  sortDirection?: string | null;
};

export const titleCell = (params?: Partial<TableColumn<LocationType>>): TableColumn<LocationType> => ({
  cellAlign: 'flex-start',
  flex: 2,
  renderValue: (location: LocationType) => (
    <TitleCell
      title={location.name}
      subtitle={location.displayAddress}
    />
  ),
  title: 'Location',
  ...params,
});

export const keywordsCell = (params?: Partial<TableColumn<LocationType>>): TableColumn<LocationType> => ({
  renderValue: (location: LocationType) => <>{location?.keywordsCount ?? 0}</>,
  title: 'Keywords',
  ...params,
});

export const LocationTable: FunctionComponent<LocationTableProps> = (props) => {
  const { columns, data, emptyContent, handleSort, sortColumn, sortDirection } = props;
  const router = useIonRouter();

  const [itemsToShow, setItemsToShow] = useState<number>(10);

  const handleItemsToShow = useCallback((value: number) => {
    setItemsToShow(value);
  }, []);

  const onClick = (location: LocationType) => {
    router.push(routes.location.url!(location.id));
  };

  return (
    <Table
      hasPagination
      itemsToShow={itemsToShow}
      handleItemsToShow={handleItemsToShow}
      handleSort={handleSort}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      emptyContent={emptyContent}
      header={columns}
      items={data.map((location) => ({
        content: (
          <>
            <TableCell
              cellAlign="flex-start"
              flex={2}
              key={`cell-location-${location.id}`}
            >
              <TitleCell
                title={location.name}
                subtitle={location.displayAddress}
              />
            </TableCell>
            <TableCell
              cellAlign="center"
              flex={1}
              key={`cell-keywords-${location.id}`}
            >
              {location.keywordsCount.toString()}
            </TableCell>
            <TableCell
              cellAlign="center"
              flex={1}
              key={`cell-competitors-${location.id}`}
            >
              {location.competitorsCount.toString()}
            </TableCell>
            <TableCell
              cellAlign="center"
              flex={1}
              key={`cell-status-${location.id}`}
            >
              {location.statusLocation}
            </TableCell>
            <TableCell
              cellAlign="flex-end"
              flex={1}
            >
              <Button
                onClick={() => onClick(location)}
                style="clear"
              >
                Details
              </Button>
            </TableCell>
          </>
        ),
        id: location.id,
        onClick: () => onClick(location),
      }))}
    />
  );
};
