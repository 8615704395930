import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { ChevronLeftIcon } from '../../icons/ChevronLeftIcon';
import { TableLink } from '../table';
import { ChevronRightIcon } from '../../icons/ChevronRightIcon';
import { Dropdown } from '../dropdown';

type PaginatorProps = {
  handleItemsToShow: (value: number) => void;
  handleNextPage: () => void;
  handlePrevPage: () => void;
  itemsQuantity: number;
  itemsToShow?: number;
  lastPageNumber: number;
  page: number;
  setPage: (value: number) => void;
};

const PaginatorContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TotalText = styled.span`
  font-size: 14px;
`;

const Pagination = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  margin: 10px 0;
`;

const IconContainer = styled.div`
  padding: 10px;
  svg {
    stroke: var(--table-pagination-number-color);
  }
`;

const PageNumberContainer = styled.div<{ isActive?: boolean }>`
  align-items: center;
  background-color: var(--table-pagination-number-bg-color);
  border: 1px solid
    ${({ isActive }) =>
      isActive ? 'var(--table-pagination-number-active-border-color)' : 'var(--table-pagination-number-border-color)'};
  border-radius: 4px;
  color: ${({ isActive }) =>
    isActive ? 'var(--table-pagination-number-active-color)' : 'var(--table-pagination-number-color)'};
  display: flex;
  font-size: 14px;
  height: 30px;
  justify-content: center;
  width: 30px;
`;

const Points = styled.span`
  color: var(--table-pagination-number-color);
  font-size: 14px;
`;

const RANGE_SIZE = 8;

export const Paginator: FunctionComponent<PaginatorProps> = (params) => {
  const {
    handleItemsToShow,
    handleNextPage,
    handlePrevPage,
    itemsQuantity,
    itemsToShow,
    lastPageNumber,
    page,
    setPage,
  } = params;

  const totalPages = Math.ceil((itemsQuantity ?? 0) / (itemsToShow ?? 1));
  const calculatePageRange = (currentPage: number, totalPages: number): number[] => {
    const rangeStart = Math.max(2, Math.min(totalPages - RANGE_SIZE + 1, currentPage - Math.floor(RANGE_SIZE / 2)));

    const rangeEnd = Math.min(totalPages - 1, rangeStart + RANGE_SIZE - 1);

    const pageRange: number[] = [];
    for (let i = rangeStart; i <= rangeEnd; i++) {
      pageRange.push(i);
    }

    return pageRange;
  };

  return (
    <PaginatorContainer>
      <TotalText>Total {itemsQuantity} items</TotalText>
      <Pagination>
        {page > 1 && (
          <TableLink onClick={handlePrevPage}>
            <IconContainer>{ChevronLeftIcon}</IconContainer>
          </TableLink>
        )}
        {totalPages > RANGE_SIZE ? (
          <>
            <TableLink onClick={() => setPage(1)}>
              <PageNumberContainer isActive={page === 1}>{1}</PageNumberContainer>
            </TableLink>
            {page >= 7 && <Points>•••</Points>}
            {calculatePageRange(page, totalPages).map((pageNumber) => (
              <TableLink
                onClick={() => setPage(pageNumber)}
                key={pageNumber}
              >
                <PageNumberContainer isActive={page === pageNumber}>{pageNumber}</PageNumberContainer>
              </TableLink>
            ))}
            {page <= totalPages - 5 && <Points>•••</Points>}
            <TableLink onClick={() => setPage(lastPageNumber)}>
              <PageNumberContainer isActive={page === lastPageNumber}>{lastPageNumber}</PageNumberContainer>
            </TableLink>
          </>
        ) : (
          Array.from({ length: totalPages }).map((_, index) => (
            <TableLink
              onClick={() => setPage(index + 1)}
              key={index}
            >
              <PageNumberContainer isActive={page === index + 1}>{index + 1}</PageNumberContainer>
            </TableLink>
          ))
        )}
        {page < totalPages && (
          <TableLink onClick={handleNextPage}>
            <IconContainer>{ChevronRightIcon}</IconContainer>
          </TableLink>
        )}
        {handleItemsToShow && itemsQuantity > 5 && (
          <Dropdown
            items={[
              { children: '10 per page', value: '10' },
              { children: '20 per page', value: '20' },
              { children: '50 per page', value: '50' },
              { children: '100 per page', value: '100' },
            ]}
            onChange={(value) => handleItemsToShow?.(Number(value))}
            value={itemsToShow?.toString()}
          />
        )}
      </Pagination>
    </PaginatorContainer>
  );
};
