import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalProps } from '../../ui/modal/modal';
import { ModalStepHeader } from '../../ui/modal/headers';
import { ManageAccountSettingsForm } from '../../accounts/settings/form';
import { useCreateAccount } from '../../../hooks/accounts';
import { Button } from '../../ui/button/Button';

type CreateAccountModalProps = Omit<ModalProps, 'children'>;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);
  justify-content: space-between;
  overflow: hidden;
  padding: 16px;
`;

export const CreateAccountModal: FunctionComponent<CreateAccountModalProps> = ({ isOpen, setIsOpen }) => {
  const [nameInput, setNameInput] = useState('');
  const onCreateAccount = useCreateAccount();

  const onSubmit = () => onCreateAccount({ name: nameInput });

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <ModalStepHeader
        title="Create New Account"
        next={false}
        previous={() => setIsOpen(false)}
      />
      <Container>
        <ManageAccountSettingsForm
          onChange={setNameInput}
          value={nameInput}
          onHitEnter={onSubmit}
        />
        <Button
          disabled={nameInput.trim().length === 0}
          fullWidth
          onClick={onSubmit}
        >
          Add Account
        </Button>
      </Container>
    </Modal>
  );
};
