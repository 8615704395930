/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccountCreateDto {
  /** @example "Garage Door Experts" */
  name: string;
}

export interface AccountPatchDto {
  /** @example "Garage Door Experts" */
  name: string;
}

export interface AccountSelectDto {
  id: number;
  /** @example "Garage Door Experts" */
  name: string;
  roles: AccountSelectDtoRolesEnum[];
  status: AccountSelectDtoStatusEnum;
}

export enum AccountSelectDtoRolesEnum {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  OWNER = 'OWNER',
}

export enum AccountSelectDtoStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface AccountUserUpdateDto {
  role: AccountUserUpdateDtoRoleEnum;
}

export enum AccountUserUpdateDtoRoleEnum {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  OWNER = 'OWNER',
}

export interface AccountsSelectDto {
  accounts: AccountSelectDto[];
}

export interface AddOnSelectDto {
  id: number;
  /** @example "Garage Door Experts" */
  name: string;
  pricePerMonth: number;
  status: AddOnSelectDtoStatusEnum;
}

export enum AddOnSelectDtoStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface AdminAccountSelectDto {
  id: number;
  /** @example "Garage Door Experts" */
  name: string;
  status: AdminAccountSelectDtoStatusEnum;
  /** @example "https://dashboard.stripe.com/customers/cus_1234567890" */
  stripeCustomerUrl?: string;
  subscriptions: SubscriptionSelectDto[];
}

export enum AdminAccountSelectDtoStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface AdminAccountsSelectDto {
  accounts: AdminAccountSelectDto[];
}

export interface AllModeratorStatsSelectDto {
  name: string;
  reviewsCount: number;
}

export interface AllModeratorsStatsSelectDto {
  moderators: AllModeratorStatsSelectDto[];
}

export interface CompetitorSelectDto {
  address?: string;
  distance?: number;
  googleMapsUrl: string;
  googleStatus: CompetitorSelectDtoGoogleStatusEnum;
  id: number;
  keywords: KeywordSelectDto[];
  latestReview?: ReviewSelectDto;
  name: string;
  status: CompetitorSelectDtoStatusEnum;
}

export enum CompetitorSelectDtoGoogleStatusEnum {
  ACTIVE = 'ACTIVE',
  HARD_SUSPENDED = 'HARD_SUSPENDED',
  PERMANENTLY_CLOSED = 'PERMANENTLY_CLOSED',
}

export enum CompetitorSelectDtoStatusEnum {
  INVALID = 'INVALID',
  UNDER_REVIEW = 'UNDER_REVIEW',
  VALID = 'VALID',
}

export interface CompetitorsSelectDto {
  competitors: CompetitorSelectDto[];
}

export interface DashboardSelectDto {
  competitorsKeywordsChanged: number;
  competitorsMonitored: number;
  competitorsRemoved: number;
}

export interface DraftSubscriptionSelectDto {
  redirectUrl: string;
}

/** @default 10 */
export enum GetLicenseChangelogParamsLimitEnum {
  Value10 = 10,
  Value20 = 20,
  Value50 = 50,
  Value100 = 100,
}

export interface InviteCreateDto {
  /** @format email */
  email: string;
  roles: InviteCreateDtoRolesEnum[];
}

export enum InviteCreateDtoRolesEnum {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  OWNER = 'OWNER',
}

export interface InviteSelectDto {
  /** @format date-time */
  acceptedAt?: string;
  account: AccountSelectDto;
  /** @format date-time */
  createdAt: string;
  /** @format email */
  email: string;
  /** @min 1 */
  id: number;
  /** @format date-time */
  rejectedAt?: string;
  rejectedReason?: InviteSelectDtoRejectedReasonEnum;
  roles: InviteSelectDtoRolesEnum[];
  status: InviteSelectDtoStatusEnum;
  user: UserSelectDto;
}

export enum InviteSelectDtoRejectedReasonEnum {
  REJECTED = 'REJECTED',
  REVOKED = 'REVOKED',
}

export enum InviteSelectDtoRolesEnum {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  OWNER = 'OWNER',
}

export enum InviteSelectDtoStatusEnum {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export interface InviteUpdateDto {
  roles: InviteUpdateDtoRolesEnum[];
}

export enum InviteUpdateDtoRolesEnum {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  OWNER = 'OWNER',
}

export interface InvitesSelectDto {
  invites: InviteSelectDto[];
}

export interface KeywordSelectDto {
  text: string;
}

export interface KeywordStatsSelectDto {
  keyword: string;
}

export interface KeywordsStatsSelectDto {
  keywords: KeywordStatsSelectDto[];
}

export interface LicenseSelectDto {
  /** @format date-time */
  createdAt: string;
  id: number;
  keywords?: KeywordSelectDto[];
  scanOverrides?: object;
  status: LicenseSelectDtoStatusEnum;
}

export enum LicenseSelectDtoStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface LicenseStatsDto {
  hardSuspendedCount: number;
  keywordsRemovedCount: number;
  monitoredCount: number;
  newCount: number;
  permanentlyClosedCount: number;
  returnedCount: number;
}

export interface LineItemDto {
  addOnId: number;
  quantity: number;
}

export interface LocalFalconLocationSelectDto {
  address?: string;
  googleMapsUrl: string;
  googlePlaceId: string;
  isServiceAreaBusiness?: boolean;
  latitude?: number;
  longitude?: number;
  name: string;
}

export interface LocalFalconLocationsSelectDto {
  locations: LocalFalconLocationSelectDto[];
}

export interface LocationChangelogItemDto {
  address?: string;
  googleMapsUrl: string;
  googleStatus: LocationChangelogItemDtoGoogleStatusEnum;
  name: string;
  originalName?: string;
  /** @format date-time */
  timestamp: string;
  type: LocationChangelogItemDtoTypeEnum;
}

export enum LocationChangelogItemDtoGoogleStatusEnum {
  ACTIVE = 'ACTIVE',
  HARD_SUSPENDED = 'HARD_SUSPENDED',
  PERMANENTLY_CLOSED = 'PERMANENTLY_CLOSED',
}

export enum LocationChangelogItemDtoTypeEnum {
  FOUND = 'FOUND',
  KEYWORDS_CHANGED = 'KEYWORDS_CHANGED',
  REMOVED = 'REMOVED',
  REVIEWED = 'REVIEWED',
}

export interface LocationChangelogSelectDto {
  changelogItems: LocationChangelogItemDto[];
  count: number;
  /** @default 10 */
  limit: LocationChangelogSelectDtoLimitEnum;
  page: number;
}

/** @default 10 */
export enum LocationChangelogSelectDtoLimitEnum {
  Value10 = 10,
  Value20 = 20,
  Value50 = 50,
  Value100 = 100,
}

export interface LocationClaimDto {
  address?: string;
  googleMapsUrl: string;
  googlePlaceId: string;
  isServiceAreaBusiness?: boolean;
  latitude?: number;
  longitude?: number;
  name: string;
}

export interface LocationOverridesSelectDto {
  address?: string;
  latitude?: number;
  longitude?: number;
}

export interface LocationProtectDto {
  address?: string;
  googleMapsUrl: string;
  googlePlaceId: string;
  isServiceAreaBusiness?: boolean;
  keywords?: string[];
  latitude?: number;
  longitude?: number;
  name: string;
  scanOverrides?: LocationOverridesSelectDto;
}

export interface LocationSelectDto {
  address?: string;
  displayAddress?: string;
  googleMapsUrl: string;
  googlePlaceId: string;
  googleStatus: LocationSelectDtoGoogleStatusEnum;
  id: number;
  latitude?: number;
  license?: LicenseSelectDto;
  longitude?: number;
  name: string;
  status: LocationSelectDtoStatusEnum;
}

export enum LocationSelectDtoGoogleStatusEnum {
  ACTIVE = 'ACTIVE',
  HARD_SUSPENDED = 'HARD_SUSPENDED',
  PERMANENTLY_CLOSED = 'PERMANENTLY_CLOSED',
}

export enum LocationSelectDtoStatusEnum {
  INVALID = 'INVALID',
  UNDER_REVIEW = 'UNDER_REVIEW',
  VALID = 'VALID',
}

export interface LocationWithStatsSelectDto {
  address?: string;
  competitorsCount: number;
  displayAddress?: string;
  googleMapsUrl: string;
  googlePlaceId: string;
  googleStatus: LocationWithStatsSelectDtoGoogleStatusEnum;
  id: number;
  keywordsCount: number;
  latitude?: number;
  license?: LicenseSelectDto;
  longitude?: number;
  name: string;
  status: LocationWithStatsSelectDtoStatusEnum;
}

export enum LocationWithStatsSelectDtoGoogleStatusEnum {
  ACTIVE = 'ACTIVE',
  HARD_SUSPENDED = 'HARD_SUSPENDED',
  PERMANENTLY_CLOSED = 'PERMANENTLY_CLOSED',
}

export enum LocationWithStatsSelectDtoStatusEnum {
  INVALID = 'INVALID',
  UNDER_REVIEW = 'UNDER_REVIEW',
  VALID = 'VALID',
}

export interface LocationsSelectDto {
  locations: LocationWithStatsSelectDto[];
}

export interface ModeratorCompetitorSelectDto {
  address?: string;
  daysPastDue: number;
  googleMapsUrl: string;
  id: number;
  name: string;
}

export interface ModeratorCompetitorsSelectDto {
  competitors: ModeratorCompetitorSelectDto[];
}

export interface ModeratorCompetitorsStatsDto {
  activeReviewCount: number;
  reviewCount: number;
}

export interface ModeratorRedressalCsvCreateDto {
  email: string;
  name: string;
}

export interface ModeratorRedressalCsvSelectDto {
  csvUrl: string;
}

export interface ModeratorSelectDto {
  name: string;
}

export interface ModeratorStatsSelectDto {
  reviewsCount: number;
}

export interface PackageSelectDto {
  addOns: AddOnSelectDto[];
  description?: string;
  id: number;
  name: string;
  numKeywords: number;
  numLicenses: number;
  numScans: number;
  pricePerMonth: number;
  status: PackageSelectDtoStatusEnum;
}

export enum PackageSelectDtoStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface PackagesSelectDto {
  packages: PackageSelectDto[];
}

export interface PlaceSelectDto {
  /** @example "123 Any Street, Any Town, USA" */
  address?: string;
  id?: string;
  latitude?: number;
  longitude?: number;
}

export interface PlacesSelectDto {
  places: PlaceSelectDto[];
}

export interface ReviewPatchDto {
  googleChangeReason?: ReviewPatchDtoGoogleChangeReasonEnum;
  googleProofMemo?: string;
  reasonRequested: ReviewPatchDtoReasonRequestedEnum;
  requestedNameChange?: string;
}

export enum ReviewPatchDtoGoogleChangeReasonEnum {
  DOES_NOT_EXIST = 'DOES_NOT_EXIST',
  DUPLICATE = 'DUPLICATE',
  MOVED = 'MOVED',
  NOT_OPEN = 'NOT_OPEN',
  OFFENSE_HARMFUL_MISLEADING = 'OFFENSE_HARMFUL_MISLEADING',
  OTHER = 'OTHER',
  PERMANENTLY_CLOSED = 'PERMANENTLY_CLOSED',
}

export enum ReviewPatchDtoReasonRequestedEnum {
  KEYWORD_STUFFED = 'KEYWORD_STUFFED',
  SPAM = 'SPAM',
}

export interface ReviewSelectDto {
  googleChangeReason?: ReviewSelectDtoGoogleChangeReasonEnum;
  googleProofMemo?: string;
  googleProofUrl?: string;
  reasonRequested?: ReviewSelectDtoReasonRequestedEnum;
  requestedNameChange?: string;
  status: ReviewSelectDtoStatusEnum;
}

export enum ReviewSelectDtoGoogleChangeReasonEnum {
  DOES_NOT_EXIST = 'DOES_NOT_EXIST',
  DUPLICATE = 'DUPLICATE',
  MOVED = 'MOVED',
  NOT_OPEN = 'NOT_OPEN',
  OFFENSE_HARMFUL_MISLEADING = 'OFFENSE_HARMFUL_MISLEADING',
  OTHER = 'OTHER',
  PERMANENTLY_CLOSED = 'PERMANENTLY_CLOSED',
}

export enum ReviewSelectDtoReasonRequestedEnum {
  KEYWORD_STUFFED = 'KEYWORD_STUFFED',
  SPAM = 'SPAM',
}

export enum ReviewSelectDtoStatusEnum {
  CHANGE_REQUESTED = 'CHANGE_REQUESTED',
  CLAIMED = 'CLAIMED',
  DEEMED_VALID = 'DEEMED_VALID',
  KEYWORDS_CHANGED = 'KEYWORDS_CHANGED',
  OUT_OF_RANGE = 'OUT_OF_RANGE',
  PENDING = 'PENDING',
  REMOVED = 'REMOVED',
}

export interface SubscriptionAddOns {
  addOnId: number;
  quantity: number;
}

export interface SubscriptionCreateDto {
  lineItems?: LineItemDto[];
  numLicenses: number;
  packageId: number;
}

export interface SubscriptionSelectDto {
  billingCycleDay: number;
  id: number;
  isActive: number;
  isFull: boolean;
  numLicenses: number;
  package: PackageSelectDto;
  status: SubscriptionSelectDtoStatusEnum;
  stripeId: string;
  subscriptionAddOns: SubscriptionAddOns[];
  useCheckout: boolean;
  usedLicenses: number;
}

export enum SubscriptionSelectDtoStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
}

export interface SubscriptionsSelectDto {
  subscriptions: SubscriptionSelectDto[];
}

export interface UserSelectDto {
  /** @format email */
  email: string;
  /** @min 1 */
  id: number;
}

export interface UserWithRolesSelectDto {
  /** @format email */
  email: string;
  /** @min 1 */
  id: number;
  roles: UserWithRolesSelectDtoRolesEnum[];
}

export enum UserWithRolesSelectDtoRolesEnum {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  OWNER = 'OWNER',
}

export interface UsersWithRolesSelectDto {
  users: UserWithRolesSelectDto[];
}
