export function pluralize(params: { plural: string; singular: string; value: number }) {
  const { plural, singular, value } = params;
  return value > 1 ? plural : singular;
}

export function capitalize(text: string) {
  return text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
}

export function initials(text: string): string {
  const words = text.split(' ');
  const initials = words.map((word) => word.charAt(0).toUpperCase());
  return initials.join('');
}

export function usernameFromEmail(email: string) {
  const [username] = email.split('@');
  return username;
}
