import { IonLabel } from '@ionic/react';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Input } from '../../ui/Input';
import { Col } from '../../ui/containers';

export type ManageAccountFormProps = {
  action?: React.ReactNode;
  onChange: (name: string) => void;
  onHitEnter?: () => void;
  value?: string;
};

const Container = styled(Col)`
  gap: 10px;
`;

export const ManageAccountSettingsForm: FunctionComponent<ManageAccountFormProps> = (props) => {
  const { action, onChange, onHitEnter, value } = props;

  return (
    <Container>
      <IonLabel>Account Name:</IonLabel>
      <Input
        onChange={(input) => onChange(input)}
        onEnterKey={onHitEnter}
        placeholder="Enter text"
        value={value}
      />
      {action}
    </Container>
  );
};
