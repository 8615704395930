const alphabet = 'abcdefghijklmnopqrstuvwxyz';
const numbers = '0123456789';

export const randomAlphabetString = (length: number): string => {
  return randomString(length, alphabet);
};

export const randomAlphaNumericString = (length: number): string => {
  return randomString(length, `${alphabet}${numbers}`);
};

export const randomNumericString = (length: number): string => {
  return randomString(length, numbers);
};

export const randomString = (length: number, choices: string) => {
  let text = '';
  for (let i = 0; i < length; i++) text += choices.charAt(Math.floor(Math.random() * choices.length));
  return text;
};
