import { FunctionComponent, useRef, useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalProps } from '../../ui/modal/modal';
import { ModalStepHeader } from '../../ui/modal/headers';
import { Button } from '../../ui/button/Button';
import { InviteUsers, InviteUsersRef } from '../../invites/invite-users';

type InviteUsersModalProps = Omit<ModalProps, 'children'>;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);
  justify-content: space-between;
  overflow: hidden;
  padding: 16px;
`;

export const InviteUsersModal: FunctionComponent<InviteUsersModalProps> = ({ isOpen, setIsOpen }) => {
  const inviteUsersRef = useRef<InviteUsersRef | null>();
  const [isReady, setIsReady] = useState(false);

  const onInviteUsersRef = (ref: InviteUsersRef) => {
    inviteUsersRef.current = ref;
  };

  const onInviteUsers = () => {
    if (isReady && inviteUsersRef.current) {
      inviteUsersRef.current?.sendInvitations();
      setIsOpen(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <ModalStepHeader
        title="Invite Users"
        next={false}
        previous={() => setIsOpen(false)}
      />
      <Container>
        <InviteUsers
          onRef={onInviteUsersRef}
          onErrors={(hasError) => setIsReady(!hasError)}
        />
        <Button
          disabled={!isReady}
          fullWidth
          onClick={onInviteUsers}
        >
          Invite Users
        </Button>
      </Container>
    </Modal>
  );
};
