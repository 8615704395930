import { useRecoilState } from 'recoil';
import { showSplashState } from '../states/app';

export function useSplash() {
  const [, setShowSplash] = useRecoilState(showSplashState);

  const startLoading = () => {
    setShowSplash(true);
  };

  const stopLoading = () => {
    setShowSplash(false);
  };

  return { startLoading, stopLoading };
}
