export const ArrowTrSquareIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      className="stroke-path"
      d="M8 7.99127L13.9912 2M13.9912 2H10.6914M13.9912 2V5.29985"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke-path"
      d="M14 2.4C14 2.17909 13.8209 2 13.6 2M7 2H2.4C2.17909 2 2 2.17909 2 2.4V13.6C2 13.8209 2.17909 14 2.4 14H13.6C13.8209 14 14 13.8209 14 13.6V9"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
