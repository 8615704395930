const formatCurrency = (amount: number, locale: string, currency: string): string => {
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    style: 'currency',
  });

  return formatter.format(roundCurrency(amount));
};

export const formatUsd = (amount: number): string => {
  return formatCurrency(amount, 'en-US', 'USD');
};

export const formatUsdStripZeroPennies = (amount: number) => formatUsd(amount).replace('.00', '');

function round(amount: number, fractionDigits?: number) {
  const multiplier = Math.pow(10, fractionDigits || 0);
  let rounded = Math.round((amount + Number.EPSILON) * multiplier) / multiplier;
  if (fractionDigits) {
    rounded = Number(rounded.toFixed(fractionDigits));
  }
  return rounded;
}

function roundCurrency(amount: number): number {
  return round(amount, 2);
}
