import { usePostHog } from 'posthog-js/react';
import ReactGA from 'react-ga4';

export enum Event {
  CLAIMED_LOCATION = 'Claimed Location',
  PROTECTED_LOCATION = 'Protected Location',
}

export type EventProperties = {
  [Event.CLAIMED_LOCATION]: Record<string, never>;
  [Event.PROTECTED_LOCATION]: Record<string, never>;
};

export const useEvents = () => {
  const posthog = usePostHog();

  const trackPageview = () => {
    try {
      // Google Analytics already handles SPA page views, so we only need to
      //   account for PostHog here
      //
      posthog.capture('$pageview');
    } catch (error) {
      console.error('Error tracking pageview', error);
    }
  };

  const trackEvent = <T extends Event>(event: T, properties: EventProperties[T]) => {
    try {
      posthog.capture(event, properties);
      ReactGA.event(event, properties);
    } catch (error) {
      console.error('Error tracking event', error);
    }
  };

  return { trackEvent, trackPageview };
};
