import styled from 'styled-components';
import { MagnifyingGlassIcon } from '../icons/MagnifyingGlassIcon';

type InputProps = {
  disabled?: boolean;
  hasMagnifyingGlass?: boolean;
  onChange: (input: string) => void;
  onEnterKey?: () => void;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  value?: string;
};

const InputContainer = styled.div<{ disabled: boolean; hasText: boolean }>`
  align-items: center;
  background: var(--global-components-bg-color);
  border: 1px solid var(--modal-border-color);
  border-radius: 8px;
  box-sizing: border-box;
  column-gap: 8px;
  display: flex;
  height: 44px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding: 0 12px;
  width: 100%;

  path {
    stroke: ${({ hasText }) => (hasText ? 'var(--global-font-color-data)' : 'var(--global-font-color-data)')};
  }
`;

const InputBox = styled.input`
  appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  color: var(--global-font-color-data);
  font-size: 14px;
  margin: 0;
  outline: none;
  padding: 0;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
  }

  ::placeholder {
    color: var(--global-font-color-disabled);
  }
`;

export const Input: React.FC<InputProps> = ({
  disabled = false,
  hasMagnifyingGlass = true,
  onChange,
  onEnterKey,
  placeholder,
  type = 'text',
  value,
}) => {
  return (
    <InputContainer
      disabled={disabled}
      hasText={Boolean(value && value.trim().length > 0)}
    >
      {hasMagnifyingGlass && MagnifyingGlassIcon}
      <InputBox
        disabled={disabled}
        onChange={(event) => onChange(event.target.value)}
        onKeyDown={(event) => event.key === 'Enter' && onEnterKey && onEnterKey()}
        placeholder={placeholder}
        type={type}
        value={value}
      />
    </InputContainer>
  );
};
