import { IonIcon, useIonRouter } from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline, helpCircleOutline } from 'ionicons/icons';
import styled from 'styled-components';
import { config } from '../config';
import { useDrawer, useIsMobile } from '../hooks/app';
import { useAvailableSideMenuRoutes } from '../hooks/navigation';
import { Route, routes, supportEmail } from '../routes';
import { DrawerState, sideMenuWidthCSSVar } from '../types/app';
import { openUrlInNewTab } from '../utils/url';
import { Brand } from './brand';
import { UserAccountMenu } from './menu/user-account';

type Action = {
  icon: string;
  name: string;
  onClick: () => void;
  toggleMenu?: boolean;
};

export const sideMenuTranslationCSS: Record<DrawerState, string> = {
  CLOSE: '-100%',
  COLLAPSED: '-100%',
  OPEN: '0',
};

const SideMenuContainer = styled.div<{
  isCollapsed: boolean;
  state: DrawerState;
}>`
  background-color: var(--side-menu-bg-color);
  border-color: transparent !important;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 12px;
  position: static;
  transform: translateX(${({ state }) => sideMenuTranslationCSS[state]});
  width: ${({ state }) => `var(${sideMenuWidthCSSVar[state]})`};
  z-index: 999;

  @media (min-width: ${config.app.mobileBreakpoint}px) {
    top: 0;
    left: 0;
    transform: translateX(0px);
  }

  @media (max-width: ${config.app.mobileBreakpoint}px) {
    transition-duration: 150ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

const SideMenuBackdrop = styled.div<{
  state: DrawerState;
}>`
  background-color: transparent;
  display: none;
  height: 100vh;
  position: absolute;
  width: 100vw;
  z-index: 998;

  @media (max-width: ${config.app.mobileBreakpoint}px) {
    display: ${({ state }) => (state === DrawerState.OPEN ? 'block' : 'none')};
    transition-duration: 150ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

const SideMenuHeader = styled.div<{ isCollapsed: boolean }>`
  margin: 0;

  @media (min-width: ${config.app.mobileBreakpoint}px) {
    margin: ${({ isCollapsed }) => (isCollapsed ? '0 auto' : '0')};
  }
`;

const SideMenuUserAccountMenu = styled(UserAccountMenu)`
  --background-hover: transparent;
  --ion-item-background: transparent;
`;

const NavItemDivider = styled.div`
  height: 20px;
  width: 100%;
`;

const NavItemContainer = styled.div<{ active?: boolean }>`
  align-items: center;
  background-color: ${({ active }) => (active ? 'var(--nav-item-bg-color-active)' : 'var(--nav-item-bg-color)')};
  border-radius: 4px;
  color: ${({ active }) => (active ? 'var(--nav-item-font-color-active)' : 'var(--nav-item-font-color)')};
  display: flex;
  height: 50px;
  justify-content: flex-start;
  width: 100%;

  &:hover {
    background-color: var(--nav-item-bg-color-hover);
    color: var(--nav-item-font-color-hover);
    cursor: pointer;
  }
`;

const NavItemActiveBar = styled.div`
  background-color: var(--nav-item-active-bar-bg-color);
  border-radius: 0px 3px 3px 0px;
  height: 28px;
  left: 0;
  position: absolute;
  width: 5px;
`;

const NavItemIcon = styled.div<{ isCollapsed: boolean }>`
  align-items: center;
  display: flex;
  font-size: 25px;
  justify-content: center;
  margin: 0 10px;
`;

const NavItemLabel = styled.div<{ isCollapsed: boolean }>`
  font-weight: 500;
  @media (min-width: ${config.app.mobileBreakpoint}px) {
    display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
  }
`;

// TODO - recent locations is being replaced with something new, commenting out to save
//   styles
//
// const RecentLocationsContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin: 0 0 10px;
//   padding: 0 0 0 40px;
// `;

// const RecentLocation = styled.div<{ active?: boolean }>`
//   border-radius: 4px;
//   color: var(--nav-item-font-color);
//   cursor: pointer;
//   font-size: 14px;
//   font-weight: ${({ active }) => (active ? 700 : 400)};
//   overflow: hidden;
//   padding: 5px;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   width: 100%;

//   &:hover {
//     background-color: var(--nav-item-bg-color-hover);
//     color: var(--nav-item-font-color-hover);
//     cursor: pointer;
//   }
// `;

export const SideMenu: React.FC = () => {
  const { closeDrawer, collapseDrawer, isCollapsed, openDrawer, state } = useDrawer();
  const isMobile = useIsMobile();
  const availableSideMenuRoutes = useAvailableSideMenuRoutes();
  const router = useIonRouter();

  const toggleCollapseDrawer = isCollapsed ? openDrawer : isMobile ? closeDrawer : collapseDrawer;

  const actions: Action[] = [
    {
      icon: helpCircleOutline,
      name: 'Get Help',
      onClick: () => openUrlInNewTab(`mailto:${supportEmail}`),
    },
    {
      icon: isCollapsed ? chevronForwardOutline : chevronBackOutline,
      name: 'Collapse',
      onClick: toggleCollapseDrawer,
    },
  ];

  const sideMenuItems: (Route | 'divider')[] = [routes.dashboard, 'divider', ...availableSideMenuRoutes];

  const isActive = (route: string): boolean => {
    return router.routeInfo.pathname === route;
  };

  return (
    <>
      <SideMenuContainer
        isCollapsed={isCollapsed}
        state={state}
      >
        <div>
          <SideMenuHeader isCollapsed={isCollapsed}>
            <Brand
              className="ion-hide-lg-down"
              isCollapsed={isCollapsed}
            />
            <NavItemDivider />
            <SideMenuUserAccountMenu className="ion-hide-lg-down" />
          </SideMenuHeader>
          <NavItemDivider />

          {sideMenuItems.map((item, index) => {
            if (item === 'divider') {
              return <NavItemDivider key={index} />;
            }

            const active = isActive(item.href);

            return (
              <div key={index}>
                <NavItemContainer
                  active={active}
                  onClick={() => router.push(item.href)}
                >
                  {active && <NavItemActiveBar />}
                  <NavItemIcon isCollapsed={isCollapsed}>
                    <IonIcon icon={item.icon} />
                  </NavItemIcon>
                  <NavItemLabel isCollapsed={isCollapsed}>{item.name}</NavItemLabel>
                </NavItemContainer>
                {/* {!isCollapsed && recentLicenses.length > 0 && item.href === routes.locations.href && (
                  <RecentLocationsContainer>
                    {recentLicenses.map((license, index) => (
                      <RecentLocation
                        key={index}
                        active={isActive(routes.license.url!(license.id))}
                        onClick={() => router.push(routes.license.url!(license.id))}
                      >
                        {license.location!.name}
                      </RecentLocation>
                    ))}
                  </RecentLocationsContainer>
                )} */}
              </div>
            );
          })}
        </div>
        <div>
          <NavItemDivider />
          {actions.map((action, index) => {
            return (
              <NavItemContainer
                key={index}
                onClick={() => action.onClick()}
              >
                <NavItemIcon isCollapsed={isCollapsed}>
                  <IonIcon icon={action.icon} />
                </NavItemIcon>
                <NavItemLabel isCollapsed={isCollapsed}>{action.name}</NavItemLabel>
              </NavItemContainer>
            );
          })}
        </div>
      </SideMenuContainer>
      <SideMenuBackdrop
        state={state}
        onClick={() => closeDrawer()}
      />
    </>
  );
};
