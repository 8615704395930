export function localStorageEffect(key: string) {
  return ({ onSet, setSelf }) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue === 'undefined') {
      setSelf(undefined);
    } else if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any) => {
      if (newValue === undefined) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };
}
