import { useQuery } from '@tanstack/react-query';
import { httpGet } from '../utils/api-client';
import { PackageSelectDto } from '../types/api.types';
import { queryKeys } from './keys';

type GetPackagesResponse = {
  packages: PackageSelectDto[];
};

export function useGetPackages() {
  return useQuery<PackageSelectDto[]>({
    queryFn: async () => {
      const { packages } = await httpGet<GetPackagesResponse>({
        path: '/packages',
      });
      return packages;
    },
    queryKey: queryKeys.packages.getPackages,
  });
}
