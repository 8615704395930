import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { currentAccountState } from '../states/account';
import { isAllowed } from '../utils/role';
import { AccountUserUpdateDtoRoleEnum } from '../types/api.types';

export function useGetActiveUserForAccount() {
  const currentAccount = useRecoilValue(currentAccountState);
  return currentAccount?.user;
}

export function useIsCurrentUserAllowed(role: AccountUserUpdateDtoRoleEnum) {
  const currentUser = useGetActiveUserForAccount();
  const [isUserAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    setIsAllowed(
      Boolean(
        currentUser &&
          isAllowed({
            role1: currentUser.roles[0] as unknown as AccountUserUpdateDtoRoleEnum,
            role2: role,
          }),
      ),
    );
  }, [currentUser, role]);

  return isUserAllowed;
}
