import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import { OpenLinkIcon } from '../../../icons/OpenLinkIcon';
import { PositionIcon } from '../../../icons/PositionIcon';
import { openUrlInNewTab } from '../../../../utils/url';
import { Input } from '../../../ui/Input';
import { Button } from '../../../ui/button/Button';
import { config } from '../../../../config';
import { CoordinatesLocation } from '../../../../types/location.types';
import { LocalFalconLocationSelectDto } from '../../../../types/api.types';

type ConfirmProtectionAreaStepProps = {
  defaultLocation: CoordinatesLocation;
  isNullCoordinates: boolean;
  location?: LocalFalconLocationSelectDto;
  newCordinates: CoordinatesLocation;
  setIsNullCoordinates: (isNullCoordinates: boolean) => void;
  setNewCordinates: (newCordinates: CoordinatesLocation) => void;
};

const Container = styled.div`
  height: calc(100% + 32px);
  margin: -16px;
`;

const TextNullCoordinates = styled.div`
  align-items: center;
  display: flex;
  height: 70%;
  justify-content: center;
  padding: 16px;
`;

const Text = styled.p`
  text-align: center;
  width: 80%;
`;

const MapContainer = styled.div<{ showMap: boolean }>`
  display: ${({ showMap }) => (showMap ? 'block' : 'none')};
  height: 60%;
  width: 100%;
`;

const ProtectionArea = styled.div`
  align-items: center;
  background-color: var(--global-color-box-map-picker);
  border: 1px solid var(--global-font-color-action);
  border-radius: 4px;
  color: var(--global-font-color-action);
  display: inline-flex;
  font-size: 24px;
  font-weight: 100;
  height: 290px;
  justify-content: center;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 290px;
`;

const MessageContainer = styled.div`
  background: var(--global-components-bg-color);
  border: 1px solid var(--global-components-border-color);
  border-radius: 8px;
  bottom: 0;
  color: var(--global-font-color-data);
  font-size: 14px;
  font-weight: 400;
  left: 0;
  margin: 19px 16px;
  position: absolute;
  width: calc(100% - 32px);
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 20px;
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 6px;
`;

const MessageTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const IconContainer = styled.div`
  background-color: var(--global-icon-bg-color);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: 4px;
  width: 24px;
  svg {
    height: 16px;
    stroke: var(--global-font-color-text);
    width: 16px;
  }
`;

const MessageText = styled.div`
  color: var(--global-font-color-text);
  font-size: 12px;
`;

const ChipServiceArea = styled.div`
  align-items: center;
  background-color: var(--badge-bg-color-warning);
  border-radius: 4px;
  color: var(--badge-font-color-warning);
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  padding: 4px 8px;
  text-transform: uppercase;
  width: fit-content;
`;

const PositionContainer = styled.div`
  align-items: center;
  background-color: var(--global-icon-bg-color);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 24px;
  svg {
    height: 16px;
    stroke: var(--global-font-color-text);
    width: 16px;
  }
`;

const FooterContainer = styled.div`
  background: var(--global-font-color-subtitle-two);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
`;

const FooterMessage = styled.div`
  color: var(--global-font-color);
  font-size: 14px;
  font-weight: 400;
`;

const Searchbar = styled.div`
  align-items: center;
  column-gap: 8px;
  display: flex;
`;

const ErrorMessage = styled.span`
  color: var(--global-color-danger);
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
`;

const ProtectionAreaComponent: React.FC = () => <ProtectionArea>+</ProtectionArea>;

export const ConfirmProtectionArea: React.FC<ConfirmProtectionAreaStepProps> = ({
  defaultLocation,
  isNullCoordinates,
  location,
  newCordinates,
  setIsNullCoordinates,
  setNewCordinates,
}) => {
  const [inputAddress, setInputAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [zoom] = useState(10.6);
  const mapHasBeenDragged = useRef(false);

  const handleSearch = () => {
    mapHasBeenDragged.current = false;
    setShowError(false);
    geocodeAddress(inputAddress);
  };

  const geocodeAddress = async (address: string) => {
    setLoading(true);
    const url = new URL('https://maps.googleapis.com/maps/api/geocode/json');
    url.search = new URLSearchParams({
      address: address,
      key: config.googleMaps?.apiKey ?? '',
    }).toString();

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        const address = data.results[0].formatted_address;
        if (lat === null || lng === null) {
          setIsNullCoordinates(true);
        } else {
          setIsNullCoordinates(false);
        }
        setNewCordinates({ address, lat, lng });
        setInputAddress(address);
        setLoading(false);
      } else {
        console.log('No results found');
        setShowError(true);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error during geocoding', error);
      setLoading(false);
    }
  };

  const resetCenter = () => {
    setNewCordinates({
      address: location?.address || '',
      lat: location?.latitude || 0,
      lng: location?.longitude || 0,
    });
    if (!location?.latitude || !location?.longitude) {
      setIsNullCoordinates(true);
    }
    setInputAddress(location?.address || '');
  };

  const handleMapChange = useCallback(
    async ({ center }) => {
      const address = newCordinates.address === location?.address ? location?.address : newCordinates.address;
      const addressOrUndefined = mapHasBeenDragged.current ? undefined : address;
      setNewCordinates({
        ...newCordinates,
        address: addressOrUndefined,
        lat: center.lat,
        lng: center.lng,
      });
      if (center.lat === 0 && center.lng === 0) {
        setIsNullCoordinates(true);
      }
      if (mapHasBeenDragged.current === true) {
        setInputAddress(center.lat + ', ' + center.lng);
        mapHasBeenDragged.current = false;
      }
    },
    [setNewCordinates, newCordinates, setIsNullCoordinates, location?.address],
  );

  useEffect(() => {
    if (location) {
      setNewCordinates({
        address: location.address || '',
        lat: location.latitude || 0,
        lng: location.longitude || 0,
      });
    }
  }, [location, setNewCordinates]);

  return (
    <Container>
      {isNullCoordinates && (
        <TextNullCoordinates>
          <Text>Unable to map this location. You must enter a new address below</Text>
        </TextNullCoordinates>
      )}

      <MapContainer showMap={!isNullCoordinates}>
        {newCordinates !== defaultLocation && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: config.googleMaps?.apiKey ?? '' }}
            defaultCenter={defaultLocation}
            center={newCordinates}
            defaultZoom={zoom}
            onChange={handleMapChange}
            onDrag={() => (mapHasBeenDragged.current = true)}
            draggable={true}
            options={{
              maxZoom: zoom,
              minZoom: zoom,
              scrollwheel: false,
              zoomControl: false,
            }}
          >
            <ProtectionAreaComponent />
          </GoogleMapReact>
        )}
      </MapContainer>
      {location && (
        <MessageContainer>
          <TitleSection>
            <TitleContainer>
              <MessageTitle>{location.name}</MessageTitle>
              <IconContainer
                onClick={(event) => {
                  event.stopPropagation();
                  openUrlInNewTab(location.googleMapsUrl);
                }}
              >
                {OpenLinkIcon}
              </IconContainer>
            </TitleContainer>
            <MessageText>{location.address}</MessageText>
            {location.isServiceAreaBusiness && <ChipServiceArea>SERVICE AREA BUSINESS</ChipServiceArea>}
            <PositionContainer
              onClick={(event) => {
                event.stopPropagation();
                resetCenter();
              }}
            >
              {PositionIcon}
            </PositionContainer>
          </TitleSection>
          <FooterContainer>
            <FooterMessage>Pan the map above or enter an address below</FooterMessage>
            <Searchbar>
              <Input
                disabled={loading}
                onChange={(input) => setInputAddress(input)}
                placeholder="Input address or coordinates..."
                value={inputAddress}
              ></Input>
              <Button
                disabled={loading}
                isLoading={loading}
                onClick={handleSearch}
              >
                {isNullCoordinates ? 'Enter' : 'Re-center'}
              </Button>
            </Searchbar>
            {showError && <ErrorMessage>No results found</ErrorMessage>}
          </FooterContainer>
        </MessageContainer>
      )}
    </Container>
  );
};
