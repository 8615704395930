import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { LeftArrowIcon } from '../../icons/LeftArrowIcon';
import { RightArrowIcon } from '../../icons/RightArrowIcon';

type ModalStepHeaderProps = {
  isDisabledNextButton?: boolean;
  next?: (() => void) | false;
  previous?: (() => void) | false;
  title: string;
};

const HeaderContainer = styled.div`
  align-items: center;
  background: var(--modal-header-bg-color);
  border-bottom: 1px solid var(--modal-border-color);
  column-gap: 10px;
  display: flex;
  height: 48px;
  padding: 0 12px;
`;

const PreviousStepContainer = styled.div<{ disabled?: boolean }>`
  align-items: center;
  color: ${({ disabled }) => (disabled ? 'var(--global-font-color-disabled)' : 'var(--global-font-color)')};
  column-gap: 4px;
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  display: flex;
  font-size: 14px;
  width: 60px;

  path {
    stroke: ${({ disabled }) => (disabled ? 'var(--global-font-color-disabled)' : 'var(--nav-item-font-color)')};
  }

  &:hover {
    color: ${({ disabled }) => (disabled ? undefined : 'var(--global-font-color-hover)')};

    path {
      stroke: ${({ disabled }) => (disabled ? undefined : 'var(--global-font-color-hover)')};
    }
  }
`;

const NextStepContainer = styled(PreviousStepContainer)`
  color: ${({ disabled }) => (disabled ? 'var(--global-font-color-disabled)' : 'var(--global-font-color-action)')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-weight: ${({ disabled }) => (disabled ? '400' : '500')};

  path {
    stroke: ${({ disabled }) => (disabled ? 'var(--global-font-color-disabled)' : 'var(--global-font-color-action)')};
  }

  &:hover {
    color: ${({ disabled }) => (disabled ? undefined : 'var(--global-font-color-hover)')};

    path {
      stroke: ${({ disabled }) => (disabled ? undefined : 'var(--global-font-color-hover)')};
    }
  }
`;

const HeaderText = styled.div`
  color: var(--modal-header-font-color);
  flex: 1;
  font-weight: 500;
  text-align: center;
`;

export const ModalStepHeader: FunctionComponent<ModalStepHeaderProps> = ({
  isDisabledNextButton,
  next,
  previous,
  title,
}) => {
  const stepFunction = (step: typeof next) => {
    if (!step) return undefined;
    return () => step();
  };

  return (
    <HeaderContainer>
      <PreviousStepContainer
        disabled={!Boolean(previous)}
        onClick={stepFunction(previous)}
      >
        {previous !== false && (
          <>
            {LeftArrowIcon}
            <div style={{ color: 'var(--modal-header-font-color' }}>Back</div>
          </>
        )}
      </PreviousStepContainer>
      <HeaderText>{title}</HeaderText>
      <NextStepContainer
        disabled={!Boolean(next) || isDisabledNextButton}
        onClick={isDisabledNextButton === false ? stepFunction(next) : undefined}
      >
        {next !== false && (
          <>
            <div>Next</div>
            {RightArrowIcon}
          </>
        )}
      </NextStepContainer>
    </HeaderContainer>
  );
};
