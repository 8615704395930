import Cookies, { CookieAttributes } from 'js-cookie';
import { config } from '../config';

export const setActiveAccountId = (accountId: number) => {
  localStorage.setItem(config.app.accountIdHeader, accountId.toString());
};

export const getActiveAccountId = () => {
  return localStorage.getItem(config.app.accountIdHeader);
};

export class BrowserStorage {
  static get(key: string) {
    return BrowserStorage.getCookie(key) || BrowserStorage.getLocalStorage(key);
  }

  static remove(key: string) {
    BrowserStorage.removeCookie(key);
    BrowserStorage.removeLocalStorage(key);
  }

  static set(key: string, value: string, options?: CookieAttributes) {
    BrowserStorage.setCookie(key, value, options);
    BrowserStorage.setLocalStorage(key, value);
  }

  static removeCookie(key: string) {
    setSafety(Cookies.remove)(key);
  }

  static removeLocalStorage(key: string) {
    const remove = (k: string) => localStorage.removeItem(k);
    setSafety(remove)(key);
  }

  static setCookie(key: string, value: string, options?: CookieAttributes) {
    setSafety(Cookies.set)(key, value, options);
  }

  static setLocalStorage(key: string, value: string) {
    const set = (k: string, v: string) => localStorage.setItem(k, v);
    setSafety(set)(key, value);
  }

  static getCookie(key: string): string | undefined {
    return getSafety(Cookies.get)(key);
  }

  static getLocalStorage(key: string): string | undefined {
    const get = (k: string) => localStorage.getItem(k);
    return getSafety(get)(key);
  }
}

const getSafety =
  (getFn: (...args: any[]) => any) =>
  (...args: any[]) => {
    let value: string | undefined;
    try {
      value = getFn(...args);
    } catch (error) {
      console.warn((error as Error).message);
    }
    return value;
  };

const setSafety =
  (setFn: (...args: any[]) => any) =>
  (...args: any[]) => {
    try {
      setFn(...args);
    } catch (error) {
      console.warn((error as Error).message);
    }
  };
