export function getElementCoordinates(element: HTMLElement): {
  bottom: number;
  left: number;
  right: number;
  top: number;
} {
  const rect = element.getBoundingClientRect();
  const top = rect.top + window.scrollY;
  const bottom = rect.bottom + window.scrollY;
  const right = rect.right + window.scrollX;
  const left = rect.left + window.scrollX;
  return { bottom, left, right, top };
}
