export const ShieldCheckIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M7.08337 9.58325L9.58337 12.0833L13.75 7.91658"
      stroke="#00CB9A"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16667 15.0001L2.63043 4.2464C2.55653 3.72912 2.89389 3.24588 3.40398 3.13253L9.56614 1.76316C9.85189 1.69966 10.1481 1.69966 10.4339 1.76316L16.596 3.13253C17.1061 3.24588 17.4435 3.72912 17.3696 4.2464L15.8333 15.0001C15.7744 15.4126 15.4167 17.9167 10 17.9167C4.58333 17.9167 4.22559 15.4126 4.16667 15.0001Z"
      stroke="#00CB9A"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
