import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { usePlacesLocation } from '../../../../queries/places.query';
import { Input } from '../../../ui/Input';
import { Button } from '../../../ui/button/Button';
// import { OpenLinkIcon } from '../../../icons/OpenLinkIcon';
// import { openUrlInNewTab } from '../../../../utils/url';
import { CheckmarkIcon } from '../../../icons/CheckmarkIcon';
import { LocalFalconLocationSelectDto } from '../../../../types/api.types';
import { useGetSubscriptionForAccount } from '../../../../queries/subscriptions.query';

type LocationSearchStepProps = {
  locationSearchQuery?: string;
  onClaimLocation: (location?: LocalFalconLocationSelectDto) => void;
  onProtectLocation: (location?: LocalFalconLocationSelectDto) => void;
  setLocationSearchQuery: (query?: string) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 16px;
`;

const Searchbar = styled.div`
  align-items: center;
  column-gap: 8px;
  display: flex;
`;

const ResultsContainer = styled.div`
  background: var(--global-components-bg-color);
  border: 1px solid var(--modal-border-color);
  border-radius: 8px;
  color: var(--global-font-color-data);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  height: 100%;
  overflow: hidden;
`;

const ResultsHeader = styled.div`
  border-bottom: 1px solid var(--modal-border-color);
  padding: 12px;
`;

const LocationsContainer = styled.div`
  overflow-y: auto;
`;

const LocationContainer = styled.div`
  align-items: center;
  background: var(--global-components-bg-color);
  border-top: 1px solid var(--modal-border-color);
  column-gap: 8px;
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;

  &:first-child {
    border-top: none;
  }
`;

const LocationDetails = styled.div`
  align-items: center;
  display: flex;
`;

const IconWrap = styled.div`
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 36px;
`;

const LocationAddress = styled.div`
  color: var(--global-font-color-text);
  font-size: 12px;
  font-weight: 400;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
`;

export const LocationSearchStep: FunctionComponent<LocationSearchStepProps> = (props) => {
  const { locationSearchQuery, onClaimLocation, onProtectLocation, setLocationSearchQuery } = props;

  const [input, setInput] = useState(locationSearchQuery ?? '');
  const [selectedLocation, setSelectedLocation] = useState<LocalFalconLocationSelectDto>();
  const { data: locations = [], isFetching } = usePlacesLocation(locationSearchQuery);
  const { data: subscription } = useGetSubscriptionForAccount();

  const disabled = input.trim().length === 0 || input.trim() === locationSearchQuery;

  const onSearch = () => {
    if (disabled || isFetching) return;

    const query = input.trim().length === 0 ? undefined : input.trim();
    if (!query || query === locationSearchQuery) return;

    setSelectedLocation(undefined);
    setLocationSearchQuery(query);
  };

  const onClickClaim = (location: LocalFalconLocationSelectDto) => {
    const newSelectedLocation = selectedLocation?.googlePlaceId === location.googlePlaceId ? undefined : location;
    onClaimLocation(newSelectedLocation);
  };

  const onClickProtect = (location: LocalFalconLocationSelectDto) => {
    const newSelectedLocation = selectedLocation?.googlePlaceId === location.googlePlaceId ? undefined : location;
    onProtectLocation(newSelectedLocation);
  };

  return (
    <Container>
      <Searchbar>
        <Input
          disabled={isFetching}
          onChange={(input) => setInput(input)}
          onEnterKey={onSearch}
          placeholder={'Business name or address'}
          value={input}
        ></Input>
        <Button
          disabled={disabled}
          isLoading={isFetching}
          onClick={onSearch}
        >
          Search
        </Button>
      </Searchbar>
      {locations.length > 0 && (
        <ResultsContainer>
          <ResultsHeader>Results</ResultsHeader>
          <LocationsContainer>
            {locations.map((location) => (
              <LocationContainer key={location.googlePlaceId}>
                <LocationDetails>
                  {selectedLocation?.googlePlaceId === location.googlePlaceId && <IconWrap>{CheckmarkIcon}</IconWrap>}
                  <div>
                    <div>{location.name}</div>
                    <LocationAddress>{location.address}</LocationAddress>
                  </div>
                </LocationDetails>
                <ButtonContainer>
                  <Button
                    onClick={() => onClickClaim(location)}
                    style="clear"
                  >
                    Claim
                  </Button>
                  <Button
                    disabled={!subscription || subscription.isFull}
                    onClick={() => onClickProtect(location)}
                    style="clear"
                  >
                    Protect
                  </Button>
                </ButtonContainer>
              </LocationContainer>
            ))}
          </LocationsContainer>
        </ResultsContainer>
      )}
    </Container>
  );
};
