import { IonContent, IonList, IonPopover } from '@ionic/react';
import { FunctionComponent, PropsWithChildren } from 'react';
import styled from 'styled-components';

export const PopoverList = styled(IonList)`
  padding: 5px;
`;

export const PopoverContainer = styled(IonPopover)`
  --offset-y: 5px;
`;

export type DropdownProps = {
  trigger: React.ReactNode;
  triggerId: string;
};

export const Popover: FunctionComponent<PropsWithChildren<DropdownProps>> = (props) => {
  const { children, trigger, triggerId } = props;

  return (
    <>
      {trigger}
      <PopoverContainer
        trigger={triggerId}
        dismissOnSelect
        showBackdrop={false}
      >
        <IonContent>{children}</IonContent>
      </PopoverContainer>
    </>
  );
};
