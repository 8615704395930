import { IonSkeletonText } from '@ionic/react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { formatUsd } from '../../utils/money';
import { ConditionalLoading } from '../ui/containers';
import { IncrementalInput } from '../ui/input/incremental';
import { AddOnSelectDto } from '../../types/api.types';

const CardContainer = styled.div`
  background-color: var(--subscriptions-bg-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Table = styled(CardContainer)`
  background-color: var(--subscriptions-bg-color);
  font-size: 14px;
  margin: 20px 0 0;
`;

const TableRow = styled.div`
  align-items: center;
  background: var(--subscriptions-bg-color);
  border-bottom: 1px solid var(--subscriptions-border-color);
  color: var(--subscriptions-data-font-color);
  display: flex;
  height: 44px;
  margin: 0 10px;
  padding: 0 10px;

  &:nth-child(1) {
    background: var(--table-header-bg-color);
    color: var(--subscriptions-title-font-color);
    margin: 0;
    padding: 0 20px;
    height: 40px;
  }

  &:nth-child(5) {
    border-bottom: none;
    height: 60px;
  }
`;

const TableHeader = styled(TableRow)`
  font-weight: 600;
`;

const TableCell = styled.div`
  &:nth-child(1) {
    width: 40%;
  }
  &:nth-child(2) {
    text-align: right;
    width: 15%;
  }
  &:nth-child(3) {
    text-align: center;
    width: 30%;
  }
  &:nth-child(4) {
    text-align: right;
    width: 15%;
  }
`;

const TotalCell = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const TotalRow = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
const QuantityContainer = styled.div`
  align-items: center;
  column-gap: 15px;
  display: flex;
  justify-content: center;
`;

const TotalValueContainer = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  padding: 0 0 0 9px;
  width: 100%;
`;

const TotalValue = styled.div`
  font-weight: 400;
`;

const TotalValueDisclaimer = styled.div`
  color: var(--subscriptions-disclaimer-font-color);
  font-size: 10px;
  font-style: italic;
`;

const IonRowSkeleton = styled(IonSkeletonText)`
  height: 20px;
`;

export const RowSkeleton: FunctionComponent = () => (
  <TableRow>
    <IonRowSkeleton animated />
  </TableRow>
);

export const CellSkeleton: FunctionComponent = () => {
  return (
    <TableCell>
      <IonRowSkeleton animated />
    </TableCell>
  );
};

export type SubscriptionOptions = Omit<AddOnSelectDto, 'status'> & { minValue?: number };

export type SubscriptionOptionsTableProps = {
  isLoading?: boolean;
  onChangeQuantity: (addOnId: number | string) => (value: number) => void;
  quantities: Record<number, number>;
  subscriptionOptions: SubscriptionOptions[];
  total: number;
};

export const SubscriptionOptionsTable: FunctionComponent<SubscriptionOptionsTableProps> = (props) => {
  const { isLoading, onChangeQuantity, quantities, subscriptionOptions, total } = props;
  return (
    <Table>
      <TableHeader>
        <TableCell>Item</TableCell>
        <TableCell>Price Per</TableCell>
        <TableCell>Qty</TableCell>
        <TableCell>Total</TableCell>
      </TableHeader>
      {subscriptionOptions.map(({ id, minValue = 0, name, pricePerMonth }, index) => {
        const quantity = quantities[id] ?? 0;

        return (
          <ConditionalLoading
            key={index}
            isLoading={isLoading}
            loadingComponent={<RowSkeleton />}
          >
            <TableRow key={id}>
              <TableCell>{name}</TableCell>
              <TableCell>{formatUsd(pricePerMonth)}</TableCell>
              <TableCell>
                <QuantityContainer>
                  <IncrementalInput
                    minValue={minValue}
                    onChange={(value) => onChangeQuantity(id)(value)}
                    value={quantity}
                  />
                </QuantityContainer>
              </TableCell>
              <TableCell>{formatUsd(pricePerMonth * quantity)}</TableCell>
            </TableRow>
          </ConditionalLoading>
        );
      })}

      <TableRow>
        <TotalRow>
          <ConditionalLoading
            isLoading={isLoading}
            loadingComponent={<CellSkeleton />}
          >
            <TotalCell>
              <TotalValueContainer>
                <div>Total</div>
                <TotalValue>{formatUsd(total)}</TotalValue>
              </TotalValueContainer>
              <TotalValueDisclaimer>(excluding local taxes)</TotalValueDisclaimer>
            </TotalCell>
          </ConditionalLoading>
        </TotalRow>
      </TableRow>
    </Table>
  );
};
