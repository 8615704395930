import { IonGrid, IonItem, IonRow } from '@ionic/react';
import { FunctionComponent, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

export const Row = styled.div<{ alignItems?: string; gap?: number }>`
  ${({ alignItems }) =>
    alignItems
      ? css`
          align-items: ${alignItems};
        `
      : ''}
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => gap ?? 0}px;
  min-width: 0;
  overflow: hidden;
  width: 100%;
`;

export const JustifyEndRow = styled(Row)`
  justify-content: end;
`;

export const Col = styled(Row)`
  display: flex;
  flex-direction: column;
`;

export const CenteredCol = styled(Col)`
  align-items: center;
  justify-content: center;
`;

export const SpaceBetweenCol = styled(Col)`
  justify-content: space-between;
`;

export const SpaceBetweenRow = styled(IonRow)`
  align-items: center;
  justify-content: space-between;
`;

export const SpaceBetweenContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const CenteredIonRow = styled(IonRow)`
  align-items: center;
`;

export const CenteredRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const NoPaddingXItem = styled(IonItem)`
  --ion-item-background: transparent;
  --padding-start: 0px;
  padding-left: 0px;
  padding-right: 0px;
`;

export const ConditionalLoading: FunctionComponent<
  PropsWithChildren<{
    isLoading?: boolean;
    loadingComponent: React.ReactNode;
  }>
> = (props) => {
  const { children, isLoading, loadingComponent } = props;

  if (isLoading) {
    return <>{loadingComponent}</>;
  }

  return <>{children}</>;
};

export const Grid = styled(IonGrid)`
  margin: 0 -10px;
`;
