import { IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { reorderThreeOutline } from 'ionicons/icons';
import { FunctionComponent, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useDrawer } from '../../../hooks/app';
import { BreadcrumbRoute, Breadcrumbs } from '../../Breadcrumbs';
import { Brand } from '../../brand';
import { UserAccountMenu } from '../../menu/user-account';
import { Button } from '../button/Button';
import { SpaceBetweenContainer } from '../containers';
import { GraphicContainer, Item, ItemLabel, TailContainer } from '../items';

export type PageLayoutProps = {
  actions?: React.ReactNode;
  breadcrumbRoutes?: BreadcrumbRoute[];
  title: React.ReactNode;
};

const PageIonHeader = styled(IonHeader)`
  --ion-toolbar-background: var(--subscriptions-table-header-bg-color);
  border-color: transparent !important;
`;

const PageIonContent = styled(IonContent)`
  --ion-background-color: var(--main-canvas-bg-color);
  --padding-bottom: 24px !important;
  --padding-top: 24px !important;
`;

const WrapPageContent = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
`;

const CustomUserAccountMenu = styled(UserAccountMenu)`
  --background: transparent;
  --background-hover: transparent;
  margin-right: 10px;

  ${ItemLabel},${TailContainer} {
    display: none;
  }
`;

const MenuIcon = styled(IonIcon)`
  font-size: 48px;
`;

const MobileHeader = styled(SpaceBetweenContainer)`
  padding: 0 10px;
  ${Item} {
    margin: 0 auto;
    padding: 0;
  }

  ${GraphicContainer} {
    height: 30px;
    width: auto;
  }
`;

export const PageLayout: FunctionComponent<PropsWithChildren<PageLayoutProps>> = (props) => {
  const { actions, breadcrumbRoutes, children, title } = props;
  const { openDrawer } = useDrawer();
  return (
    <IonPage>
      <PageIonHeader>
        <IonToolbar>
          <MobileHeader className="ion-hide-lg-up">
            <Button
              onClick={() => openDrawer()}
              style="clear"
            >
              <MenuIcon
                icon={reorderThreeOutline}
                size="large"
              />
            </Button>
            <Brand />
            <CustomUserAccountMenu toggleDrawer={false} />
          </MobileHeader>
          <IonTitle className="ion-hide-lg-down">
            <SpaceBetweenContainer>
              {breadcrumbRoutes ? <Breadcrumbs routes={breadcrumbRoutes} /> : title}
              {actions}
            </SpaceBetweenContainer>
          </IonTitle>
        </IonToolbar>
      </PageIonHeader>
      <PageIonContent
        fullscreen
        scrollY={false}
      >
        <WrapPageContent>
          {breadcrumbRoutes && (
            <Breadcrumbs
              mobileOnly={true}
              routes={breadcrumbRoutes}
            />
          )}
          {children}
        </WrapPageContent>
      </PageIonContent>
    </IonPage>
  );
};
