import { AccountSettings } from '@aws-amplify/ui-react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { Button } from '../components/ui/button/Button';
import { Card } from '../components/ui/card';
import { JustifyEndRow } from '../components/ui/containers';
import { PageLayout } from '../components/ui/layouts/page';
import { ThemeSelector } from '../components/user-settings/ThemeSelector';
import { showLoadingSpinnerState, toastMessageState } from '../states/app';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0;
  width: 50%;

  label,
  input,
  span {
    color: var(--global-title-color);
  }

  input {
    background-color: var(--input-bg-color);
    border-color: var(--input-border-color);
    :focus {
      border-color: var(--input-border-color-focus);
    }
  }

  .amplify-field-group {
    button {
      background-color: var(--input-bg-color);
      border-color: var(--input-border-color);
    }
  }

  .amplify-text.amplify-text--error {
    color: var(--global-color-danger);
  }

  .amplify-alert--error {
    background-color: var(--global-color-danger);
    border-radius: 10px;

    .amplify-alert__icon,
    .amplify-alert__body {
      color: #fff;
    }
  }
`;

export const SettingsPage: React.FC = () => {
  const [, setShowLoadingSpinnerState] = useRecoilState(showLoadingSpinnerState);
  const [, setToastMessageState] = useRecoilState(toastMessageState);

  const onPasswordChangeError = () => {
    setShowLoadingSpinnerState(false);
  };

  const onPasswordChangeSuccess = () => {
    setShowLoadingSpinnerState(false);
    setToastMessageState('Password changed successfully');
    setTimeout(() => {
      setToastMessageState(undefined);
    }, 3000);
  };

  return (
    <PageLayout title="User settings">
      <Container>
        <Card title="Change Password">
          <AccountSettings.ChangePassword
            onError={onPasswordChangeError}
            onSuccess={onPasswordChangeSuccess}
            components={{
              SubmitButton: () => (
                <JustifyEndRow>
                  <Button>Change Password</Button>
                </JustifyEndRow>
              ),
            }}
          />
        </Card>
        <Card title="Theme">
          <ThemeSelector />
        </Card>
      </Container>
    </PageLayout>
  );
};
