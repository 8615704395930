import { IonLabel, IonRadioGroup } from '@ionic/react';
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useApp } from '../../context/app';
import { Theme, themeLabels } from '../../types/common';
import { Radio } from '../ui/Radio';
import { NoPaddingXItem } from '../ui/containers';

const Label = styled(IonLabel)`
  --color: var(--global-title-color) !important;
  margin-left: 20px;
`;

export const ThemeSelector: FunctionComponent = () => {
  const { enableTheme, getActiveTheme } = useApp();
  const [theme, setTheme] = useState(getActiveTheme);

  function onChangeTheme(theme: Theme) {
    setTheme(theme);
    enableTheme(theme);
  }

  return (
    <IonRadioGroup
      value={theme}
      onIonChange={(event) => onChangeTheme(event.target.value)}
    >
      {Object.entries(themeLabels).map(([key, value]) => (
        <NoPaddingXItem
          lines="none"
          key={key}
        >
          <Radio value={key} />
          <Label>{value}</Label>
        </NoPaddingXItem>
      ))}
    </IonRadioGroup>
  );
};
