import { IonLabel } from '@ionic/react';
import { PendingInvites } from '../components/invites';
import { NoPaddingXItem } from '../components/ui/containers';
import { PageLayout } from '../components/ui/layouts/page';

export const InvitesPage: React.FC = () => {
  return (
    <PageLayout
      breadcrumbRoutes={[{ name: 'Pending Invites' }]}
      title="Invites"
    >
      <NoPaddingXItem lines="full">
        <IonLabel>
          <h1>Invites</h1>
        </IonLabel>
      </NoPaddingXItem>
      <PendingInvites />
    </PageLayout>
  );
};
