import { useQuery } from '@tanstack/react-query';
import { useGetActiveAccount } from '../hooks/accounts';
import { ApiErrors, httpGet } from '../utils/api-client';
import { DashboardSelectDto } from '../types/api.types';
import { queryKeys } from './keys';

const initialData = (): DashboardSelectDto => ({
  competitorsKeywordsChanged: 0,
  competitorsMonitored: 0,
  competitorsRemoved: 0,
});

export function useGetDashboard() {
  const account = useGetActiveAccount();
  return useQuery<DashboardSelectDto, ApiErrors>({
    enabled: Boolean(account?.id),
    initialData,
    queryFn: async () => httpGet({ path: '/dashboard' }),
    queryKey: queryKeys.dashboard.getDashboard,
    refetchOnMount: true,
  });
}
