import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { useGetCompetitorsForLicense, useGetKeywordsStatsForLicense } from '../../queries/licenses.query';
import { FullTable, TableCell } from '../ui/table';

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

// const ManageButton = styled.span`
//   color: var(--license-cta-color-link);
//   cursor: pointer;
//   font-size: 14px;
//   text-decoration: underline;
// `;

export const KeywordsStatsTable: FunctionComponent<{ licenseId: number }> = (params) => {
  const { data: competitors = [] } = useGetCompetitorsForLicense(params.licenseId);
  const { data: keywords = [] } = useGetKeywordsStatsForLicense(params.licenseId);

  const keyKeywordCount = (text: string): number => {
    return competitors.filter((competitor) => competitor.keywords.some((keyword) => keyword.text === text)).length;
  };

  const headers = [
    {
      dataKey: 'keyword',
      key: 'keyword-stats-keyword',
      name: 'Keyword',
    },
    {
      dataKey: 'competitors',
      key: 'keyword-stats-competitors',
      name: 'Competitors',
    },
  ];

  return (
    <>
      <TitleWrapper>
        <Title>Location keywords</Title>
        {/* <ManageButton>Manage</ManageButton> */}
      </TitleWrapper>
      <FullTable
        header={headers}
        items={keywords.map(({ keyword }) => ({
          content: (
            <>
              <TableCell cellAlign="flex-start">{keyword}</TableCell>
              <TableCell cellAlign="flex-end">{keyKeywordCount(keyword)}</TableCell>
            </>
          ),
          id: keyword,
        }))}
      />
    </>
  );
};
