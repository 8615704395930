import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FlattenKeys } from '../../../types/common';
import { StyledElement } from '../../../types/styles.types';
import { CenteredCol, Row } from '../containers';
import { ChevronLeftIcon } from '../../icons/ChevronLeftIcon';
import { ChevronRightIcon } from '../../icons/ChevronRightIcon';
import { FilterArrowIcon } from '../../icons/FilterArrowIcon';
import { Dropdown } from '../dropdown';

export type TableCellProps = {
  cellAlign?: 'center' | 'flex-start' | 'flex-end';
  cursor?: string;
  flex?: string | number;
  textAlign?: 'center' | 'end' | 'start';
};

export type headersType = {
  cellAlign?: any;
  dataKey: string;
  filterOptions?: any[];
  flex?: number;
  hasFilter?: boolean;
  hasSort?: boolean;
  key: string;
  name: string;
  setValueFilter?: any;
  valueFilter?: any;
};

export type TableColumn<T extends Record<string, any>> = {
  cell?: TableCellProps;
  dataKey?: FlattenKeys<T>;
  header?: TableCellProps;
  renderValue?: (value: any) => React.ReactElement;
  title: string;
} & TableCellProps;

export type DataTableProps<T extends Record<string, any>> = {
  columns: TableColumn<T>[];
  data: T[];
};

export type TitleCellPros = {
  subtitle?: string;
  title: string;
};

export type TableElement = {
  content: React.ReactNode;
  id: string | number;
  onClick?: () => void;
  tip?: string;
};

export type TableProps = {
  emptyContent?: React.ReactNode;
  handleItemsToShow?: (value: number) => void;
  handleSort?: (column: string) => void;
  hasPagination?: boolean;
  hasTips?: boolean;
  header?: headersType[];
  items: TableElement[];
  itemsToShow?: number;
  sortColumn?: string | null;
  sortDirection?: string | null;
} & StyledElement;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const TableContainer = styled.div`
  --ion-item-background: transparent;
  background-color: var(--table-content-bg-color);
  border: 2px solid var(--table-border-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
`;

const TableHeaderContainer = styled.div`
  background-color: var(--table-header-bg-color);
  border-bottom: solid 1px var(--table-separator-content-color);
`;

const TableContent = styled.div`
  background-color: var(--table-content-bg-color);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
`;

const EmptyContainer = styled(CenteredCol)`
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  min-height: 188px;
`;

const TableTip = styled.div`
  background-color: var(--license-tooltip-bg-color);
  border: 1px solid var(--table-separator-content-color);
  border-radius: 4px;
  color: var(--license-tooltip-font-color);
  font-size: 12px;
  font-weight: 500;
  left: -220px;
  padding: 5px 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  width: 200px;
  z-index: 10;
  &::after {
    border-color: transparent transparent transparent var(--license-tooltip-bg-color);
    border-style: solid;
    border-width: 5px 0 5px 10px;
    content: '';
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const TableRow = styled.div<{ showCursor?: boolean }>`
  border-bottom: 1px solid var(--table-separator-content-color);
  cursor: ${({ showCursor }) => (showCursor ? 'pointer' : 'inherit')};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  margin: 0 10px;
  padding: 0 10px;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    & ${TableTip} {
      visibility: visible;
    }
  }
`;

const PaginatorConatiner = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TotalText = styled.span`
  font-size: 14px;
`;

const Pagination = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  margin: 10px 0;
`;

const IconContainer = styled.div`
  padding: 10px;
  svg {
    stroke: var(--table-pagination-number-color);
  }
`;

const PageNumberContainer = styled.div<{ isActive?: boolean }>`
  align-items: center;
  background-color: var(--table-pagination-number-bg-color);
  border: 1px solid
    ${({ isActive }) =>
      isActive ? 'var(--table-pagination-number-active-border-color)' : 'var(--table-pagination-number-border-color)'};
  border-radius: 4px;
  color: ${({ isActive }) =>
    isActive ? 'var(--table-pagination-number-active-color)' : 'var(--table-pagination-number-color)'};
  display: flex;
  font-size: 14px;
  height: 30px;
  justify-content: center;
  width: 30px;
`;

const Points = styled.span`
  color: var(--table-pagination-number-color);
  font-size: 14px;
`;

export const TableCell = styled.div<TableCellProps>`
  align-items: center;
  color: var(--table-content-font-color);
  cursor: ${({ cursor }) => cursor ?? 'inherit'};
  display: flex;
  flex: ${({ flex }) => flex ?? '1'};
  justify-content: ${({ cellAlign }) => cellAlign ?? 'center'};
  min-width: 0;
  overflow: hidden;
  padding: 10px 0;
  text-align: ${({ textAlign }) => textAlign ?? 'inherit'};
`;

export const TableHeader = styled(TableCell)`
  color: var(--table-header-font-color);
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
`;

const FilterContainer = styled.div`
  cursor: pointer;
`;

const Arrow = styled.div<{ isActive?: boolean; isRotated?: boolean }>`
  align-items: center;
  height: 14px;
  rotate: ${({ isRotated }) => (isRotated ? '180deg' : '0deg')};
  width: 14px;
  svg {
    color: ${({ isActive }) =>
      isActive ? 'var(--table-pagination-number-active-color)' : 'var(--table-pagination-number-color)'};
  }
`;

const ArrowFilters = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
`;

const OptionContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  max-width: 150px;
`;

const TableTitleContainer = styled.div`
  display: flex;
  flex-direction: column !important;
  gap: 2px;
  width: 100%;
`;

export const TableTitle = styled.span<{ cellWidth?: number }>`
  font-size: 16px;
  max-width: ${({ cellWidth }) => (cellWidth && cellWidth < 238 ? '238px' : 'initial')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const SubtitleTitle = styled.span<{ cellWidth?: number }>`
  color: var(--global-text-alternative-color);
  font-size: 12px;
  max-width: ${({ cellWidth }) => (cellWidth && cellWidth < 238 ? '238px' : 'initital')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const TitleCell: FunctionComponent<TitleCellPros> = (props) => {
  const { subtitle, title } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
    }
  }, []);

  return (
    <Row ref={containerRef}>
      <TableTitleContainer>
        <TableTitle cellWidth={width}>{title}</TableTitle>
        {subtitle && <SubtitleTitle cellWidth={width}>{subtitle}</SubtitleTitle>}
      </TableTitleContainer>
    </Row>
  );
};

export const EmptyTableContent = () => {
  return (
    <CenteredCol>
      <span>You don't have any data yet.</span>
    </CenteredCol>
  );
};

export const TableLink = styled.span`
  color: var(--table-header-font-color);
  cursor: pointer;
`;

const RANGE_SIZE = 8;

export const Table: FunctionComponent<TableProps> = (params) => {
  const {
    className,
    emptyContent,
    handleItemsToShow,
    handleSort,
    hasPagination,
    hasTips = false,
    header,
    items,
    itemsToShow,
    sortColumn,
    sortDirection,
  } = params;

  const [page, setPage] = useState(1);

  const totalPages = Math.ceil(items.length / (itemsToShow ?? 1));
  const lastPageNumber = Math.max(1, totalPages);

  const handleNextPage = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const handlePrevPage = useCallback(() => {
    setPage(page - 1);
  }, [page]);

  const getItemsToRender = useCallback(() => {
    const start = (page - 1) * (itemsToShow ?? 1);
    const end = start + (itemsToShow ?? 1);
    return items.slice(start, end);
  }, [items, itemsToShow, page]);

  const itemsToRender = hasPagination ? getItemsToRender() : items;

  const calculatePageRange = (currentPage: number, totalPages: number): number[] => {
    const rangeStart = Math.max(2, Math.min(totalPages - RANGE_SIZE + 1, currentPage - Math.floor(RANGE_SIZE / 2)));

    const rangeEnd = Math.min(totalPages - 1, rangeStart + RANGE_SIZE - 1);

    const pageRange: number[] = [];
    for (let i = rangeStart; i <= rangeEnd; i++) {
      pageRange.push(i);
    }

    return pageRange;
  };

  return (
    <TableWrapper>
      <TableContainer className={className}>
        {header && (
          <TableHeaderContainer>
            <TableRow>
              {header.map(
                ({
                  cellAlign,
                  dataKey,
                  filterOptions,
                  flex,
                  hasFilter,
                  hasSort,
                  key,
                  name,
                  setValueFilter,
                  valueFilter,
                }) => (
                  <TableHeader
                    key={key}
                    flex={flex}
                    cellAlign={cellAlign}
                  >
                    <HeaderTitle>
                      {name}
                      {hasSort && handleSort && (
                        <OptionContainer>
                          <FilterContainer onClick={() => handleSort(dataKey)}>
                            <ArrowFilters>
                              <Arrow
                                isActive={sortColumn === dataKey && sortDirection === 'asc'}
                                onClick={() => handleSort(dataKey)}
                              >
                                {FilterArrowIcon}
                              </Arrow>
                              <Arrow
                                isActive={sortColumn === dataKey && sortDirection === 'desc'}
                                isRotated
                                onClick={() => handleSort(dataKey)}
                              >
                                {FilterArrowIcon}
                              </Arrow>
                            </ArrowFilters>
                          </FilterContainer>
                          {hasFilter && filterOptions && (
                            <Dropdown
                              items={filterOptions}
                              onChange={(value) => {
                                setValueFilter(value);
                              }}
                              value={valueFilter}
                            />
                          )}
                        </OptionContainer>
                      )}
                    </HeaderTitle>
                  </TableHeader>
                ),
              )}
            </TableRow>
          </TableHeaderContainer>
        )}
        <TableContent>
          {items.length > 0 ? (
            <>
              {itemsToRender.map(({ content, id, onClick, tip = '' }) => (
                <TableRow
                  showCursor={Boolean(onClick) || hasTips}
                  key={id}
                  onClick={() => onClick && onClick()}
                >
                  {content}
                  {hasTips && <TableTip id="tooltip">{tip}</TableTip>}
                </TableRow>
              ))}
            </>
          ) : (
            <EmptyContainer>{emptyContent ?? <EmptyTableContent />}</EmptyContainer>
          )}
        </TableContent>
      </TableContainer>
      {hasPagination && (
        <PaginatorConatiner>
          <TotalText>Total {items.length} items</TotalText>
          <Pagination>
            {page > 1 && (
              <TableLink onClick={handlePrevPage}>
                <IconContainer>{ChevronLeftIcon}</IconContainer>
              </TableLink>
            )}
            {totalPages > RANGE_SIZE ? (
              <>
                <TableLink onClick={() => setPage(1)}>
                  <PageNumberContainer isActive={page === 1}>{1}</PageNumberContainer>
                </TableLink>
                {page >= 7 && <Points>•••</Points>}
                {calculatePageRange(page, totalPages).map((pageNumber) => (
                  <TableLink
                    onClick={() => setPage(pageNumber)}
                    key={pageNumber}
                  >
                    <PageNumberContainer isActive={page === pageNumber}>{pageNumber}</PageNumberContainer>
                  </TableLink>
                ))}
                {page <= totalPages - 5 && <Points>•••</Points>}
                <TableLink onClick={() => setPage(lastPageNumber)}>
                  <PageNumberContainer isActive={page === lastPageNumber}>{lastPageNumber}</PageNumberContainer>
                </TableLink>
              </>
            ) : (
              Array.from({ length: totalPages }).map((_, index) => (
                <TableLink
                  onClick={() => setPage(index + 1)}
                  key={index}
                >
                  <PageNumberContainer isActive={page === index + 1}>{index + 1}</PageNumberContainer>
                </TableLink>
              ))
            )}
            {page < totalPages && (
              <TableLink onClick={handleNextPage}>
                <IconContainer>{ChevronRightIcon}</IconContainer>
              </TableLink>
            )}
            {handleItemsToShow && items.length > 5 && (
              <Dropdown
                items={[
                  { children: '5 per page', value: '5' },
                  { children: '10 per page', value: '10' },
                  { children: '15 per page', value: '15' },
                  { children: '20 per page', value: '20' },
                ]}
                onChange={(value) => {
                  handleItemsToShow && handleItemsToShow(Number(value));
                  setPage(1);
                }}
                value={itemsToShow?.toString()}
              />
            )}
          </Pagination>
        </PaginatorConatiner>
      )}
    </TableWrapper>
  );
};

export const FullTable = styled(Table)`
  flex: 1;
  height: 100%;
`;
