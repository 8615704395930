import { useIonRouter } from '@ionic/react';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useCreateAccountQuery, useGetAccounts, usePatchAccountQuery } from '../queries/accounts.query';
import { queryClient } from '../queries/client';
import { queryKeys } from '../queries/keys';
import { useGetCurrentUserForAccount } from '../queries/users.query';
import { routes } from '../routes';
import { currentAccountState } from '../states/account';
import { AccountWithUser } from '../types/account.types';
import { getActiveAccountId, setActiveAccountId } from '../utils/storage';
import { AccountSelectDto } from '../types/api.types';
import { useLoading } from './loading';
import { useNotification } from './notification';
import { useSplash } from './splash';

export function useGetActiveAccount() {
  const { data: accounts = [] } = useGetAccounts();
  const { activeAccount, setActiveAccount } = useSetActiveAccount();
  const accountId = getActiveAccountId();

  useEffect(() => {
    if (!activeAccount && accounts.length > 0) {
      const account = accounts.find(({ id }) => id.toString() === accountId) ?? accounts[0];
      setActiveAccount(account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  return activeAccount;
}

export function useSetActiveAccount() {
  const { mutateAsync } = useGetCurrentUserForAccount();
  const [activeAccount, setCurrentAccount] = useRecoilState(currentAccountState);

  const { startLoading, stopLoading } = useSplash();

  const setActiveAccount = async (account: AccountSelectDto) => {
    startLoading();
    const user = await mutateAsync(account.id);
    setCurrentAccount({
      ...account,
      user,
    });
    setActiveAccountId(account.id);
    stopLoading();
  };

  return {
    activeAccount,
    setActiveAccount,
    setActiveAccountId,
  };
}

export function useCreateAccount() {
  const { setActiveAccount } = useSetActiveAccount();
  const notify = useNotification();
  const { mutateAsync: createAccount } = useCreateAccountQuery();

  const onCreateAccount = async (params: { name: string }) => {
    const account = await createAccount(params);
    if (account) setActiveAccount(account);

    notify(account ? 'Account created successfully' : 'Account creation failed');
  };

  return onCreateAccount;
}

export function usePatchAccount() {
  const { startLoading, stopLoading } = useLoading();
  const [, setCurrentAccount] = useRecoilState(currentAccountState);
  const notify = useNotification();
  const { mutateAsync: patchAccount } = usePatchAccountQuery();

  const onPatchAccount = async (params: { name: string }) => {
    startLoading();
    try {
      const { name } = params;
      const accountPatch = await patchAccount(
        { name },
        {
          onError: () => notify('Account update failed'),
          onSuccess: () => notify('Account updated successfully'),
        },
      );
      setCurrentAccount((activeAccount) => ({
        ...activeAccount,
        ...(accountPatch as AccountWithUser),
      }));
    } catch (error) {}
    stopLoading();
  };

  return onPatchAccount;
}

export function useSwitchAccount() {
  const { activeAccount, setActiveAccount } = useSetActiveAccount();
  const router = useIonRouter();

  function switchAccount(account: AccountSelectDto) {
    setActiveAccount(account);
    router.push(routes.dashboard.href);
  }

  return [activeAccount, switchAccount] as const;
}

export function useSwitchToInvitedAccount() {
  const { activeAccount, setActiveAccountId } = useSetActiveAccount();
  const router = useIonRouter();

  function switchAccount(account: AccountSelectDto) {
    setActiveAccountId(account.id);
    queryClient.invalidateQueries(queryKeys.accounts.getAccounts);
    router.push(routes.dashboard.href);
  }

  return [activeAccount, switchAccount] as const;
}
