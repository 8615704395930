export function getValueFromKey<R = any>(data: Record<string, any>, key: string) {
  let value: R | Record<string, any> = data;

  for (const path of key.split('.')) {
    value = value[path];
  }

  return value as R;
}

export function isEqualObjectsByKeys(objectA: Record<string, any>, objectB: Record<string, any>) {
  return Object.entries(objectA).every(([key, value]) => value === objectB[key]);
}
