import { ToastOptions, useIonToast } from '@ionic/react';
import { HookOverlayOptions } from '@ionic/react/dist/types/hooks/HookOverlayOptions';

export function useNotification() {
  const [present] = useIonToast();

  const notify = (message: string, options?: ToastOptions & HookOverlayOptions) => {
    present({
      duration: 3000,
      message: message,
      position: 'bottom',
      ...options,
    });
  };

  return notify;
}
