import { IonContent, IonModal } from '@ionic/react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

export type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  longContent?: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const CustomIonModal = styled(IonModal)<{ longContent: boolean }>`
  --backdrop-opacity: var(--modal-backdrop-opacity);
  --border-radius: 16px;
  --max-height: ${({ longContent }) => (longContent ? '740px' : '460px')};
  --max-width: 550px;
`;

const CustomIonContent = styled(IonContent)`
  --background: var(--global-font-color-subtitle-two);
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Modal: FunctionComponent<ModalProps> = ({ children, isOpen, longContent = false, setIsOpen }) => {
  return (
    <CustomIonModal
      isOpen={isOpen}
      onDidDismiss={() => setIsOpen(false)}
      longContent={longContent}
    >
      <CustomIonContent>{children}</CustomIonContent>
    </CustomIonModal>
  );
};
