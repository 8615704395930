import { IonIcon, IonNote } from '@ionic/react';
import { addCircleOutline, closeCircleOutline } from 'ionicons/icons';
import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useValidate } from '../../hooks/validation';
import { useInviteEmail } from '../../queries/invites.query';
import { randomAlphaNumericString } from '../../utils/random';
import { capitalize } from '../../utils/text';
import { RolesDropdown } from '../account-users/roles-dropdown';
import { invitationPartialSchema, invitationsSchema } from '../account-users/schemas';
import { Input } from '../ui/Input';
import { Col, Row } from '../ui/containers';
import { TableCell } from '../ui/table';
import { Button } from '../ui/button/Button';
import { AccountUserUpdateDtoRoleEnum } from '../../types/api.types';
import { Invitation } from '../../types/invitation.types';

export type InviteProps = {
  invitation: Partial<Invitation>;
  onChangeEmail: (email: string) => void;
  onChangeRole: (role: AccountUserUpdateDtoRoleEnum) => void;
  onRemove: () => void;
};

const CustomModalFontColor = styled.div`
  color: var(--modal-font-color) !important;

  * {
    color: var(--modal-font-color) !important;
  }
`;

export const InviteRow: FunctionComponent<InviteProps> = (props) => {
  const { invitation, onChangeEmail, onChangeRole, onRemove } = props;
  const validate = useValidate();
  const errors = validate(invitationPartialSchema, invitation as any);

  return (
    <>
      <Row>
        <TableCell
          cellAlign="flex-start"
          flex={4}
        >
          <Input
            value={invitation?.email}
            placeholder="john@doe.com"
            onChange={(input) => onChangeEmail(input)}
          />
        </TableCell>
        <TableCell>
          <RolesDropdown
            value={invitation?.role ?? AccountUserUpdateDtoRoleEnum.MEMBER}
            onChange={(role) => onChangeRole(role as AccountUserUpdateDtoRoleEnum)}
            placeholder={capitalize(AccountUserUpdateDtoRoleEnum.MEMBER)}
          />
        </TableCell>
        <TableCell
          cellAlign="flex-end"
          flex={0.4}
        >
          <Button
            onClick={onRemove}
            style="clear"
          >
            <IonIcon
              size="small"
              icon={closeCircleOutline}
            />
          </Button>
        </TableCell>
      </Row>

      <Row>{errors?.get('email') && <IonNote color="danger">{capitalize(errors?.get('email')!)}</IonNote>}</Row>
    </>
  );
};

const initialInvitationsState = (invitations: number): Partial<Invitation>[] =>
  Array.from(Array(invitations), () => ({ id: randomAlphaNumericString(8) }));

export type InviteUsersRef = {
  sendInvitations: () => void;
};

export type InviteUsersProps = {
  onErrors: (hasErrors?: boolean) => void;
  onRef: (inviteUsersRef: InviteUsersRef) => void;
};

export const InviteUsers: FunctionComponent<InviteUsersProps> = (params) => {
  const { onErrors, onRef } = params;
  const validate = useValidate();
  const { mutate } = useInviteEmail();
  const [invitations, setInvitations] = useState<Partial<Invitation>[]>(initialInvitationsState(1));

  useEffect(() => {
    if (onRef) {
      onRef({ sendInvitations: onSendInvitations });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRef]);

  useEffect(() => {
    onErrors(Boolean(validate(invitationsSchema as any, invitations as any)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitations]);

  function addMember() {
    setInvitations((prev) => [...prev, {}]);
  }

  function onRemove(elementIndex: number) {
    setInvitations((prev) => prev.filter((_, index) => index !== elementIndex));
  }

  function onSendInvitations() {
    for (const { email, role } of invitations as Invitation[]) {
      mutate({ email, roles: [role] });
    }
    setInvitations(initialInvitationsState(1));
  }

  const updateInvitation = (index: number) => (key: keyof Invitation) => (value: any) => {
    const updatedInvitations = [...invitations];
    updatedInvitations[index] = {
      ...updatedInvitations[index],
      [key]: value,
    };
    setInvitations(updatedInvitations);
  };

  return (
    <Col>
      {/* <Row>
        <TableCell cellAlign="flex-start" flex={4}>
          Email Address:
        </TableCell>
        <TableCell>Role:</TableCell>
        <TableCell cellAlign="flex-end" flex={0.4} />
      </Row> */}
      {invitations.map((invitation, index) => (
        <CustomModalFontColor key={invitation?.id}>
          <InviteRow
            onRemove={() => onRemove(index)}
            invitation={invitation}
            onChangeEmail={updateInvitation(index)('email')}
            onChangeRole={updateInvitation(index)('role')}
          />
        </CustomModalFontColor>
      ))}
      <Row>
        <CustomModalFontColor>
          <Button
            onClick={addMember}
            style="clear"
          >
            <IonIcon
              size="small"
              icon={addCircleOutline}
            ></IonIcon>
            Add another
          </Button>
        </CustomModalFontColor>
      </Row>
    </Col>
  );
};
