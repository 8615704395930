import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { RightArrowIcon } from '../../../icons/RightArrowIcon';
import { showProtectionAreasStepState } from '../../../../states/license';

type ProtectionAreasStepProps = {
  onContinue: () => void;
};

const Container = styled.div`
  background: url('/assets/protection-area.png');
  background-repeat: no-repeat;
  background-size: 658px 412px;
  height: calc(100% + 32px);
  margin: -16px;
`;

const MessageContainer = styled.div`
  background: var(--global-components-bg-color);
  border: 1px solid #eaeaea;
  border-radius: 8px;
  bottom: 0;
  color: var(--global-font-color-data);
  font-size: 14px;
  font-weight: 400;
  left: 0;
  margin: 16px;
  position: absolute;
  width: calc(100% - 32px);
`;

const MessageTitle = styled.div`
  font-weight: 500;
  padding: 16px 20px 4px 20px;
`;

const MessageText = styled.div`
  color: var(--global-font-color-text);
  padding: 0 20px 12px 20px;
`;

const ContinueContainer = styled.div`
  align-items: center;
  background: var(--global-font-color-subtitle-two);
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
`;

const CheckboxContainer = styled.div`
  align-items: center;
  column-gap: 8px;
  display: flex;
`;

const ContinueTextContainer = styled.div`
  align-items: center;
  border: none;
  color: var(--global-font-color-action);
  column-gap: 4px;
  cursor: pointer;
  display: flex;
  font-size: 14px;

  path {
    stroke: var(--global-font-color-action);
  }

  &:hover {
    color: var(--global-font-color-hover);

    path {
      stroke: var(--global-font-color-hover);
    }
  }
`;

export const ProtectionAreasStep: FunctionComponent<ProtectionAreasStepProps> = ({ onContinue }) => {
  const [showProtectionAreasStep, setShowProtectionAreasStep] = useRecoilState(showProtectionAreasStepState);

  return (
    <Container>
      <MessageContainer>
        <MessageTitle>Understanding Protection Areas</MessageTitle>
        <MessageText>
          The protection area charts the area in which we’re scanning for your potential competitors.
        </MessageText>
        <ContinueContainer>
          <CheckboxContainer>
            <input
              checked={!showProtectionAreasStep}
              onChange={(event) => setShowProtectionAreasStep(!event.target.checked)}
              type="checkbox"
            />
            <div>Don’t show this again</div>
          </CheckboxContainer>
          <ContinueTextContainer onClick={onContinue}>
            <div>Continue</div>
            {RightArrowIcon}
          </ContinueTextContainer>
        </ContinueContainer>
      </MessageContainer>
    </Container>
  );
};
