import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import styled from 'styled-components';

import { IonCol, IonIcon, IonItem, IonRow } from '@ionic/react';
import { ellipsisHorizontal } from 'ionicons/icons';

import { BreadcrumbRoute } from '../components/Breadcrumbs';
import { Popover, PopoverList } from '../components/ui/Popover';
import { Button } from '../components/ui/button/Button';
import { Grid } from '../components/ui/containers';
import { PageLayout } from '../components/ui/layouts/page';
import { routes } from '../routes';
import { LicenseSelectDtoStatusEnum } from '../types/api.types';
import { useGetChangesForLicense } from '../queries/licenses.query';
import { ChangelogList } from '../components/changelog/changelog-list';
import { Filters } from '../components/changelog/filters';
import { useCancelProtection, useGetLocation, useProtectLocation } from '../queries/locations.query';
import { useAddLocationModal } from '../hooks/modal';
import { useGetSubscriptionForAccount } from '../queries/subscriptions.query';

interface Filters {
  competitorIds: number[];
  endDate: string;
  startDate: string;
}

const PrincipalColumnSection = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LicenseContainer = styled.div`
  margin: 0 0 40px;

  ion-col:first-child {
    padding-right: 24px;
  }
`;

export const ChangelogPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const locationId = Number(params.id);
  const locationParams = useLocation();
  const query = new URLSearchParams(locationParams.search);
  const competitorID = query.get('competitorID');

  const [filters, setFilters] = useState<Filters>({
    competitorIds: [],
    endDate: '',
    startDate: '',
  });

  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [page, setPage] = useState(1);

  const { mutateAsync: cancelProtection } = useCancelProtection();
  const { data: location } = useGetLocation(locationId);
  const { data: subscription } = useGetSubscriptionForAccount();
  const { modal, presentModal } = useAddLocationModal(subscription, location);
  const { mutateAsync: protectLocation } = useProtectLocation();

  const licenseId = location?.license?.id;

  const {
    data: changelogData,
    isLoading,
    refetch: getChangelog,
  } = useGetChangesForLicense({
    competitorIds: filters.competitorIds,
    licenseId,
    limit: itemsToShow,
    maxTimestamp: filters.endDate,
    minTimestamp: filters.startDate,
    page,
  });

  const breadcrumbRoutes: BreadcrumbRoute[] = [
    { href: routes.locations.href, name: 'My Locations' },
    { href: location ? routes.location.url!(location.id) : '', name: location?.name ?? '' },
    { name: 'Changelog' },
  ];

  const handleItemsToShow = useCallback((value: number) => {
    setItemsToShow(value);
    setPage(1);
  }, []);

  const onApplyfilters = useCallback(() => {
    setPage(1);
    getChangelog();
  }, [getChangelog]);

  useEffect(() => {
    if (licenseId) getChangelog();
  }, [licenseId, page, itemsToShow, getChangelog]);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  useEffect(() => {
    const query = new URLSearchParams(locationParams.search);
    const competitorID = query.get('competitorID');

    if (competitorID) {
      const newFilters = {
        ...filters,
        competitorIds: [Number(competitorID)],
      };
      setFilters(newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitorID]);

  return (
    <PageLayout
      breadcrumbRoutes={breadcrumbRoutes}
      title="License Changelog"
      actions={
        <Popover
          triggerId="changelog-actions"
          trigger={
            <Button
              id="changelog-actions"
              style="clear"
            >
              <IonIcon
                color="white"
                size="large"
                src={ellipsisHorizontal}
              />
            </Button>
          }
        >
          <PopoverList>
            {location?.license?.status === LicenseSelectDtoStatusEnum.ACTIVE && (
              <IonItem
                lines="none"
                button
                detail={false}
                onClick={() => cancelProtection(location.id)}
              >
                Cancel Protection
              </IonItem>
            )}

            {location?.license?.status === LicenseSelectDtoStatusEnum.INACTIVE && (
              <IonItem
                disabled={subscription?.isFull}
                lines="none"
                button
                detail={false}
                onClick={() =>
                  protectLocation({
                    address: location.address,
                    googleMapsUrl: location.googleMapsUrl,
                    googlePlaceId: location.googlePlaceId,
                    name: location.name,
                  })
                }
              >
                Protect Location
              </IonItem>
            )}

            {location && !location?.license && (
              <IonItem
                disabled={subscription?.isFull}
                lines="none"
                button
                detail={false}
                onClick={presentModal}
              >
                Add Protection
              </IonItem>
            )}
          </PopoverList>
        </Popover>
      }
    >
      {licenseId && changelogData && (
        <LicenseContainer>
          <Grid>
            <IonRow>
              <IonCol size="8">
                <PrincipalColumnSection>
                  <IonRow>
                    <ChangelogList
                      changelogData={changelogData}
                      handleItemsToShow={handleItemsToShow}
                      itemsToShow={itemsToShow}
                      page={page}
                      setPage={setPage}
                    />
                  </IonRow>
                </PrincipalColumnSection>
              </IonCol>
              <IonCol size="4">
                <PrincipalColumnSection>
                  <IonRow>
                    <Filters
                      filters={filters}
                      isLoading={isLoading}
                      licenseId={licenseId}
                      setFilters={setFilters}
                      onApplyfilters={onApplyfilters}
                    />
                  </IonRow>
                </PrincipalColumnSection>
              </IonCol>
            </IonRow>
          </Grid>
        </LicenseContainer>
      )}
      {modal}
    </PageLayout>
  );
};
