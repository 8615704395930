export const ShieldCrossIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M6.58084 9.91421L7.99505 8.5M9.40927 7.08579L7.99505 8.5M7.99505 8.5L6.58084 7.08579M7.99505 8.5L9.40927 9.91421"
      stroke="#3387FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33333 12.4999L2.13075 4.08181C2.05685 3.56453 2.39389 3.07906 2.90398 2.9657L7.56614 1.92967C7.85189 1.86616 8.14811 1.86616 8.43386 1.92967L13.096 2.9657C13.6061 3.07906 13.9432 3.56453 13.8693 4.08182L12.6667 12.4999C12.6195 12.8299 12.3333 14.8333 8 14.8333C3.66667 14.8333 3.38047 12.8299 3.33333 12.4999Z"
      stroke="#3387FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
