import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { MinusIcon } from '../../icons/MinusIcon';
import { PlusIcon } from '../../icons/PlusIcon';
import { SpaceBetweenRow } from '../containers';

const QuantityInput = styled.input`
  background-color: transparent;
  border: none;
  padding: 0 10px;
  text-align: center;
  width: 40px;
`;

export type IncrementalInputProps = {
  className?: string;
  minValue?: number;
  onChange: (value: number) => void;
  value: number;
};

export const IncrementalInput: FunctionComponent<IncrementalInputProps> = (props) => {
  const { className, minValue = 0, onChange, value } = props;

  const onIncrease = () => {
    onChange(value + 1);
  };
  const onDecrease = () => {
    onChange(Math.max(minValue, value - 1));
  };

  const onChangeInput = (inputValue: number) => {
    onChange(isNaN(inputValue) ? value : Math.max(minValue, inputValue));
  };

  return (
    <SpaceBetweenRow className={className}>
      <MinusIcon
        disabled={value === minValue}
        onClick={onDecrease}
      />
      <QuantityInput
        value={value}
        onChange={(value) => onChangeInput(Number(value.target.value))}
      />
      <PlusIcon onClick={onIncrease} />
    </SpaceBetweenRow>
  );
};
