export type ListItem<T extends Record<string, any> = Record<string, any>> = T & {
  id: string | number;
};

export enum Theme {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
  SYSTEM = 'SYSTEM',
}

export const themes: Theme[] = [Theme.SYSTEM, Theme.DARK, Theme.LIGHT];
export const themeLabels: Record<Theme, string> = {
  SYSTEM: 'System Default',
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  DARK: 'Dark mode',
  LIGHT: 'Light mode',
};

export type FlattenKeys<T, Prefix extends string = '', Separator extends string = '.'> = T extends object
  ?
      | {
          [K in keyof T]: T[K] extends any[]
            ? `${Prefix & string}${K & string}`
            : T[K] extends object
            ? FlattenKeys<T[K], `${Prefix & string}${K & string}${Separator & string}`>
            : `${Prefix & string}${K & string}`;
        }[keyof T]
      | keyof T
  : never;
