import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useNotification } from '../hooks/notification';
import { useIsCurrentUserAllowed } from '../hooks/users';
import { currentAccountState } from '../states/account';
import { ApiErrors, httpGet, httpPatch, httpPost } from '../utils/api-client';
import { AccountUserUpdateDtoRoleEnum, SubscriptionCreateDto, SubscriptionSelectDto } from '../types/api.types';
import { queryClient } from './client';
import { queryKeys } from './keys';

type GetSubscriptionsForAccountResponse = {
  subscriptions: SubscriptionSelectDto[];
};

export function useGetSubscriptionForAccount() {
  const activeAccount = useRecoilValue(currentAccountState);
  const isAllowed = useIsCurrentUserAllowed(AccountUserUpdateDtoRoleEnum.ADMIN);

  return useQuery<SubscriptionSelectDto | undefined, ApiErrors>({
    enabled: Boolean(activeAccount?.id) && isAllowed,
    queryFn: async () => {
      const { subscriptions } = await httpGet<GetSubscriptionsForAccountResponse>({
        path: '/subscriptions',
      });
      return subscriptions.length > 0 ? subscriptions[0] : undefined;
    },
    queryKey: queryKeys.subscriptions.getSubscriptionsForAccount(activeAccount?.id),
    refetchOnMount: true,
  });
}

export function useGetSubscriptionForAccountById(subscriptionId: number) {
  const activeAccount = useRecoilValue(currentAccountState);
  return useQuery({
    enabled: Boolean(activeAccount?.id),
    queryFn: () => httpGet<SubscriptionSelectDto>({ path: `/subscriptions/${subscriptionId}` }),
    queryKey: queryKeys.subscriptions.getSubscriptionForAccount(subscriptionId),
    refetchOnMount: true,
  });
}

export function useCancelSubscription() {
  const activeAccount = useRecoilValue(currentAccountState);
  const notify = useNotification();

  const onSuccess = (subscription: SubscriptionSelectDto) => {
    queryClient.invalidateQueries(queryKeys.subscriptions.getSubscriptionsForAccount(activeAccount?.id));
    queryClient.invalidateQueries(queryKeys.subscriptions.getSubscriptionForAccount(subscription.id));
  };
  return useMutation({
    mutationFn: (subscriptionId: number) =>
      httpPost<SubscriptionSelectDto>({
        path: `/subscriptions/${subscriptionId}/cancel`,
      }),
    onError: () => notify('Something went wrong please try again later.'),
    onSuccess: (data) => onSuccess(data),
  });
}

export function useCancelSubscriptionQuery(subscriptionId: number) {
  const activeAccount = useRecoilValue(currentAccountState);
  const notify = useNotification();

  const onSuccess = (subscription: SubscriptionSelectDto) => {
    queryClient.invalidateQueries(queryKeys.subscriptions.getSubscriptionsForAccount(activeAccount?.id));
    queryClient.invalidateQueries(queryKeys.subscriptions.getSubscriptionForAccount(subscription.id));
  };
  return useQuery({
    enabled: Boolean(activeAccount?.id),
    onError: () => notify('Something went wrong please try again later.'),
    onSuccess,
    queryFn: () =>
      httpPost<SubscriptionSelectDto>({
        path: `/subscriptions/${subscriptionId}/cancel`,
      }),
    queryKey: queryKeys.subscriptions.finalizeSubscriptionForAccount(subscriptionId),
  });
}

export function useCreateSubscription() {
  const activeAccount = useRecoilValue(currentAccountState);
  const onSuccess = () => {
    queryClient.invalidateQueries(queryKeys.subscriptions.getSubscriptionsForAccount(activeAccount?.id));
  };
  return useMutation({
    mutationFn: (body: SubscriptionCreateDto) => httpPost<{ redirectUrl: string }>({ body, path: '/subscriptions' }),
    onSuccess,
  });
}

export function useUpdateSubscription(subscriptionId?: number) {
  const activeAccount = useRecoilValue(currentAccountState);
  const onSuccess = () => {
    queryClient.invalidateQueries(queryKeys.subscriptions.getSubscriptionsForAccount(activeAccount?.id));
  };
  return useMutation({
    mutationFn: (body: SubscriptionCreateDto) => httpPatch({ body, path: `/subscriptions/${subscriptionId}` }),
    onSuccess,
  });
}
