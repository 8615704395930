export enum DrawerState {
  CLOSE = 'CLOSE',
  COLLAPSED = 'COLLAPSED',
  OPEN = 'OPEN',
}

export const sideMenuWidthCSSVar: Record<DrawerState, string> = {
  CLOSE: '--side-menu-width-close',
  COLLAPSED: '--side-menu-width-collapsed',
  OPEN: '--side-menu-width-open',
};

export const sideMenuDropdownItemCSSVar: Record<DrawerState, string> = {
  CLOSE: '',
  COLLAPSED: '--side-menu-dropdown-item-width-collapsed',
  OPEN: '--side-menu-dropdown-item-width-open',
};

export type BreadcrumbRoute = { name: string; path?: string };
