import styled from 'styled-components';
import { useEffect } from 'react';
import { AccountUsers } from '../components/account-users';
import { Button } from '../components/ui/button/Button';
import { Col, SpaceBetweenRow } from '../components/ui/containers';
import { PageLayout } from '../components/ui/layouts/page';
import { useInviteUsersModal } from '../hooks/modal';
import { inviteQueryPart } from '../routes';

const EndOfRow = styled(SpaceBetweenRow)`
  justify-content: flex-end;
  margin: 0 0 10px;
`;

export const ManageUsersPage: React.FC = () => {
  const { modal, presentModal } = useInviteUsersModal();

  useEffect(() => {
    if (location?.search.includes(inviteQueryPart)) {
      presentModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.search]);

  return (
    <PageLayout
      breadcrumbRoutes={[{ name: 'Account' }, { name: 'Manage Users' }]}
      title="Manage Users"
    >
      <Col gap={5}>
        {modal}
        <EndOfRow>
          <Button onClick={presentModal}>Invite Users</Button>
        </EndOfRow>
        <AccountUsers />
      </Col>
    </PageLayout>
  );
};
