import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Modal, ModalProps } from '../../ui/modal/modal';
import { ModalStepHeader } from '../../ui/modal/headers';
import { Button } from '../../ui/button/Button';
import { supportEmail } from '../../../routes';

type LegacyRedirectModalProps = Omit<ModalProps, 'children'>;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: calc(100% - 48px);
  justify-content: space-between;
  overflow: hidden;
  padding: 16px;
`;

export const LegacyRedirectModal: FunctionComponent<LegacyRedirectModalProps> = (props) => {
  const { isOpen, setIsOpen } = props;

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <ModalStepHeader
        next={false}
        previous={false}
        title="Welcome"
      />
      <Container>
        <div>
          We're excited to introduce Version 2 of Local Business Protection! Our upgraded platform offers enhanced
          features to better safeguard your Google Maps listings, and provides an upgraded user experience.
          <br />
          <br />
          To continue your journey with us, please create a new account using your existing email. This will ensure all
          your data is seamlessly transferred to the new system. Thank you for being a valued part of our community.
          <br />
          <br />
          If you have any questions, please contact us at{' '}
          <a
            href={`mailto:${supportEmail}`}
            aria-label="Email support at Local Business Protection"
          >
            {supportEmail}
          </a>
          .
        </div>
        <Button
          fullWidth
          onClick={() => setIsOpen(false)}
        >
          Let's Get Started!
        </Button>
      </Container>
    </Modal>
  );
};
